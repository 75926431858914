import styled from 'styled-components'

export const SnowWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  .snow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
  }

  .snow:first-child {
    opacity: 0.576;
    -webkit-transform: translate(90.5522vw, -10px) scale(0.6547);
    transform: translate(90.5522vw, -10px) scale(0.6547);
    -webkit-animation: fall-1 22s linear -19s infinite;
    animation: fall-1 22s linear -19s infinite;
  }

  @-webkit-keyframes fall-1 {
    47.448% {
      -webkit-transform: translate(96.2682vw, 142.344vh) scale(0.6547);
      transform: translate(96.2682vw, 142.344vh) scale(0.6547);
    }

    to {
      -webkit-transform: translate(93.4102vw, 300vh) scale(0.6547);
      transform: translate(93.4102vw, 300vh) scale(0.6547);
    }
  }

  @keyframes fall-1 {
    47.448% {
      -webkit-transform: translate(96.2682vw, 142.344vh) scale(0.6547);
      transform: translate(96.2682vw, 142.344vh) scale(0.6547);
    }

    to {
      -webkit-transform: translate(93.4102vw, 300vh) scale(0.6547);
      transform: translate(93.4102vw, 300vh) scale(0.6547);
    }
  }

  .snow:nth-child(2) {
    opacity: 0.9001;
    -webkit-transform: translate(73.6978vw, -10px) scale(0.0709);
    transform: translate(73.6978vw, -10px) scale(0.0709);
    -webkit-animation: fall-2 38s linear -12s infinite;
    animation: fall-2 38s linear -12s infinite;
  }

  @-webkit-keyframes fall-2 {
    49.793% {
      -webkit-transform: translate(72.6433vw, 149.379vh) scale(0.0709);
      transform: translate(72.6433vw, 149.379vh) scale(0.0709);
    }

    to {
      -webkit-transform: translate(73.17055vw, 300vh) scale(0.0709);
      transform: translate(73.17055vw, 300vh) scale(0.0709);
    }
  }

  @keyframes fall-2 {
    49.793% {
      -webkit-transform: translate(72.6433vw, 149.379vh) scale(0.0709);
      transform: translate(72.6433vw, 149.379vh) scale(0.0709);
    }

    to {
      -webkit-transform: translate(73.17055vw, 300vh) scale(0.0709);
      transform: translate(73.17055vw, 300vh) scale(0.0709);
    }
  }

  .snow:nth-child(3) {
    opacity: 0.7595;
    -webkit-transform: translate(54.8792vw, -10px) scale(0.4374);
    transform: translate(54.8792vw, -10px) scale(0.4374);
    -webkit-animation: fall-3 34s linear -1s infinite;
    animation: fall-3 34s linear -1s infinite;
  }

  @-webkit-keyframes fall-3 {
    73.052% {
      -webkit-transform: translate(62.4841vw, 219.156vh) scale(0.4374);
      transform: translate(62.4841vw, 219.156vh) scale(0.4374);
    }

    to {
      -webkit-transform: translate(58.68165vw, 300vh) scale(0.4374);
      transform: translate(58.68165vw, 300vh) scale(0.4374);
    }
  }

  @keyframes fall-3 {
    73.052% {
      -webkit-transform: translate(62.4841vw, 219.156vh) scale(0.4374);
      transform: translate(62.4841vw, 219.156vh) scale(0.4374);
    }

    to {
      -webkit-transform: translate(58.68165vw, 300vh) scale(0.4374);
      transform: translate(58.68165vw, 300vh) scale(0.4374);
    }
  }

  .snow:nth-child(4) {
    opacity: 0.3256;
    -webkit-transform: translate(20.0922vw, -10px) scale(0.9576);
    transform: translate(20.0922vw, -10px) scale(0.9576);
    -webkit-animation: fall-4 24s linear -26s infinite;
    animation: fall-4 24s linear -26s infinite;
  }

  @-webkit-keyframes fall-4 {
    63.179% {
      -webkit-transform: translate(26.683vw, 189.537vh) scale(0.9576);
      transform: translate(26.683vw, 189.537vh) scale(0.9576);
    }

    to {
      -webkit-transform: translate(23.3876vw, 300vh) scale(0.9576);
      transform: translate(23.3876vw, 300vh) scale(0.9576);
    }
  }

  @keyframes fall-4 {
    63.179% {
      -webkit-transform: translate(26.683vw, 189.537vh) scale(0.9576);
      transform: translate(26.683vw, 189.537vh) scale(0.9576);
    }

    to {
      -webkit-transform: translate(23.3876vw, 300vh) scale(0.9576);
      transform: translate(23.3876vw, 300vh) scale(0.9576);
    }
  }

  .snow:nth-child(5) {
    opacity: 0.8207;
    -webkit-transform: translate(20.5021vw, -10px) scale(0.9329);
    transform: translate(20.5021vw, -10px) scale(0.9329);
    -webkit-animation: fall-5 33s linear -21s infinite;
    animation: fall-5 33s linear -21s infinite;
  }

  @-webkit-keyframes fall-5 {
    74.061% {
      -webkit-transform: translate(13.5931vw, 222.183vh) scale(0.9329);
      transform: translate(13.5931vw, 222.183vh) scale(0.9329);
    }

    to {
      -webkit-transform: translate(17.0476vw, 300vh) scale(0.9329);
      transform: translate(17.0476vw, 300vh) scale(0.9329);
    }
  }

  @keyframes fall-5 {
    74.061% {
      -webkit-transform: translate(13.5931vw, 222.183vh) scale(0.9329);
      transform: translate(13.5931vw, 222.183vh) scale(0.9329);
    }

    to {
      -webkit-transform: translate(17.0476vw, 300vh) scale(0.9329);
      transform: translate(17.0476vw, 300vh) scale(0.9329);
    }
  }

  .snow:nth-child(6) {
    opacity: 0.9764;
    -webkit-transform: translate(88.0681vw, -10px) scale(0.1709);
    transform: translate(88.0681vw, -10px) scale(0.1709);
    -webkit-animation: fall-6 31s linear -17s infinite;
    animation: fall-6 31s linear -17s infinite;
  }

  @-webkit-keyframes fall-6 {
    39% {
      -webkit-transform: translate(97.191vw, 117vh) scale(0.1709);
      transform: translate(97.191vw, 117vh) scale(0.1709);
    }

    to {
      -webkit-transform: translate(92.62955vw, 300vh) scale(0.1709);
      transform: translate(92.62955vw, 300vh) scale(0.1709);
    }
  }

  @keyframes fall-6 {
    39% {
      -webkit-transform: translate(97.191vw, 117vh) scale(0.1709);
      transform: translate(97.191vw, 117vh) scale(0.1709);
    }

    to {
      -webkit-transform: translate(92.62955vw, 300vh) scale(0.1709);
      transform: translate(92.62955vw, 300vh) scale(0.1709);
    }
  }

  .snow:nth-child(7) {
    opacity: 0.6614;
    -webkit-transform: translate(30.1125vw, -10px) scale(0.2208);
    transform: translate(30.1125vw, -10px) scale(0.2208);
    -webkit-animation: fall-7 22s linear -2s infinite;
    animation: fall-7 22s linear -2s infinite;
  }

  @-webkit-keyframes fall-7 {
    63.016% {
      -webkit-transform: translate(23.568vw, 189.048vh) scale(0.2208);
      transform: translate(23.568vw, 189.048vh) scale(0.2208);
    }

    to {
      -webkit-transform: translate(26.84025vw, 300vh) scale(0.2208);
      transform: translate(26.84025vw, 300vh) scale(0.2208);
    }
  }

  @keyframes fall-7 {
    63.016% {
      -webkit-transform: translate(23.568vw, 189.048vh) scale(0.2208);
      transform: translate(23.568vw, 189.048vh) scale(0.2208);
    }

    to {
      -webkit-transform: translate(26.84025vw, 300vh) scale(0.2208);
      transform: translate(26.84025vw, 300vh) scale(0.2208);
    }
  }

  .snow:nth-child(8) {
    opacity: 0.0059;
    -webkit-transform: translate(85.6736vw, -10px) scale(0.9724);
    transform: translate(85.6736vw, -10px) scale(0.9724);
    -webkit-animation: fall-8 40s linear -12s infinite;
    animation: fall-8 40s linear -12s infinite;
  }

  @-webkit-keyframes fall-8 {
    48.183% {
      -webkit-transform: translate(83.2928vw, 144.549vh) scale(0.9724);
      transform: translate(83.2928vw, 144.549vh) scale(0.9724);
    }

    to {
      -webkit-transform: translate(84.4832vw, 300vh) scale(0.9724);
      transform: translate(84.4832vw, 300vh) scale(0.9724);
    }
  }

  @keyframes fall-8 {
    48.183% {
      -webkit-transform: translate(83.2928vw, 144.549vh) scale(0.9724);
      transform: translate(83.2928vw, 144.549vh) scale(0.9724);
    }

    to {
      -webkit-transform: translate(84.4832vw, 300vh) scale(0.9724);
      transform: translate(84.4832vw, 300vh) scale(0.9724);
    }
  }

  .snow:nth-child(9) {
    opacity: 0.4567;
    -webkit-transform: translate(68.2775vw, -10px) scale(0.0145);
    transform: translate(68.2775vw, -10px) scale(0.0145);
    -webkit-animation: fall-9 22s linear -3s infinite;
    animation: fall-9 22s linear -3s infinite;
  }

  @-webkit-keyframes fall-9 {
    43.353% {
      -webkit-transform: translate(65.5565vw, 130.059vh) scale(0.0145);
      transform: translate(65.5565vw, 130.059vh) scale(0.0145);
    }

    to {
      -webkit-transform: translate(66.917vw, 300vh) scale(0.0145);
      transform: translate(66.917vw, 300vh) scale(0.0145);
    }
  }

  @keyframes fall-9 {
    43.353% {
      -webkit-transform: translate(65.5565vw, 130.059vh) scale(0.0145);
      transform: translate(65.5565vw, 130.059vh) scale(0.0145);
    }

    to {
      -webkit-transform: translate(66.917vw, 300vh) scale(0.0145);
      transform: translate(66.917vw, 300vh) scale(0.0145);
    }
  }

  .snow:nth-child(10) {
    opacity: 0.2628;
    -webkit-transform: translate(6.893vw, -10px) scale(0.727);
    transform: translate(6.893vw, -10px) scale(0.727);
    -webkit-animation: fall-10 22s linear -7s infinite;
    animation: fall-10 22s linear -7s infinite;
  }

  @-webkit-keyframes fall-10 {
    32.293% {
      -webkit-transform: translate(12.3681vw, 96.879vh) scale(0.727);
      transform: translate(12.3681vw, 96.879vh) scale(0.727);
    }

    to {
      -webkit-transform: translate(9.63055vw, 300vh) scale(0.727);
      transform: translate(9.63055vw, 300vh) scale(0.727);
    }
  }

  @keyframes fall-10 {
    32.293% {
      -webkit-transform: translate(12.3681vw, 96.879vh) scale(0.727);
      transform: translate(12.3681vw, 96.879vh) scale(0.727);
    }

    to {
      -webkit-transform: translate(9.63055vw, 300vh) scale(0.727);
      transform: translate(9.63055vw, 300vh) scale(0.727);
    }
  }

  .snow:nth-child(11) {
    opacity: 0.3005;
    -webkit-transform: translate(16.5324vw, -10px) scale(0.3302);
    transform: translate(16.5324vw, -10px) scale(0.3302);
    -webkit-animation: fall-11 34s linear -7s infinite;
    animation: fall-11 34s linear -7s infinite;
  }

  @-webkit-keyframes fall-11 {
    77.873% {
      -webkit-transform: translate(10.5031vw, 233.619vh) scale(0.3302);
      transform: translate(10.5031vw, 233.619vh) scale(0.3302);
    }

    to {
      -webkit-transform: translate(13.51775vw, 300vh) scale(0.3302);
      transform: translate(13.51775vw, 300vh) scale(0.3302);
    }
  }

  @keyframes fall-11 {
    77.873% {
      -webkit-transform: translate(10.5031vw, 233.619vh) scale(0.3302);
      transform: translate(10.5031vw, 233.619vh) scale(0.3302);
    }

    to {
      -webkit-transform: translate(13.51775vw, 300vh) scale(0.3302);
      transform: translate(13.51775vw, 300vh) scale(0.3302);
    }
  }

  .snow:nth-child(12) {
    opacity: 0.1866;
    -webkit-transform: translate(7.5169vw, -10px) scale(0.3247);
    transform: translate(7.5169vw, -10px) scale(0.3247);
    -webkit-animation: fall-12 37s linear -11s infinite;
    animation: fall-12 37s linear -11s infinite;
  }

  @-webkit-keyframes fall-12 {
    48.301% {
      -webkit-transform: translate(5.3665vw, 144.903vh) scale(0.3247);
      transform: translate(5.3665vw, 144.903vh) scale(0.3247);
    }

    to {
      -webkit-transform: translate(6.4417vw, 300vh) scale(0.3247);
      transform: translate(6.4417vw, 300vh) scale(0.3247);
    }
  }

  @keyframes fall-12 {
    48.301% {
      -webkit-transform: translate(5.3665vw, 144.903vh) scale(0.3247);
      transform: translate(5.3665vw, 144.903vh) scale(0.3247);
    }

    to {
      -webkit-transform: translate(6.4417vw, 300vh) scale(0.3247);
      transform: translate(6.4417vw, 300vh) scale(0.3247);
    }
  }

  .snow:nth-child(13) {
    opacity: 0.5944;
    -webkit-transform: translate(6.5605vw, -10px) scale(0.4117);
    transform: translate(6.5605vw, -10px) scale(0.4117);
    -webkit-animation: fall-13 20s linear -29s infinite;
    animation: fall-13 20s linear -29s infinite;
  }

  @-webkit-keyframes fall-13 {
    46.997% {
      -webkit-transform: translate(5.6083vw, 140.991vh) scale(0.4117);
      transform: translate(5.6083vw, 140.991vh) scale(0.4117);
    }

    to {
      -webkit-transform: translate(6.0844vw, 300vh) scale(0.4117);
      transform: translate(6.0844vw, 300vh) scale(0.4117);
    }
  }

  @keyframes fall-13 {
    46.997% {
      -webkit-transform: translate(5.6083vw, 140.991vh) scale(0.4117);
      transform: translate(5.6083vw, 140.991vh) scale(0.4117);
    }

    to {
      -webkit-transform: translate(6.0844vw, 300vh) scale(0.4117);
      transform: translate(6.0844vw, 300vh) scale(0.4117);
    }
  }

  .snow:nth-child(14) {
    opacity: 0.8489;
    -webkit-transform: translate(71.7766vw, -10px) scale(0.7174);
    transform: translate(71.7766vw, -10px) scale(0.7174);
    -webkit-animation: fall-14 26s linear -7s infinite;
    animation: fall-14 26s linear -7s infinite;
  }

  @-webkit-keyframes fall-14 {
    60.504% {
      -webkit-transform: translate(73.4592vw, 181.512vh) scale(0.7174);
      transform: translate(73.4592vw, 181.512vh) scale(0.7174);
    }

    to {
      -webkit-transform: translate(72.6179vw, 300vh) scale(0.7174);
      transform: translate(72.6179vw, 300vh) scale(0.7174);
    }
  }

  @keyframes fall-14 {
    60.504% {
      -webkit-transform: translate(73.4592vw, 181.512vh) scale(0.7174);
      transform: translate(73.4592vw, 181.512vh) scale(0.7174);
    }

    to {
      -webkit-transform: translate(72.6179vw, 300vh) scale(0.7174);
      transform: translate(72.6179vw, 300vh) scale(0.7174);
    }
  }

  .snow:nth-child(15) {
    opacity: 0.02;
    -webkit-transform: translate(64.3588vw, -10px) scale(0.275);
    transform: translate(64.3588vw, -10px) scale(0.275);
    -webkit-animation: fall-15 33s linear -20s infinite;
    animation: fall-15 33s linear -20s infinite;
  }

  @-webkit-keyframes fall-15 {
    53.094% {
      -webkit-transform: translate(58.739vw, 159.282vh) scale(0.275);
      transform: translate(58.739vw, 159.282vh) scale(0.275);
    }

    to {
      -webkit-transform: translate(61.5489vw, 300vh) scale(0.275);
      transform: translate(61.5489vw, 300vh) scale(0.275);
    }
  }

  @keyframes fall-15 {
    53.094% {
      -webkit-transform: translate(58.739vw, 159.282vh) scale(0.275);
      transform: translate(58.739vw, 159.282vh) scale(0.275);
    }

    to {
      -webkit-transform: translate(61.5489vw, 300vh) scale(0.275);
      transform: translate(61.5489vw, 300vh) scale(0.275);
    }
  }

  .snow:nth-child(16) {
    opacity: 0.2111;
    -webkit-transform: translate(24.9027vw, -10px) scale(0.4819);
    transform: translate(24.9027vw, -10px) scale(0.4819);
    -webkit-animation: fall-16 29s linear -8s infinite;
    animation: fall-16 29s linear -8s infinite;
  }

  @-webkit-keyframes fall-16 {
    46.351% {
      -webkit-transform: translate(25.3122vw, 139.053vh) scale(0.4819);
      transform: translate(25.3122vw, 139.053vh) scale(0.4819);
    }

    to {
      -webkit-transform: translate(25.10745vw, 300vh) scale(0.4819);
      transform: translate(25.10745vw, 300vh) scale(0.4819);
    }
  }

  @keyframes fall-16 {
    46.351% {
      -webkit-transform: translate(25.3122vw, 139.053vh) scale(0.4819);
      transform: translate(25.3122vw, 139.053vh) scale(0.4819);
    }

    to {
      -webkit-transform: translate(25.10745vw, 300vh) scale(0.4819);
      transform: translate(25.10745vw, 300vh) scale(0.4819);
    }
  }

  .snow:nth-child(17) {
    opacity: 0.131;
    -webkit-transform: translate(86.6582vw, -10px) scale(0.6012);
    transform: translate(86.6582vw, -10px) scale(0.6012);
    -webkit-animation: fall-17 36s linear -29s infinite;
    animation: fall-17 36s linear -29s infinite;
  }

  @-webkit-keyframes fall-17 {
    49.885% {
      -webkit-transform: translate(83.289vw, 149.655vh) scale(0.6012);
      transform: translate(83.289vw, 149.655vh) scale(0.6012);
    }

    to {
      -webkit-transform: translate(84.9736vw, 300vh) scale(0.6012);
      transform: translate(84.9736vw, 300vh) scale(0.6012);
    }
  }

  @keyframes fall-17 {
    49.885% {
      -webkit-transform: translate(83.289vw, 149.655vh) scale(0.6012);
      transform: translate(83.289vw, 149.655vh) scale(0.6012);
    }

    to {
      -webkit-transform: translate(84.9736vw, 300vh) scale(0.6012);
      transform: translate(84.9736vw, 300vh) scale(0.6012);
    }
  }

  .snow:nth-child(18) {
    opacity: 0.2152;
    -webkit-transform: translate(35.211vw, -10px) scale(0.7803);
    transform: translate(35.211vw, -10px) scale(0.7803);
    -webkit-animation: fall-18 27s linear -16s infinite;
    animation: fall-18 27s linear -16s infinite;
  }

  @-webkit-keyframes fall-18 {
    33.001% {
      -webkit-transform: translate(27.9895vw, 99.003vh) scale(0.7803);
      transform: translate(27.9895vw, 99.003vh) scale(0.7803);
    }

    to {
      -webkit-transform: translate(31.60025vw, 300vh) scale(0.7803);
      transform: translate(31.60025vw, 300vh) scale(0.7803);
    }
  }

  @keyframes fall-18 {
    33.001% {
      -webkit-transform: translate(27.9895vw, 99.003vh) scale(0.7803);
      transform: translate(27.9895vw, 99.003vh) scale(0.7803);
    }

    to {
      -webkit-transform: translate(31.60025vw, 300vh) scale(0.7803);
      transform: translate(31.60025vw, 300vh) scale(0.7803);
    }
  }

  .snow:nth-child(19) {
    opacity: 0.7115;
    -webkit-transform: translate(29.9676vw, -10px) scale(0.4506);
    transform: translate(29.9676vw, -10px) scale(0.4506);
    -webkit-animation: fall-19 29s linear -3s infinite;
    animation: fall-19 29s linear -3s infinite;
  }

  @-webkit-keyframes fall-19 {
    46.538% {
      -webkit-transform: translate(33.1615vw, 139.614vh) scale(0.4506);
      transform: translate(33.1615vw, 139.614vh) scale(0.4506);
    }

    to {
      -webkit-transform: translate(31.56455vw, 300vh) scale(0.4506);
      transform: translate(31.56455vw, 300vh) scale(0.4506);
    }
  }

  @keyframes fall-19 {
    46.538% {
      -webkit-transform: translate(33.1615vw, 139.614vh) scale(0.4506);
      transform: translate(33.1615vw, 139.614vh) scale(0.4506);
    }

    to {
      -webkit-transform: translate(31.56455vw, 300vh) scale(0.4506);
      transform: translate(31.56455vw, 300vh) scale(0.4506);
    }
  }

  .snow:nth-child(20) {
    opacity: 0.2541;
    -webkit-transform: translate(63.3753vw, -10px) scale(0.4125);
    transform: translate(63.3753vw, -10px) scale(0.4125);
    -webkit-animation: fall-20 27s linear -26s infinite;
    animation: fall-20 27s linear -26s infinite;
  }

  @-webkit-keyframes fall-20 {
    45.412% {
      -webkit-transform: translate(58.8065vw, 136.236vh) scale(0.4125);
      transform: translate(58.8065vw, 136.236vh) scale(0.4125);
    }

    to {
      -webkit-transform: translate(61.0909vw, 300vh) scale(0.4125);
      transform: translate(61.0909vw, 300vh) scale(0.4125);
    }
  }

  @keyframes fall-20 {
    45.412% {
      -webkit-transform: translate(58.8065vw, 136.236vh) scale(0.4125);
      transform: translate(58.8065vw, 136.236vh) scale(0.4125);
    }

    to {
      -webkit-transform: translate(61.0909vw, 300vh) scale(0.4125);
      transform: translate(61.0909vw, 300vh) scale(0.4125);
    }
  }

  .snow:nth-child(21) {
    opacity: 0.3691;
    -webkit-transform: translate(71.5859vw, -10px) scale(0.7602);
    transform: translate(71.5859vw, -10px) scale(0.7602);
    -webkit-animation: fall-21 34s linear -23s infinite;
    animation: fall-21 34s linear -23s infinite;
  }

  @-webkit-keyframes fall-21 {
    42.985% {
      -webkit-transform: translate(74.6342vw, 128.955vh) scale(0.7602);
      transform: translate(74.6342vw, 128.955vh) scale(0.7602);
    }

    to {
      -webkit-transform: translate(73.11005vw, 300vh) scale(0.7602);
      transform: translate(73.11005vw, 300vh) scale(0.7602);
    }
  }

  @keyframes fall-21 {
    42.985% {
      -webkit-transform: translate(74.6342vw, 128.955vh) scale(0.7602);
      transform: translate(74.6342vw, 128.955vh) scale(0.7602);
    }

    to {
      -webkit-transform: translate(73.11005vw, 300vh) scale(0.7602);
      transform: translate(73.11005vw, 300vh) scale(0.7602);
    }
  }

  .snow:nth-child(22) {
    opacity: 0.5023;
    -webkit-transform: translate(47.4537vw, -10px) scale(0.5407);
    transform: translate(47.4537vw, -10px) scale(0.5407);
    -webkit-animation: fall-22 21s linear -6s infinite;
    animation: fall-22 21s linear -6s infinite;
  }

  @-webkit-keyframes fall-22 {
    30.307% {
      -webkit-transform: translate(43.4359vw, 90.921vh) scale(0.5407);
      transform: translate(43.4359vw, 90.921vh) scale(0.5407);
    }

    to {
      -webkit-transform: translate(45.4448vw, 300vh) scale(0.5407);
      transform: translate(45.4448vw, 300vh) scale(0.5407);
    }
  }

  @keyframes fall-22 {
    30.307% {
      -webkit-transform: translate(43.4359vw, 90.921vh) scale(0.5407);
      transform: translate(43.4359vw, 90.921vh) scale(0.5407);
    }

    to {
      -webkit-transform: translate(45.4448vw, 300vh) scale(0.5407);
      transform: translate(45.4448vw, 300vh) scale(0.5407);
    }
  }

  .snow:nth-child(23) {
    opacity: 0.0357;
    -webkit-transform: translate(77.2186vw, -10px) scale(0.3486);
    transform: translate(77.2186vw, -10px) scale(0.3486);
    -webkit-animation: fall-23 21s linear -19s infinite;
    animation: fall-23 21s linear -19s infinite;
  }

  @-webkit-keyframes fall-23 {
    54.451% {
      -webkit-transform: translate(84.3589vw, 163.353vh) scale(0.3486);
      transform: translate(84.3589vw, 163.353vh) scale(0.3486);
    }

    to {
      -webkit-transform: translate(80.78875vw, 300vh) scale(0.3486);
      transform: translate(80.78875vw, 300vh) scale(0.3486);
    }
  }

  @keyframes fall-23 {
    54.451% {
      -webkit-transform: translate(84.3589vw, 163.353vh) scale(0.3486);
      transform: translate(84.3589vw, 163.353vh) scale(0.3486);
    }

    to {
      -webkit-transform: translate(80.78875vw, 300vh) scale(0.3486);
      transform: translate(80.78875vw, 300vh) scale(0.3486);
    }
  }

  .snow:nth-child(24) {
    opacity: 0.4333;
    -webkit-transform: translate(41.5038vw, -10px) scale(0.7816);
    transform: translate(41.5038vw, -10px) scale(0.7816);
    -webkit-animation: fall-24 31s linear -18s infinite;
    animation: fall-24 31s linear -18s infinite;
  }

  @-webkit-keyframes fall-24 {
    48.696% {
      -webkit-transform: translate(40.2489vw, 146.088vh) scale(0.7816);
      transform: translate(40.2489vw, 146.088vh) scale(0.7816);
    }

    to {
      -webkit-transform: translate(40.87635vw, 300vh) scale(0.7816);
      transform: translate(40.87635vw, 300vh) scale(0.7816);
    }
  }

  @keyframes fall-24 {
    48.696% {
      -webkit-transform: translate(40.2489vw, 146.088vh) scale(0.7816);
      transform: translate(40.2489vw, 146.088vh) scale(0.7816);
    }

    to {
      -webkit-transform: translate(40.87635vw, 300vh) scale(0.7816);
      transform: translate(40.87635vw, 300vh) scale(0.7816);
    }
  }

  .snow:nth-child(25) {
    opacity: 0.0144;
    -webkit-transform: translate(36.4369vw, -10px) scale(0.2281);
    transform: translate(36.4369vw, -10px) scale(0.2281);
    -webkit-animation: fall-25 30s linear -6s infinite;
    animation: fall-25 30s linear -6s infinite;
  }

  @-webkit-keyframes fall-25 {
    39.001% {
      -webkit-transform: translate(34.5985vw, 117.003vh) scale(0.2281);
      transform: translate(34.5985vw, 117.003vh) scale(0.2281);
    }

    to {
      -webkit-transform: translate(35.5177vw, 300vh) scale(0.2281);
      transform: translate(35.5177vw, 300vh) scale(0.2281);
    }
  }

  @keyframes fall-25 {
    39.001% {
      -webkit-transform: translate(34.5985vw, 117.003vh) scale(0.2281);
      transform: translate(34.5985vw, 117.003vh) scale(0.2281);
    }

    to {
      -webkit-transform: translate(35.5177vw, 300vh) scale(0.2281);
      transform: translate(35.5177vw, 300vh) scale(0.2281);
    }
  }

  .snow:nth-child(26) {
    opacity: 0.6756;
    -webkit-transform: translate(37.588vw, -10px) scale(0.9405);
    transform: translate(37.588vw, -10px) scale(0.9405);
    -webkit-animation: fall-26 27s linear -26s infinite;
    animation: fall-26 27s linear -26s infinite;
  }

  @-webkit-keyframes fall-26 {
    79.456% {
      -webkit-transform: translate(29.016vw, 238.368vh) scale(0.9405);
      transform: translate(29.016vw, 238.368vh) scale(0.9405);
    }

    to {
      -webkit-transform: translate(33.302vw, 300vh) scale(0.9405);
      transform: translate(33.302vw, 300vh) scale(0.9405);
    }
  }

  @keyframes fall-26 {
    79.456% {
      -webkit-transform: translate(29.016vw, 238.368vh) scale(0.9405);
      transform: translate(29.016vw, 238.368vh) scale(0.9405);
    }

    to {
      -webkit-transform: translate(33.302vw, 300vh) scale(0.9405);
      transform: translate(33.302vw, 300vh) scale(0.9405);
    }
  }

  .snow:nth-child(27) {
    opacity: 0.938;
    -webkit-transform: translate(60.7158vw, -10px) scale(0.3987);
    transform: translate(60.7158vw, -10px) scale(0.3987);
    -webkit-animation: fall-27 24s linear -23s infinite;
    animation: fall-27 24s linear -23s infinite;
  }

  @-webkit-keyframes fall-27 {
    31.875% {
      -webkit-transform: translate(66.9104vw, 95.625vh) scale(0.3987);
      transform: translate(66.9104vw, 95.625vh) scale(0.3987);
    }

    to {
      -webkit-transform: translate(63.8131vw, 300vh) scale(0.3987);
      transform: translate(63.8131vw, 300vh) scale(0.3987);
    }
  }

  @keyframes fall-27 {
    31.875% {
      -webkit-transform: translate(66.9104vw, 95.625vh) scale(0.3987);
      transform: translate(66.9104vw, 95.625vh) scale(0.3987);
    }

    to {
      -webkit-transform: translate(63.8131vw, 300vh) scale(0.3987);
      transform: translate(63.8131vw, 300vh) scale(0.3987);
    }
  }

  .snow:nth-child(28) {
    opacity: 0.2375;
    -webkit-transform: translate(31.2837vw, -10px) scale(0.2052);
    transform: translate(31.2837vw, -10px) scale(0.2052);
    -webkit-animation: fall-28 20s linear -11s infinite;
    animation: fall-28 20s linear -11s infinite;
  }

  @-webkit-keyframes fall-28 {
    34.17% {
      -webkit-transform: translate(36.269vw, 102.51vh) scale(0.2052);
      transform: translate(36.269vw, 102.51vh) scale(0.2052);
    }

    to {
      -webkit-transform: translate(33.77635vw, 300vh) scale(0.2052);
      transform: translate(33.77635vw, 300vh) scale(0.2052);
    }
  }

  @keyframes fall-28 {
    34.17% {
      -webkit-transform: translate(36.269vw, 102.51vh) scale(0.2052);
      transform: translate(36.269vw, 102.51vh) scale(0.2052);
    }

    to {
      -webkit-transform: translate(33.77635vw, 300vh) scale(0.2052);
      transform: translate(33.77635vw, 300vh) scale(0.2052);
    }
  }

  .snow:nth-child(29) {
    opacity: 0.2004;
    -webkit-transform: translate(60.4452vw, -10px) scale(0.7315);
    transform: translate(60.4452vw, -10px) scale(0.7315);
    -webkit-animation: fall-29 27s linear -14s infinite;
    animation: fall-29 27s linear -14s infinite;
  }

  @-webkit-keyframes fall-29 {
    57.74% {
      -webkit-transform: translate(59.1296vw, 173.22vh) scale(0.7315);
      transform: translate(59.1296vw, 173.22vh) scale(0.7315);
    }

    to {
      -webkit-transform: translate(59.7874vw, 300vh) scale(0.7315);
      transform: translate(59.7874vw, 300vh) scale(0.7315);
    }
  }

  @keyframes fall-29 {
    57.74% {
      -webkit-transform: translate(59.1296vw, 173.22vh) scale(0.7315);
      transform: translate(59.1296vw, 173.22vh) scale(0.7315);
    }

    to {
      -webkit-transform: translate(59.7874vw, 300vh) scale(0.7315);
      transform: translate(59.7874vw, 300vh) scale(0.7315);
    }
  }

  .snow:nth-child(30) {
    opacity: 0.9727;
    -webkit-transform: translate(7.2624vw, -10px) scale(0.6605);
    transform: translate(7.2624vw, -10px) scale(0.6605);
    -webkit-animation: fall-30 30s linear -5s infinite;
    animation: fall-30 30s linear -5s infinite;
  }

  @-webkit-keyframes fall-30 {
    60.887% {
      -webkit-transform: translate(12.1897vw, 182.661vh) scale(0.6605);
      transform: translate(12.1897vw, 182.661vh) scale(0.6605);
    }

    to {
      -webkit-transform: translate(9.72605vw, 300vh) scale(0.6605);
      transform: translate(9.72605vw, 300vh) scale(0.6605);
    }
  }

  @keyframes fall-30 {
    60.887% {
      -webkit-transform: translate(12.1897vw, 182.661vh) scale(0.6605);
      transform: translate(12.1897vw, 182.661vh) scale(0.6605);
    }

    to {
      -webkit-transform: translate(9.72605vw, 300vh) scale(0.6605);
      transform: translate(9.72605vw, 300vh) scale(0.6605);
    }
  }

  .snow:nth-child(31) {
    opacity: 0.6971;
    -webkit-transform: translate(88.1432vw, -10px) scale(0.6014);
    transform: translate(88.1432vw, -10px) scale(0.6014);
    -webkit-animation: fall-31 36s linear -13s infinite;
    animation: fall-31 36s linear -13s infinite;
  }

  @-webkit-keyframes fall-31 {
    61.423% {
      -webkit-transform: translate(83.8815vw, 184.269vh) scale(0.6014);
      transform: translate(83.8815vw, 184.269vh) scale(0.6014);
    }

    to {
      -webkit-transform: translate(86.01235vw, 300vh) scale(0.6014);
      transform: translate(86.01235vw, 300vh) scale(0.6014);
    }
  }

  @keyframes fall-31 {
    61.423% {
      -webkit-transform: translate(83.8815vw, 184.269vh) scale(0.6014);
      transform: translate(83.8815vw, 184.269vh) scale(0.6014);
    }

    to {
      -webkit-transform: translate(86.01235vw, 300vh) scale(0.6014);
      transform: translate(86.01235vw, 300vh) scale(0.6014);
    }
  }

  .snow:nth-child(32) {
    opacity: 0.0526;
    -webkit-transform: translate(83.2824vw, -10px) scale(0.613);
    transform: translate(83.2824vw, -10px) scale(0.613);
    -webkit-animation: fall-32 35s linear -7s infinite;
    animation: fall-32 35s linear -7s infinite;
  }

  @-webkit-keyframes fall-32 {
    68.954% {
      -webkit-transform: translate(74.4602vw, 206.862vh) scale(0.613);
      transform: translate(74.4602vw, 206.862vh) scale(0.613);
    }

    to {
      -webkit-transform: translate(78.8713vw, 300vh) scale(0.613);
      transform: translate(78.8713vw, 300vh) scale(0.613);
    }
  }

  @keyframes fall-32 {
    68.954% {
      -webkit-transform: translate(74.4602vw, 206.862vh) scale(0.613);
      transform: translate(74.4602vw, 206.862vh) scale(0.613);
    }

    to {
      -webkit-transform: translate(78.8713vw, 300vh) scale(0.613);
      transform: translate(78.8713vw, 300vh) scale(0.613);
    }
  }

  .snow:nth-child(33) {
    opacity: 0.2343;
    -webkit-transform: translate(69.4852vw, -10px) scale(0.4034);
    transform: translate(69.4852vw, -10px) scale(0.4034);
    -webkit-animation: fall-33 31s linear -27s infinite;
    animation: fall-33 31s linear -27s infinite;
  }

  @-webkit-keyframes fall-33 {
    63.398% {
      -webkit-transform: translate(62.3793vw, 190.194vh) scale(0.4034);
      transform: translate(62.3793vw, 190.194vh) scale(0.4034);
    }

    to {
      -webkit-transform: translate(65.93225vw, 300vh) scale(0.4034);
      transform: translate(65.93225vw, 300vh) scale(0.4034);
    }
  }

  @keyframes fall-33 {
    63.398% {
      -webkit-transform: translate(62.3793vw, 190.194vh) scale(0.4034);
      transform: translate(62.3793vw, 190.194vh) scale(0.4034);
    }

    to {
      -webkit-transform: translate(65.93225vw, 300vh) scale(0.4034);
      transform: translate(65.93225vw, 300vh) scale(0.4034);
    }
  }

  .snow:nth-child(34) {
    opacity: 0.2833;
    -webkit-transform: translate(2.6232vw, -10px) scale(0.5067);
    transform: translate(2.6232vw, -10px) scale(0.5067);
    -webkit-animation: fall-34 34s linear -1s infinite;
    animation: fall-34 34s linear -1s infinite;
  }

  @-webkit-keyframes fall-34 {
    33.781% {
      -webkit-transform: translate(12.4772vw, 101.343vh) scale(0.5067);
      transform: translate(12.4772vw, 101.343vh) scale(0.5067);
    }

    to {
      -webkit-transform: translate(7.5502vw, 300vh) scale(0.5067);
      transform: translate(7.5502vw, 300vh) scale(0.5067);
    }
  }

  @keyframes fall-34 {
    33.781% {
      -webkit-transform: translate(12.4772vw, 101.343vh) scale(0.5067);
      transform: translate(12.4772vw, 101.343vh) scale(0.5067);
    }

    to {
      -webkit-transform: translate(7.5502vw, 300vh) scale(0.5067);
      transform: translate(7.5502vw, 300vh) scale(0.5067);
    }
  }

  .snow:nth-child(35) {
    opacity: 0.6069;
    -webkit-transform: translate(86.1795vw, -10px) scale(0.2999);
    transform: translate(86.1795vw, -10px) scale(0.2999);
    -webkit-animation: fall-35 25s linear -10s infinite;
    animation: fall-35 25s linear -10s infinite;
  }

  @-webkit-keyframes fall-35 {
    73.734% {
      -webkit-transform: translate(95.7504vw, 221.202vh) scale(0.2999);
      transform: translate(95.7504vw, 221.202vh) scale(0.2999);
    }

    to {
      -webkit-transform: translate(90.96495vw, 300vh) scale(0.2999);
      transform: translate(90.96495vw, 300vh) scale(0.2999);
    }
  }

  @keyframes fall-35 {
    73.734% {
      -webkit-transform: translate(95.7504vw, 221.202vh) scale(0.2999);
      transform: translate(95.7504vw, 221.202vh) scale(0.2999);
    }

    to {
      -webkit-transform: translate(90.96495vw, 300vh) scale(0.2999);
      transform: translate(90.96495vw, 300vh) scale(0.2999);
    }
  }

  .snow:nth-child(36) {
    opacity: 0.6752;
    -webkit-transform: translate(52.5532vw, -10px) scale(0.2445);
    transform: translate(52.5532vw, -10px) scale(0.2445);
    -webkit-animation: fall-36 37s linear -3s infinite;
    animation: fall-36 37s linear -3s infinite;
  }

  @-webkit-keyframes fall-36 {
    48.305% {
      -webkit-transform: translate(44.9649vw, 144.915vh) scale(0.2445);
      transform: translate(44.9649vw, 144.915vh) scale(0.2445);
    }

    to {
      -webkit-transform: translate(48.75905vw, 300vh) scale(0.2445);
      transform: translate(48.75905vw, 300vh) scale(0.2445);
    }
  }

  @keyframes fall-36 {
    48.305% {
      -webkit-transform: translate(44.9649vw, 144.915vh) scale(0.2445);
      transform: translate(44.9649vw, 144.915vh) scale(0.2445);
    }

    to {
      -webkit-transform: translate(48.75905vw, 300vh) scale(0.2445);
      transform: translate(48.75905vw, 300vh) scale(0.2445);
    }
  }

  .snow:nth-child(37) {
    opacity: 0.7851;
    -webkit-transform: translate(90.7842vw, -10px) scale(0.5654);
    transform: translate(90.7842vw, -10px) scale(0.5654);
    -webkit-animation: fall-37 35s linear -19s infinite;
    animation: fall-37 35s linear -19s infinite;
  }

  @-webkit-keyframes fall-37 {
    45.571% {
      -webkit-transform: translate(99.7694vw, 136.713vh) scale(0.5654);
      transform: translate(99.7694vw, 136.713vh) scale(0.5654);
    }

    to {
      -webkit-transform: translate(95.2768vw, 300vh) scale(0.5654);
      transform: translate(95.2768vw, 300vh) scale(0.5654);
    }
  }

  @keyframes fall-37 {
    45.571% {
      -webkit-transform: translate(99.7694vw, 136.713vh) scale(0.5654);
      transform: translate(99.7694vw, 136.713vh) scale(0.5654);
    }

    to {
      -webkit-transform: translate(95.2768vw, 300vh) scale(0.5654);
      transform: translate(95.2768vw, 300vh) scale(0.5654);
    }
  }

  .snow:nth-child(38) {
    opacity: 0.4023;
    -webkit-transform: translate(31.1669vw, -10px) scale(0.7004);
    transform: translate(31.1669vw, -10px) scale(0.7004);
    -webkit-animation: fall-38 21s linear -11s infinite;
    animation: fall-38 21s linear -11s infinite;
  }

  @-webkit-keyframes fall-38 {
    50.204% {
      -webkit-transform: translate(23.6571vw, 150.612vh) scale(0.7004);
      transform: translate(23.6571vw, 150.612vh) scale(0.7004);
    }

    to {
      -webkit-transform: translate(27.412vw, 300vh) scale(0.7004);
      transform: translate(27.412vw, 300vh) scale(0.7004);
    }
  }

  @keyframes fall-38 {
    50.204% {
      -webkit-transform: translate(23.6571vw, 150.612vh) scale(0.7004);
      transform: translate(23.6571vw, 150.612vh) scale(0.7004);
    }

    to {
      -webkit-transform: translate(27.412vw, 300vh) scale(0.7004);
      transform: translate(27.412vw, 300vh) scale(0.7004);
    }
  }

  .snow:nth-child(39) {
    opacity: 0.2218;
    -webkit-transform: translate(85.9588vw, -10px) scale(0.2564);
    transform: translate(85.9588vw, -10px) scale(0.2564);
    -webkit-animation: fall-39 37s linear -18s infinite;
    animation: fall-39 37s linear -18s infinite;
  }

  @-webkit-keyframes fall-39 {
    52.775% {
      -webkit-transform: translate(89.6493vw, 158.325vh) scale(0.2564);
      transform: translate(89.6493vw, 158.325vh) scale(0.2564);
    }

    to {
      -webkit-transform: translate(87.80405vw, 300vh) scale(0.2564);
      transform: translate(87.80405vw, 300vh) scale(0.2564);
    }
  }

  @keyframes fall-39 {
    52.775% {
      -webkit-transform: translate(89.6493vw, 158.325vh) scale(0.2564);
      transform: translate(89.6493vw, 158.325vh) scale(0.2564);
    }

    to {
      -webkit-transform: translate(87.80405vw, 300vh) scale(0.2564);
      transform: translate(87.80405vw, 300vh) scale(0.2564);
    }
  }

  .snow:nth-child(40) {
    opacity: 0.7152;
    -webkit-transform: translate(60.0434vw, -10px) scale(0.9383);
    transform: translate(60.0434vw, -10px) scale(0.9383);
    -webkit-animation: fall-40 20s linear -28s infinite;
    animation: fall-40 20s linear -28s infinite;
  }

  @-webkit-keyframes fall-40 {
    57.416% {
      -webkit-transform: translate(51.4144vw, 172.248vh) scale(0.9383);
      transform: translate(51.4144vw, 172.248vh) scale(0.9383);
    }

    to {
      -webkit-transform: translate(55.7289vw, 300vh) scale(0.9383);
      transform: translate(55.7289vw, 300vh) scale(0.9383);
    }
  }

  @keyframes fall-40 {
    57.416% {
      -webkit-transform: translate(51.4144vw, 172.248vh) scale(0.9383);
      transform: translate(51.4144vw, 172.248vh) scale(0.9383);
    }

    to {
      -webkit-transform: translate(55.7289vw, 300vh) scale(0.9383);
      transform: translate(55.7289vw, 300vh) scale(0.9383);
    }
  }

  .snow:nth-child(41) {
    opacity: 0.3108;
    -webkit-transform: translate(79.8323vw, -10px) scale(0.6567);
    transform: translate(79.8323vw, -10px) scale(0.6567);
    -webkit-animation: fall-41 33s linear -5s infinite;
    animation: fall-41 33s linear -5s infinite;
  }

  @-webkit-keyframes fall-41 {
    31.331% {
      -webkit-transform: translate(69.957vw, 93.993vh) scale(0.6567);
      transform: translate(69.957vw, 93.993vh) scale(0.6567);
    }

    to {
      -webkit-transform: translate(74.89465vw, 300vh) scale(0.6567);
      transform: translate(74.89465vw, 300vh) scale(0.6567);
    }
  }

  @keyframes fall-41 {
    31.331% {
      -webkit-transform: translate(69.957vw, 93.993vh) scale(0.6567);
      transform: translate(69.957vw, 93.993vh) scale(0.6567);
    }

    to {
      -webkit-transform: translate(74.89465vw, 300vh) scale(0.6567);
      transform: translate(74.89465vw, 300vh) scale(0.6567);
    }
  }

  .snow:nth-child(42) {
    opacity: 0.0776;
    -webkit-transform: translate(81.8438vw, -10px) scale(0.0331);
    transform: translate(81.8438vw, -10px) scale(0.0331);
    -webkit-animation: fall-42 24s linear -4s infinite;
    animation: fall-42 24s linear -4s infinite;
  }

  @-webkit-keyframes fall-42 {
    58.722% {
      -webkit-transform: translate(75.3693vw, 176.166vh) scale(0.0331);
      transform: translate(75.3693vw, 176.166vh) scale(0.0331);
    }

    to {
      -webkit-transform: translate(78.60655vw, 300vh) scale(0.0331);
      transform: translate(78.60655vw, 300vh) scale(0.0331);
    }
  }

  @keyframes fall-42 {
    58.722% {
      -webkit-transform: translate(75.3693vw, 176.166vh) scale(0.0331);
      transform: translate(75.3693vw, 176.166vh) scale(0.0331);
    }

    to {
      -webkit-transform: translate(78.60655vw, 300vh) scale(0.0331);
      transform: translate(78.60655vw, 300vh) scale(0.0331);
    }
  }

  .snow:nth-child(43) {
    opacity: 0.2381;
    -webkit-transform: translate(25.8325vw, -10px) scale(0.5308);
    transform: translate(25.8325vw, -10px) scale(0.5308);
    -webkit-animation: fall-43 30s linear -25s infinite;
    animation: fall-43 30s linear -25s infinite;
  }

  @-webkit-keyframes fall-43 {
    77.63% {
      -webkit-transform: translate(32.7082vw, 232.89vh) scale(0.5308);
      transform: translate(32.7082vw, 232.89vh) scale(0.5308);
    }

    to {
      -webkit-transform: translate(29.27035vw, 300vh) scale(0.5308);
      transform: translate(29.27035vw, 300vh) scale(0.5308);
    }
  }

  @keyframes fall-43 {
    77.63% {
      -webkit-transform: translate(32.7082vw, 232.89vh) scale(0.5308);
      transform: translate(32.7082vw, 232.89vh) scale(0.5308);
    }

    to {
      -webkit-transform: translate(29.27035vw, 300vh) scale(0.5308);
      transform: translate(29.27035vw, 300vh) scale(0.5308);
    }
  }

  .snow:nth-child(44) {
    opacity: 0.3512;
    -webkit-transform: translate(55.9736vw, -10px) scale(0.1332);
    transform: translate(55.9736vw, -10px) scale(0.1332);
    -webkit-animation: fall-44 35s linear -27s infinite;
    animation: fall-44 35s linear -27s infinite;
  }

  @-webkit-keyframes fall-44 {
    72.829% {
      -webkit-transform: translate(47.2629vw, 218.487vh) scale(0.1332);
      transform: translate(47.2629vw, 218.487vh) scale(0.1332);
    }

    to {
      -webkit-transform: translate(51.61825vw, 300vh) scale(0.1332);
      transform: translate(51.61825vw, 300vh) scale(0.1332);
    }
  }

  @keyframes fall-44 {
    72.829% {
      -webkit-transform: translate(47.2629vw, 218.487vh) scale(0.1332);
      transform: translate(47.2629vw, 218.487vh) scale(0.1332);
    }

    to {
      -webkit-transform: translate(51.61825vw, 300vh) scale(0.1332);
      transform: translate(51.61825vw, 300vh) scale(0.1332);
    }
  }

  .snow:nth-child(45) {
    opacity: 0.1319;
    -webkit-transform: translate(17.6846vw, -10px) scale(0.3445);
    transform: translate(17.6846vw, -10px) scale(0.3445);
    -webkit-animation: fall-45 24s linear -29s infinite;
    animation: fall-45 24s linear -29s infinite;
  }

  @-webkit-keyframes fall-45 {
    33.738% {
      -webkit-transform: translate(8.1138vw, 101.214vh) scale(0.3445);
      transform: translate(8.1138vw, 101.214vh) scale(0.3445);
    }

    to {
      -webkit-transform: translate(12.8992vw, 300vh) scale(0.3445);
      transform: translate(12.8992vw, 300vh) scale(0.3445);
    }
  }

  @keyframes fall-45 {
    33.738% {
      -webkit-transform: translate(8.1138vw, 101.214vh) scale(0.3445);
      transform: translate(8.1138vw, 101.214vh) scale(0.3445);
    }

    to {
      -webkit-transform: translate(12.8992vw, 300vh) scale(0.3445);
      transform: translate(12.8992vw, 300vh) scale(0.3445);
    }
  }

  .snow:nth-child(46) {
    opacity: 0.3126;
    -webkit-transform: translate(59.6661vw, -10px) scale(0.5479);
    transform: translate(59.6661vw, -10px) scale(0.5479);
    -webkit-animation: fall-46 37s linear -24s infinite;
    animation: fall-46 37s linear -24s infinite;
  }

  @-webkit-keyframes fall-46 {
    61.711% {
      -webkit-transform: translate(68.2292vw, 185.133vh) scale(0.5479);
      transform: translate(68.2292vw, 185.133vh) scale(0.5479);
    }

    to {
      -webkit-transform: translate(63.94765vw, 300vh) scale(0.5479);
      transform: translate(63.94765vw, 300vh) scale(0.5479);
    }
  }

  @keyframes fall-46 {
    61.711% {
      -webkit-transform: translate(68.2292vw, 185.133vh) scale(0.5479);
      transform: translate(68.2292vw, 185.133vh) scale(0.5479);
    }

    to {
      -webkit-transform: translate(63.94765vw, 300vh) scale(0.5479);
      transform: translate(63.94765vw, 300vh) scale(0.5479);
    }
  }

  .snow:nth-child(47) {
    opacity: 0.5357;
    -webkit-transform: translate(2.2086vw, -10px) scale(0.1843);
    transform: translate(2.2086vw, -10px) scale(0.1843);
    -webkit-animation: fall-47 21s linear -28s infinite;
    animation: fall-47 21s linear -28s infinite;
  }

  @-webkit-keyframes fall-47 {
    50.062% {
      -webkit-transform: translate(-2.2848vw, 150.186vh) scale(0.1843);
      transform: translate(-2.2848vw, 150.186vh) scale(0.1843);
    }

    to {
      -webkit-transform: translate(-0.0381vw, 300vh) scale(0.1843);
      transform: translate(-0.0381vw, 300vh) scale(0.1843);
    }
  }

  @keyframes fall-47 {
    50.062% {
      -webkit-transform: translate(-2.2848vw, 150.186vh) scale(0.1843);
      transform: translate(-2.2848vw, 150.186vh) scale(0.1843);
    }

    to {
      -webkit-transform: translate(-0.0381vw, 300vh) scale(0.1843);
      transform: translate(-0.0381vw, 300vh) scale(0.1843);
    }
  }

  .snow:nth-child(48) {
    opacity: 0.6975;
    -webkit-transform: translate(95.188vw, -10px) scale(0.5604);
    transform: translate(95.188vw, -10px) scale(0.5604);
    -webkit-animation: fall-48 31s linear -12s infinite;
    animation: fall-48 31s linear -12s infinite;
  }

  @-webkit-keyframes fall-48 {
    41.528% {
      -webkit-transform: translate(92.6857vw, 124.584vh) scale(0.5604);
      transform: translate(92.6857vw, 124.584vh) scale(0.5604);
    }

    to {
      -webkit-transform: translate(93.93685vw, 300vh) scale(0.5604);
      transform: translate(93.93685vw, 300vh) scale(0.5604);
    }
  }

  @keyframes fall-48 {
    41.528% {
      -webkit-transform: translate(92.6857vw, 124.584vh) scale(0.5604);
      transform: translate(92.6857vw, 124.584vh) scale(0.5604);
    }

    to {
      -webkit-transform: translate(93.93685vw, 300vh) scale(0.5604);
      transform: translate(93.93685vw, 300vh) scale(0.5604);
    }
  }

  .snow:nth-child(49) {
    opacity: 0.2073;
    -webkit-transform: translate(33.4884vw, -10px) scale(0.1112);
    transform: translate(33.4884vw, -10px) scale(0.1112);
    -webkit-animation: fall-49 40s linear -14s infinite;
    animation: fall-49 40s linear -14s infinite;
  }

  @-webkit-keyframes fall-49 {
    68.661% {
      -webkit-transform: translate(28.1792vw, 205.983vh) scale(0.1112);
      transform: translate(28.1792vw, 205.983vh) scale(0.1112);
    }

    to {
      -webkit-transform: translate(30.8338vw, 300vh) scale(0.1112);
      transform: translate(30.8338vw, 300vh) scale(0.1112);
    }
  }

  @keyframes fall-49 {
    68.661% {
      -webkit-transform: translate(28.1792vw, 205.983vh) scale(0.1112);
      transform: translate(28.1792vw, 205.983vh) scale(0.1112);
    }

    to {
      -webkit-transform: translate(30.8338vw, 300vh) scale(0.1112);
      transform: translate(30.8338vw, 300vh) scale(0.1112);
    }
  }

  .snow:nth-child(50) {
    opacity: 0.5483;
    -webkit-transform: translate(12.011vw, -10px) scale(0.7053);
    transform: translate(12.011vw, -10px) scale(0.7053);
    -webkit-animation: fall-50 40s linear -19s infinite;
    animation: fall-50 40s linear -19s infinite;
  }

  @-webkit-keyframes fall-50 {
    61.011% {
      -webkit-transform: translate(4.9323vw, 183.033vh) scale(0.7053);
      transform: translate(4.9323vw, 183.033vh) scale(0.7053);
    }

    to {
      -webkit-transform: translate(8.47165vw, 300vh) scale(0.7053);
      transform: translate(8.47165vw, 300vh) scale(0.7053);
    }
  }

  @keyframes fall-50 {
    61.011% {
      -webkit-transform: translate(4.9323vw, 183.033vh) scale(0.7053);
      transform: translate(4.9323vw, 183.033vh) scale(0.7053);
    }

    to {
      -webkit-transform: translate(8.47165vw, 300vh) scale(0.7053);
      transform: translate(8.47165vw, 300vh) scale(0.7053);
    }
  }

  .snow:nth-child(51) {
    opacity: 0.7154;
    -webkit-transform: translate(28.7004vw, -10px) scale(0.8206);
    transform: translate(28.7004vw, -10px) scale(0.8206);
    -webkit-animation: fall-51 40s linear -10s infinite;
    animation: fall-51 40s linear -10s infinite;
  }

  @-webkit-keyframes fall-51 {
    51.946% {
      -webkit-transform: translate(34.8245vw, 155.838vh) scale(0.8206);
      transform: translate(34.8245vw, 155.838vh) scale(0.8206);
    }

    to {
      -webkit-transform: translate(31.76245vw, 300vh) scale(0.8206);
      transform: translate(31.76245vw, 300vh) scale(0.8206);
    }
  }

  @keyframes fall-51 {
    51.946% {
      -webkit-transform: translate(34.8245vw, 155.838vh) scale(0.8206);
      transform: translate(34.8245vw, 155.838vh) scale(0.8206);
    }

    to {
      -webkit-transform: translate(31.76245vw, 300vh) scale(0.8206);
      transform: translate(31.76245vw, 300vh) scale(0.8206);
    }
  }

  .snow:nth-child(52) {
    opacity: 0.029;
    -webkit-transform: translate(69.7198vw, -10px) scale(0.3458);
    transform: translate(69.7198vw, -10px) scale(0.3458);
    -webkit-animation: fall-52 33s linear -10s infinite;
    animation: fall-52 33s linear -10s infinite;
  }

  @-webkit-keyframes fall-52 {
    43.613% {
      -webkit-transform: translate(61.5245vw, 130.839vh) scale(0.3458);
      transform: translate(61.5245vw, 130.839vh) scale(0.3458);
    }

    to {
      -webkit-transform: translate(65.62215vw, 300vh) scale(0.3458);
      transform: translate(65.62215vw, 300vh) scale(0.3458);
    }
  }

  @keyframes fall-52 {
    43.613% {
      -webkit-transform: translate(61.5245vw, 130.839vh) scale(0.3458);
      transform: translate(61.5245vw, 130.839vh) scale(0.3458);
    }

    to {
      -webkit-transform: translate(65.62215vw, 300vh) scale(0.3458);
      transform: translate(65.62215vw, 300vh) scale(0.3458);
    }
  }

  .snow:nth-child(53) {
    opacity: 0.1328;
    -webkit-transform: translate(55.5679vw, -10px) scale(0.5752);
    transform: translate(55.5679vw, -10px) scale(0.5752);
    -webkit-animation: fall-53 23s linear -25s infinite;
    animation: fall-53 23s linear -25s infinite;
  }

  @-webkit-keyframes fall-53 {
    70.897% {
      -webkit-transform: translate(52.2807vw, 212.691vh) scale(0.5752);
      transform: translate(52.2807vw, 212.691vh) scale(0.5752);
    }

    to {
      -webkit-transform: translate(53.9243vw, 300vh) scale(0.5752);
      transform: translate(53.9243vw, 300vh) scale(0.5752);
    }
  }

  @keyframes fall-53 {
    70.897% {
      -webkit-transform: translate(52.2807vw, 212.691vh) scale(0.5752);
      transform: translate(52.2807vw, 212.691vh) scale(0.5752);
    }

    to {
      -webkit-transform: translate(53.9243vw, 300vh) scale(0.5752);
      transform: translate(53.9243vw, 300vh) scale(0.5752);
    }
  }

  .snow:nth-child(54) {
    opacity: 0.2056;
    -webkit-transform: translate(58.9835vw, -10px) scale(0.4191);
    transform: translate(58.9835vw, -10px) scale(0.4191);
    -webkit-animation: fall-54 37s linear -27s infinite;
    animation: fall-54 37s linear -27s infinite;
  }

  @-webkit-keyframes fall-54 {
    79.658% {
      -webkit-transform: translate(57.3657vw, 238.974vh) scale(0.4191);
      transform: translate(57.3657vw, 238.974vh) scale(0.4191);
    }

    to {
      -webkit-transform: translate(58.1746vw, 300vh) scale(0.4191);
      transform: translate(58.1746vw, 300vh) scale(0.4191);
    }
  }

  @keyframes fall-54 {
    79.658% {
      -webkit-transform: translate(57.3657vw, 238.974vh) scale(0.4191);
      transform: translate(57.3657vw, 238.974vh) scale(0.4191);
    }

    to {
      -webkit-transform: translate(58.1746vw, 300vh) scale(0.4191);
      transform: translate(58.1746vw, 300vh) scale(0.4191);
    }
  }

  .snow:nth-child(55) {
    opacity: 0.9877;
    -webkit-transform: translate(79.3012vw, -10px) scale(0.4218);
    transform: translate(79.3012vw, -10px) scale(0.4218);
    -webkit-animation: fall-55 39s linear -3s infinite;
    animation: fall-55 39s linear -3s infinite;
  }

  @-webkit-keyframes fall-55 {
    55.349% {
      -webkit-transform: translate(87.2488vw, 166.047vh) scale(0.4218);
      transform: translate(87.2488vw, 166.047vh) scale(0.4218);
    }

    to {
      -webkit-transform: translate(83.275vw, 300vh) scale(0.4218);
      transform: translate(83.275vw, 300vh) scale(0.4218);
    }
  }

  @keyframes fall-55 {
    55.349% {
      -webkit-transform: translate(87.2488vw, 166.047vh) scale(0.4218);
      transform: translate(87.2488vw, 166.047vh) scale(0.4218);
    }

    to {
      -webkit-transform: translate(83.275vw, 300vh) scale(0.4218);
      transform: translate(83.275vw, 300vh) scale(0.4218);
    }
  }

  .snow:nth-child(56) {
    opacity: 0.9738;
    -webkit-transform: translate(72.6147vw, -10px) scale(0.3619);
    transform: translate(72.6147vw, -10px) scale(0.3619);
    -webkit-animation: fall-56 36s linear -14s infinite;
    animation: fall-56 36s linear -14s infinite;
  }

  @-webkit-keyframes fall-56 {
    34.955% {
      -webkit-transform: translate(68.9492vw, 104.865vh) scale(0.3619);
      transform: translate(68.9492vw, 104.865vh) scale(0.3619);
    }

    to {
      -webkit-transform: translate(70.78195vw, 300vh) scale(0.3619);
      transform: translate(70.78195vw, 300vh) scale(0.3619);
    }
  }

  @keyframes fall-56 {
    34.955% {
      -webkit-transform: translate(68.9492vw, 104.865vh) scale(0.3619);
      transform: translate(68.9492vw, 104.865vh) scale(0.3619);
    }

    to {
      -webkit-transform: translate(70.78195vw, 300vh) scale(0.3619);
      transform: translate(70.78195vw, 300vh) scale(0.3619);
    }
  }

  .snow:nth-child(57) {
    opacity: 0.7027;
    -webkit-transform: translate(98.0597vw, -10px) scale(0.9685);
    transform: translate(98.0597vw, -10px) scale(0.9685);
    -webkit-animation: fall-57 25s linear -12s infinite;
    animation: fall-57 25s linear -12s infinite;
  }

  @-webkit-keyframes fall-57 {
    31.579% {
      -webkit-transform: translate(93.7382vw, 94.737vh) scale(0.9685);
      transform: translate(93.7382vw, 94.737vh) scale(0.9685);
    }

    to {
      -webkit-transform: translate(95.89895vw, 300vh) scale(0.9685);
      transform: translate(95.89895vw, 300vh) scale(0.9685);
    }
  }

  @keyframes fall-57 {
    31.579% {
      -webkit-transform: translate(93.7382vw, 94.737vh) scale(0.9685);
      transform: translate(93.7382vw, 94.737vh) scale(0.9685);
    }

    to {
      -webkit-transform: translate(95.89895vw, 300vh) scale(0.9685);
      transform: translate(95.89895vw, 300vh) scale(0.9685);
    }
  }

  .snow:nth-child(58) {
    opacity: 0.1298;
    -webkit-transform: translate(20.2383vw, -10px) scale(0.8423);
    transform: translate(20.2383vw, -10px) scale(0.8423);
    -webkit-animation: fall-58 25s linear -17s infinite;
    animation: fall-58 25s linear -17s infinite;
  }

  @-webkit-keyframes fall-58 {
    30.216% {
      -webkit-transform: translate(22.9233vw, 90.648vh) scale(0.8423);
      transform: translate(22.9233vw, 90.648vh) scale(0.8423);
    }

    to {
      -webkit-transform: translate(21.5808vw, 300vh) scale(0.8423);
      transform: translate(21.5808vw, 300vh) scale(0.8423);
    }
  }

  @keyframes fall-58 {
    30.216% {
      -webkit-transform: translate(22.9233vw, 90.648vh) scale(0.8423);
      transform: translate(22.9233vw, 90.648vh) scale(0.8423);
    }

    to {
      -webkit-transform: translate(21.5808vw, 300vh) scale(0.8423);
      transform: translate(21.5808vw, 300vh) scale(0.8423);
    }
  }

  .snow:nth-child(59) {
    opacity: 0.5673;
    -webkit-transform: translate(64.4113vw, -10px) scale(0.7721);
    transform: translate(64.4113vw, -10px) scale(0.7721);
    -webkit-animation: fall-59 35s linear -4s infinite;
    animation: fall-59 35s linear -4s infinite;
  }

  @-webkit-keyframes fall-59 {
    73.475% {
      -webkit-transform: translate(56.2071vw, 220.425vh) scale(0.7721);
      transform: translate(56.2071vw, 220.425vh) scale(0.7721);
    }

    to {
      -webkit-transform: translate(60.3092vw, 300vh) scale(0.7721);
      transform: translate(60.3092vw, 300vh) scale(0.7721);
    }
  }

  @keyframes fall-59 {
    73.475% {
      -webkit-transform: translate(56.2071vw, 220.425vh) scale(0.7721);
      transform: translate(56.2071vw, 220.425vh) scale(0.7721);
    }

    to {
      -webkit-transform: translate(60.3092vw, 300vh) scale(0.7721);
      transform: translate(60.3092vw, 300vh) scale(0.7721);
    }
  }

  .snow:nth-child(60) {
    opacity: 0.3994;
    -webkit-transform: translate(99.0294vw, -10px) scale(0.4965);
    transform: translate(99.0294vw, -10px) scale(0.4965);
    -webkit-animation: fall-60 29s linear -17s infinite;
    animation: fall-60 29s linear -17s infinite;
  }

  @-webkit-keyframes fall-60 {
    37.387% {
      -webkit-transform: translate(107.8197vw, 112.161vh) scale(0.4965);
      transform: translate(107.8197vw, 112.161vh) scale(0.4965);
    }

    to {
      -webkit-transform: translate(103.42455vw, 300vh) scale(0.4965);
      transform: translate(103.42455vw, 300vh) scale(0.4965);
    }
  }

  @keyframes fall-60 {
    37.387% {
      -webkit-transform: translate(107.8197vw, 112.161vh) scale(0.4965);
      transform: translate(107.8197vw, 112.161vh) scale(0.4965);
    }

    to {
      -webkit-transform: translate(103.42455vw, 300vh) scale(0.4965);
      transform: translate(103.42455vw, 300vh) scale(0.4965);
    }
  }

  .snow:nth-child(61) {
    opacity: 0.8996;
    -webkit-transform: translate(75.166vw, -10px) scale(0.2399);
    transform: translate(75.166vw, -10px) scale(0.2399);
    -webkit-animation: fall-61 29s linear -21s infinite;
    animation: fall-61 29s linear -21s infinite;
  }

  @-webkit-keyframes fall-61 {
    47.895% {
      -webkit-transform: translate(78.7397vw, 143.685vh) scale(0.2399);
      transform: translate(78.7397vw, 143.685vh) scale(0.2399);
    }

    to {
      -webkit-transform: translate(76.95285vw, 300vh) scale(0.2399);
      transform: translate(76.95285vw, 300vh) scale(0.2399);
    }
  }

  @keyframes fall-61 {
    47.895% {
      -webkit-transform: translate(78.7397vw, 143.685vh) scale(0.2399);
      transform: translate(78.7397vw, 143.685vh) scale(0.2399);
    }

    to {
      -webkit-transform: translate(76.95285vw, 300vh) scale(0.2399);
      transform: translate(76.95285vw, 300vh) scale(0.2399);
    }
  }

  .snow:nth-child(62) {
    opacity: 0.1465;
    -webkit-transform: translate(70.8819vw, -10px) scale(0.56);
    transform: translate(70.8819vw, -10px) scale(0.56);
    -webkit-animation: fall-62 37s linear -17s infinite;
    animation: fall-62 37s linear -17s infinite;
  }

  @-webkit-keyframes fall-62 {
    55.494% {
      -webkit-transform: translate(70.1781vw, 166.482vh) scale(0.56);
      transform: translate(70.1781vw, 166.482vh) scale(0.56);
    }

    to {
      -webkit-transform: translate(70.53vw, 300vh) scale(0.56);
      transform: translate(70.53vw, 300vh) scale(0.56);
    }
  }

  @keyframes fall-62 {
    55.494% {
      -webkit-transform: translate(70.1781vw, 166.482vh) scale(0.56);
      transform: translate(70.1781vw, 166.482vh) scale(0.56);
    }

    to {
      -webkit-transform: translate(70.53vw, 300vh) scale(0.56);
      transform: translate(70.53vw, 300vh) scale(0.56);
    }
  }

  .snow:nth-child(63) {
    opacity: 0.1514;
    -webkit-transform: translate(28.3925vw, -10px) scale(0.8053);
    transform: translate(28.3925vw, -10px) scale(0.8053);
    -webkit-animation: fall-63 29s linear -29s infinite;
    animation: fall-63 29s linear -29s infinite;
  }

  @-webkit-keyframes fall-63 {
    62.678% {
      -webkit-transform: translate(28.2026vw, 188.034vh) scale(0.8053);
      transform: translate(28.2026vw, 188.034vh) scale(0.8053);
    }

    to {
      -webkit-transform: translate(28.29755vw, 300vh) scale(0.8053);
      transform: translate(28.29755vw, 300vh) scale(0.8053);
    }
  }

  @keyframes fall-63 {
    62.678% {
      -webkit-transform: translate(28.2026vw, 188.034vh) scale(0.8053);
      transform: translate(28.2026vw, 188.034vh) scale(0.8053);
    }

    to {
      -webkit-transform: translate(28.29755vw, 300vh) scale(0.8053);
      transform: translate(28.29755vw, 300vh) scale(0.8053);
    }
  }

  .snow:nth-child(64) {
    opacity: 0.0838;
    -webkit-transform: translate(10.8265vw, -10px) scale(0.2112);
    transform: translate(10.8265vw, -10px) scale(0.2112);
    -webkit-animation: fall-64 32s linear -19s infinite;
    animation: fall-64 32s linear -19s infinite;
  }

  @-webkit-keyframes fall-64 {
    70.264% {
      -webkit-transform: translate(11.8499vw, 210.792vh) scale(0.2112);
      transform: translate(11.8499vw, 210.792vh) scale(0.2112);
    }

    to {
      -webkit-transform: translate(11.3382vw, 300vh) scale(0.2112);
      transform: translate(11.3382vw, 300vh) scale(0.2112);
    }
  }

  @keyframes fall-64 {
    70.264% {
      -webkit-transform: translate(11.8499vw, 210.792vh) scale(0.2112);
      transform: translate(11.8499vw, 210.792vh) scale(0.2112);
    }

    to {
      -webkit-transform: translate(11.3382vw, 300vh) scale(0.2112);
      transform: translate(11.3382vw, 300vh) scale(0.2112);
    }
  }

  .snow:nth-child(65) {
    opacity: 0.0015;
    -webkit-transform: translate(24.9394vw, -10px) scale(0.9839);
    transform: translate(24.9394vw, -10px) scale(0.9839);
    -webkit-animation: fall-65 39s linear -26s infinite;
    animation: fall-65 39s linear -26s infinite;
  }

  @-webkit-keyframes fall-65 {
    42.379% {
      -webkit-transform: translate(33.9459vw, 127.137vh) scale(0.9839);
      transform: translate(33.9459vw, 127.137vh) scale(0.9839);
    }

    to {
      -webkit-transform: translate(29.44265vw, 300vh) scale(0.9839);
      transform: translate(29.44265vw, 300vh) scale(0.9839);
    }
  }

  @keyframes fall-65 {
    42.379% {
      -webkit-transform: translate(33.9459vw, 127.137vh) scale(0.9839);
      transform: translate(33.9459vw, 127.137vh) scale(0.9839);
    }

    to {
      -webkit-transform: translate(29.44265vw, 300vh) scale(0.9839);
      transform: translate(29.44265vw, 300vh) scale(0.9839);
    }
  }

  .snow:nth-child(66) {
    opacity: 0.5731;
    -webkit-transform: translate(83.7491vw, -10px) scale(0.6669);
    transform: translate(83.7491vw, -10px) scale(0.6669);
    -webkit-animation: fall-66 26s linear -27s infinite;
    animation: fall-66 26s linear -27s infinite;
  }

  @-webkit-keyframes fall-66 {
    51.472% {
      -webkit-transform: translate(87.2626vw, 154.416vh) scale(0.6669);
      transform: translate(87.2626vw, 154.416vh) scale(0.6669);
    }

    to {
      -webkit-transform: translate(85.50585vw, 300vh) scale(0.6669);
      transform: translate(85.50585vw, 300vh) scale(0.6669);
    }
  }

  @keyframes fall-66 {
    51.472% {
      -webkit-transform: translate(87.2626vw, 154.416vh) scale(0.6669);
      transform: translate(87.2626vw, 154.416vh) scale(0.6669);
    }

    to {
      -webkit-transform: translate(85.50585vw, 300vh) scale(0.6669);
      transform: translate(85.50585vw, 300vh) scale(0.6669);
    }
  }

  .snow:nth-child(67) {
    opacity: 0.4119;
    -webkit-transform: translate(8.8369vw, -10px) scale(0.0592);
    transform: translate(8.8369vw, -10px) scale(0.0592);
    -webkit-animation: fall-67 40s linear -30s infinite;
    animation: fall-67 40s linear -30s infinite;
  }

  @-webkit-keyframes fall-67 {
    32.81% {
      -webkit-transform: translate(-0.2772vw, 98.43vh) scale(0.0592);
      transform: translate(-0.2772vw, 98.43vh) scale(0.0592);
    }

    to {
      -webkit-transform: translate(4.27985vw, 300vh) scale(0.0592);
      transform: translate(4.27985vw, 300vh) scale(0.0592);
    }
  }

  @keyframes fall-67 {
    32.81% {
      -webkit-transform: translate(-0.2772vw, 98.43vh) scale(0.0592);
      transform: translate(-0.2772vw, 98.43vh) scale(0.0592);
    }

    to {
      -webkit-transform: translate(4.27985vw, 300vh) scale(0.0592);
      transform: translate(4.27985vw, 300vh) scale(0.0592);
    }
  }

  .snow:nth-child(68) {
    opacity: 0.8992;
    -webkit-transform: translate(74.1566vw, -10px) scale(0.6264);
    transform: translate(74.1566vw, -10px) scale(0.6264);
    -webkit-animation: fall-68 33s linear -13s infinite;
    animation: fall-68 33s linear -13s infinite;
  }

  @-webkit-keyframes fall-68 {
    54.923% {
      -webkit-transform: translate(70.1934vw, 164.769vh) scale(0.6264);
      transform: translate(70.1934vw, 164.769vh) scale(0.6264);
    }

    to {
      -webkit-transform: translate(72.175vw, 300vh) scale(0.6264);
      transform: translate(72.175vw, 300vh) scale(0.6264);
    }
  }

  @keyframes fall-68 {
    54.923% {
      -webkit-transform: translate(70.1934vw, 164.769vh) scale(0.6264);
      transform: translate(70.1934vw, 164.769vh) scale(0.6264);
    }

    to {
      -webkit-transform: translate(72.175vw, 300vh) scale(0.6264);
      transform: translate(72.175vw, 300vh) scale(0.6264);
    }
  }

  .snow:nth-child(69) {
    opacity: 0.4566;
    -webkit-transform: translate(90.1241vw, -10px) scale(0.6126);
    transform: translate(90.1241vw, -10px) scale(0.6126);
    -webkit-animation: fall-69 30s linear -21s infinite;
    animation: fall-69 30s linear -21s infinite;
  }

  @-webkit-keyframes fall-69 {
    46.009% {
      -webkit-transform: translate(95.4179vw, 138.027vh) scale(0.6126);
      transform: translate(95.4179vw, 138.027vh) scale(0.6126);
    }

    to {
      -webkit-transform: translate(92.771vw, 300vh) scale(0.6126);
      transform: translate(92.771vw, 300vh) scale(0.6126);
    }
  }

  @keyframes fall-69 {
    46.009% {
      -webkit-transform: translate(95.4179vw, 138.027vh) scale(0.6126);
      transform: translate(95.4179vw, 138.027vh) scale(0.6126);
    }

    to {
      -webkit-transform: translate(92.771vw, 300vh) scale(0.6126);
      transform: translate(92.771vw, 300vh) scale(0.6126);
    }
  }

  .snow:nth-child(70) {
    opacity: 0.5142;
    -webkit-transform: translate(54.9086vw, -10px) scale(0.3597);
    transform: translate(54.9086vw, -10px) scale(0.3597);
    -webkit-animation: fall-70 37s linear -14s infinite;
    animation: fall-70 37s linear -14s infinite;
  }

  @-webkit-keyframes fall-70 {
    45.914% {
      -webkit-transform: translate(54.5045vw, 137.742vh) scale(0.3597);
      transform: translate(54.5045vw, 137.742vh) scale(0.3597);
    }

    to {
      -webkit-transform: translate(54.70655vw, 300vh) scale(0.3597);
      transform: translate(54.70655vw, 300vh) scale(0.3597);
    }
  }

  @keyframes fall-70 {
    45.914% {
      -webkit-transform: translate(54.5045vw, 137.742vh) scale(0.3597);
      transform: translate(54.5045vw, 137.742vh) scale(0.3597);
    }

    to {
      -webkit-transform: translate(54.70655vw, 300vh) scale(0.3597);
      transform: translate(54.70655vw, 300vh) scale(0.3597);
    }
  }

  .snow:nth-child(71) {
    opacity: 0.3597;
    -webkit-transform: translate(6.5202vw, -10px) scale(0.8322);
    transform: translate(6.5202vw, -10px) scale(0.8322);
    -webkit-animation: fall-71 30s linear -10s infinite;
    animation: fall-71 30s linear -10s infinite;
  }

  @-webkit-keyframes fall-71 {
    75.648% {
      -webkit-transform: translate(10.198vw, 226.944vh) scale(0.8322);
      transform: translate(10.198vw, 226.944vh) scale(0.8322);
    }

    to {
      -webkit-transform: translate(8.3591vw, 300vh) scale(0.8322);
      transform: translate(8.3591vw, 300vh) scale(0.8322);
    }
  }

  @keyframes fall-71 {
    75.648% {
      -webkit-transform: translate(10.198vw, 226.944vh) scale(0.8322);
      transform: translate(10.198vw, 226.944vh) scale(0.8322);
    }

    to {
      -webkit-transform: translate(8.3591vw, 300vh) scale(0.8322);
      transform: translate(8.3591vw, 300vh) scale(0.8322);
    }
  }

  .snow:nth-child(72) {
    opacity: 0.7134;
    -webkit-transform: translate(49.9962vw, -10px) scale(0.436);
    transform: translate(49.9962vw, -10px) scale(0.436);
    -webkit-animation: fall-72 33s linear -2s infinite;
    animation: fall-72 33s linear -2s infinite;
  }

  @-webkit-keyframes fall-72 {
    30.107% {
      -webkit-transform: translate(50.3245vw, 90.321vh) scale(0.436);
      transform: translate(50.3245vw, 90.321vh) scale(0.436);
    }

    to {
      -webkit-transform: translate(50.16035vw, 300vh) scale(0.436);
      transform: translate(50.16035vw, 300vh) scale(0.436);
    }
  }

  @keyframes fall-72 {
    30.107% {
      -webkit-transform: translate(50.3245vw, 90.321vh) scale(0.436);
      transform: translate(50.3245vw, 90.321vh) scale(0.436);
    }

    to {
      -webkit-transform: translate(50.16035vw, 300vh) scale(0.436);
      transform: translate(50.16035vw, 300vh) scale(0.436);
    }
  }

  .snow:nth-child(73) {
    opacity: 0.9008;
    -webkit-transform: translate(86.7576vw, -10px) scale(0.1109);
    transform: translate(86.7576vw, -10px) scale(0.1109);
    -webkit-animation: fall-73 31s linear -12s infinite;
    animation: fall-73 31s linear -12s infinite;
  }

  @-webkit-keyframes fall-73 {
    77.221% {
      -webkit-transform: translate(84.8675vw, 231.663vh) scale(0.1109);
      transform: translate(84.8675vw, 231.663vh) scale(0.1109);
    }

    to {
      -webkit-transform: translate(85.81255vw, 300vh) scale(0.1109);
      transform: translate(85.81255vw, 300vh) scale(0.1109);
    }
  }

  @keyframes fall-73 {
    77.221% {
      -webkit-transform: translate(84.8675vw, 231.663vh) scale(0.1109);
      transform: translate(84.8675vw, 231.663vh) scale(0.1109);
    }

    to {
      -webkit-transform: translate(85.81255vw, 300vh) scale(0.1109);
      transform: translate(85.81255vw, 300vh) scale(0.1109);
    }
  }

  .snow:nth-child(74) {
    opacity: 0.9982;
    -webkit-transform: translate(75.7695vw, -10px) scale(0.4907);
    transform: translate(75.7695vw, -10px) scale(0.4907);
    -webkit-animation: fall-74 23s linear -4s infinite;
    animation: fall-74 23s linear -4s infinite;
  }

  @-webkit-keyframes fall-74 {
    54.058% {
      -webkit-transform: translate(80.6946vw, 162.174vh) scale(0.4907);
      transform: translate(80.6946vw, 162.174vh) scale(0.4907);
    }

    to {
      -webkit-transform: translate(78.23205vw, 300vh) scale(0.4907);
      transform: translate(78.23205vw, 300vh) scale(0.4907);
    }
  }

  @keyframes fall-74 {
    54.058% {
      -webkit-transform: translate(80.6946vw, 162.174vh) scale(0.4907);
      transform: translate(80.6946vw, 162.174vh) scale(0.4907);
    }

    to {
      -webkit-transform: translate(78.23205vw, 300vh) scale(0.4907);
      transform: translate(78.23205vw, 300vh) scale(0.4907);
    }
  }

  .snow:nth-child(75) {
    opacity: 0.9188;
    -webkit-transform: translate(69.84vw, -10px) scale(0.2026);
    transform: translate(69.84vw, -10px) scale(0.2026);
    -webkit-animation: fall-75 31s linear -27s infinite;
    animation: fall-75 31s linear -27s infinite;
  }

  @-webkit-keyframes fall-75 {
    42.955% {
      -webkit-transform: translate(60.3274vw, 128.865vh) scale(0.2026);
      transform: translate(60.3274vw, 128.865vh) scale(0.2026);
    }

    to {
      -webkit-transform: translate(65.0837vw, 300vh) scale(0.2026);
      transform: translate(65.0837vw, 300vh) scale(0.2026);
    }
  }

  @keyframes fall-75 {
    42.955% {
      -webkit-transform: translate(60.3274vw, 128.865vh) scale(0.2026);
      transform: translate(60.3274vw, 128.865vh) scale(0.2026);
    }

    to {
      -webkit-transform: translate(65.0837vw, 300vh) scale(0.2026);
      transform: translate(65.0837vw, 300vh) scale(0.2026);
    }
  }

  .snow:nth-child(76) {
    opacity: 0.2613;
    -webkit-transform: translate(63.5973vw, -10px) scale(0.7001);
    transform: translate(63.5973vw, -10px) scale(0.7001);
    -webkit-animation: fall-76 31s linear -28s infinite;
    animation: fall-76 31s linear -28s infinite;
  }

  @-webkit-keyframes fall-76 {
    37.177% {
      -webkit-transform: translate(55.3589vw, 111.531vh) scale(0.7001);
      transform: translate(55.3589vw, 111.531vh) scale(0.7001);
    }

    to {
      -webkit-transform: translate(59.4781vw, 300vh) scale(0.7001);
      transform: translate(59.4781vw, 300vh) scale(0.7001);
    }
  }

  @keyframes fall-76 {
    37.177% {
      -webkit-transform: translate(55.3589vw, 111.531vh) scale(0.7001);
      transform: translate(55.3589vw, 111.531vh) scale(0.7001);
    }

    to {
      -webkit-transform: translate(59.4781vw, 300vh) scale(0.7001);
      transform: translate(59.4781vw, 300vh) scale(0.7001);
    }
  }

  .snow:nth-child(77) {
    opacity: 0.218;
    -webkit-transform: translate(6.0676vw, -10px) scale(0.1663);
    transform: translate(6.0676vw, -10px) scale(0.1663);
    -webkit-animation: fall-77 37s linear -1s infinite;
    animation: fall-77 37s linear -1s infinite;
  }

  @-webkit-keyframes fall-77 {
    43.21% {
      -webkit-transform: translate(3.1666vw, 129.63vh) scale(0.1663);
      transform: translate(3.1666vw, 129.63vh) scale(0.1663);
    }

    to {
      -webkit-transform: translate(4.6171vw, 300vh) scale(0.1663);
      transform: translate(4.6171vw, 300vh) scale(0.1663);
    }
  }

  @keyframes fall-77 {
    43.21% {
      -webkit-transform: translate(3.1666vw, 129.63vh) scale(0.1663);
      transform: translate(3.1666vw, 129.63vh) scale(0.1663);
    }

    to {
      -webkit-transform: translate(4.6171vw, 300vh) scale(0.1663);
      transform: translate(4.6171vw, 300vh) scale(0.1663);
    }
  }

  .snow:nth-child(78) {
    opacity: 0.6764;
    -webkit-transform: translate(23.919vw, -10px) scale(0.5176);
    transform: translate(23.919vw, -10px) scale(0.5176);
    -webkit-animation: fall-78 20s linear -12s infinite;
    animation: fall-78 20s linear -12s infinite;
  }

  @-webkit-keyframes fall-78 {
    34.734% {
      -webkit-transform: translate(33.0081vw, 104.202vh) scale(0.5176);
      transform: translate(33.0081vw, 104.202vh) scale(0.5176);
    }

    to {
      -webkit-transform: translate(28.46355vw, 300vh) scale(0.5176);
      transform: translate(28.46355vw, 300vh) scale(0.5176);
    }
  }

  @keyframes fall-78 {
    34.734% {
      -webkit-transform: translate(33.0081vw, 104.202vh) scale(0.5176);
      transform: translate(33.0081vw, 104.202vh) scale(0.5176);
    }

    to {
      -webkit-transform: translate(28.46355vw, 300vh) scale(0.5176);
      transform: translate(28.46355vw, 300vh) scale(0.5176);
    }
  }

  .snow:nth-child(79) {
    opacity: 0.8326;
    -webkit-transform: translate(69.5474vw, -10px) scale(0.3607);
    transform: translate(69.5474vw, -10px) scale(0.3607);
    -webkit-animation: fall-79 36s linear -30s infinite;
    animation: fall-79 36s linear -30s infinite;
  }

  @-webkit-keyframes fall-79 {
    49.095% {
      -webkit-transform: translate(72.7674vw, 147.285vh) scale(0.3607);
      transform: translate(72.7674vw, 147.285vh) scale(0.3607);
    }

    to {
      -webkit-transform: translate(71.1574vw, 300vh) scale(0.3607);
      transform: translate(71.1574vw, 300vh) scale(0.3607);
    }
  }

  @keyframes fall-79 {
    49.095% {
      -webkit-transform: translate(72.7674vw, 147.285vh) scale(0.3607);
      transform: translate(72.7674vw, 147.285vh) scale(0.3607);
    }

    to {
      -webkit-transform: translate(71.1574vw, 300vh) scale(0.3607);
      transform: translate(71.1574vw, 300vh) scale(0.3607);
    }
  }

  .snow:nth-child(80) {
    opacity: 0.3274;
    -webkit-transform: translate(38.3064vw, -10px) scale(0.9345);
    transform: translate(38.3064vw, -10px) scale(0.9345);
    -webkit-animation: fall-80 34s linear -15s infinite;
    animation: fall-80 34s linear -15s infinite;
  }

  @-webkit-keyframes fall-80 {
    43.22% {
      -webkit-transform: translate(44.7477vw, 129.66vh) scale(0.9345);
      transform: translate(44.7477vw, 129.66vh) scale(0.9345);
    }

    to {
      -webkit-transform: translate(41.52705vw, 300vh) scale(0.9345);
      transform: translate(41.52705vw, 300vh) scale(0.9345);
    }
  }

  @keyframes fall-80 {
    43.22% {
      -webkit-transform: translate(44.7477vw, 129.66vh) scale(0.9345);
      transform: translate(44.7477vw, 129.66vh) scale(0.9345);
    }

    to {
      -webkit-transform: translate(41.52705vw, 300vh) scale(0.9345);
      transform: translate(41.52705vw, 300vh) scale(0.9345);
    }
  }

  .snow:nth-child(81) {
    opacity: 0.5983;
    -webkit-transform: translate(20.7491vw, -10px) scale(0.0028);
    transform: translate(20.7491vw, -10px) scale(0.0028);
    -webkit-animation: fall-81 39s linear -9s infinite;
    animation: fall-81 39s linear -9s infinite;
  }

  @-webkit-keyframes fall-81 {
    52.46% {
      -webkit-transform: translate(30.3489vw, 157.38vh) scale(0.0028);
      transform: translate(30.3489vw, 157.38vh) scale(0.0028);
    }

    to {
      -webkit-transform: translate(25.549vw, 300vh) scale(0.0028);
      transform: translate(25.549vw, 300vh) scale(0.0028);
    }
  }

  @keyframes fall-81 {
    52.46% {
      -webkit-transform: translate(30.3489vw, 157.38vh) scale(0.0028);
      transform: translate(30.3489vw, 157.38vh) scale(0.0028);
    }

    to {
      -webkit-transform: translate(25.549vw, 300vh) scale(0.0028);
      transform: translate(25.549vw, 300vh) scale(0.0028);
    }
  }

  .snow:nth-child(82) {
    opacity: 0.041;
    -webkit-transform: translate(43.5354vw, -10px) scale(0.9689);
    transform: translate(43.5354vw, -10px) scale(0.9689);
    -webkit-animation: fall-82 30s linear -17s infinite;
    animation: fall-82 30s linear -17s infinite;
  }

  @-webkit-keyframes fall-82 {
    66.171% {
      -webkit-transform: translate(48.2074vw, 198.513vh) scale(0.9689);
      transform: translate(48.2074vw, 198.513vh) scale(0.9689);
    }

    to {
      -webkit-transform: translate(45.8714vw, 300vh) scale(0.9689);
      transform: translate(45.8714vw, 300vh) scale(0.9689);
    }
  }

  @keyframes fall-82 {
    66.171% {
      -webkit-transform: translate(48.2074vw, 198.513vh) scale(0.9689);
      transform: translate(48.2074vw, 198.513vh) scale(0.9689);
    }

    to {
      -webkit-transform: translate(45.8714vw, 300vh) scale(0.9689);
      transform: translate(45.8714vw, 300vh) scale(0.9689);
    }
  }

  .snow:nth-child(83) {
    opacity: 0.3334;
    -webkit-transform: translate(91.0193vw, -10px) scale(0.1987);
    transform: translate(91.0193vw, -10px) scale(0.1987);
    -webkit-animation: fall-83 29s linear -11s infinite;
    animation: fall-83 29s linear -11s infinite;
  }

  @-webkit-keyframes fall-83 {
    60.143% {
      -webkit-transform: translate(94.8965vw, 180.429vh) scale(0.1987);
      transform: translate(94.8965vw, 180.429vh) scale(0.1987);
    }

    to {
      -webkit-transform: translate(92.9579vw, 300vh) scale(0.1987);
      transform: translate(92.9579vw, 300vh) scale(0.1987);
    }
  }

  @keyframes fall-83 {
    60.143% {
      -webkit-transform: translate(94.8965vw, 180.429vh) scale(0.1987);
      transform: translate(94.8965vw, 180.429vh) scale(0.1987);
    }

    to {
      -webkit-transform: translate(92.9579vw, 300vh) scale(0.1987);
      transform: translate(92.9579vw, 300vh) scale(0.1987);
    }
  }

  .snow:nth-child(84) {
    opacity: 0.7674;
    -webkit-transform: translate(60.0044vw, -10px) scale(0.68);
    transform: translate(60.0044vw, -10px) scale(0.68);
    -webkit-animation: fall-84 21s linear -24s infinite;
    animation: fall-84 21s linear -24s infinite;
  }

  @-webkit-keyframes fall-84 {
    76.203% {
      -webkit-transform: translate(67.9261vw, 228.609vh) scale(0.68);
      transform: translate(67.9261vw, 228.609vh) scale(0.68);
    }

    to {
      -webkit-transform: translate(63.96525vw, 300vh) scale(0.68);
      transform: translate(63.96525vw, 300vh) scale(0.68);
    }
  }

  @keyframes fall-84 {
    76.203% {
      -webkit-transform: translate(67.9261vw, 228.609vh) scale(0.68);
      transform: translate(67.9261vw, 228.609vh) scale(0.68);
    }

    to {
      -webkit-transform: translate(63.96525vw, 300vh) scale(0.68);
      transform: translate(63.96525vw, 300vh) scale(0.68);
    }
  }

  .snow:nth-child(85) {
    opacity: 0.8725;
    -webkit-transform: translate(16.3934vw, -10px) scale(0.7338);
    transform: translate(16.3934vw, -10px) scale(0.7338);
    -webkit-animation: fall-85 35s linear -9s infinite;
    animation: fall-85 35s linear -9s infinite;
  }

  @-webkit-keyframes fall-85 {
    69.414% {
      -webkit-transform: translate(22.4906vw, 208.242vh) scale(0.7338);
      transform: translate(22.4906vw, 208.242vh) scale(0.7338);
    }

    to {
      -webkit-transform: translate(19.442vw, 300vh) scale(0.7338);
      transform: translate(19.442vw, 300vh) scale(0.7338);
    }
  }

  @keyframes fall-85 {
    69.414% {
      -webkit-transform: translate(22.4906vw, 208.242vh) scale(0.7338);
      transform: translate(22.4906vw, 208.242vh) scale(0.7338);
    }

    to {
      -webkit-transform: translate(19.442vw, 300vh) scale(0.7338);
      transform: translate(19.442vw, 300vh) scale(0.7338);
    }
  }

  .snow:nth-child(86) {
    opacity: 0.618;
    -webkit-transform: translate(33.0325vw, -10px) scale(0.5619);
    transform: translate(33.0325vw, -10px) scale(0.5619);
    -webkit-animation: fall-86 31s linear -3s infinite;
    animation: fall-86 31s linear -3s infinite;
  }

  @-webkit-keyframes fall-86 {
    39.785% {
      -webkit-transform: translate(27.973vw, 119.355vh) scale(0.5619);
      transform: translate(27.973vw, 119.355vh) scale(0.5619);
    }

    to {
      -webkit-transform: translate(30.50275vw, 300vh) scale(0.5619);
      transform: translate(30.50275vw, 300vh) scale(0.5619);
    }
  }

  @keyframes fall-86 {
    39.785% {
      -webkit-transform: translate(27.973vw, 119.355vh) scale(0.5619);
      transform: translate(27.973vw, 119.355vh) scale(0.5619);
    }

    to {
      -webkit-transform: translate(30.50275vw, 300vh) scale(0.5619);
      transform: translate(30.50275vw, 300vh) scale(0.5619);
    }
  }

  .snow:nth-child(87) {
    opacity: 0.1441;
    -webkit-transform: translate(35.8865vw, -10px) scale(0.5581);
    transform: translate(35.8865vw, -10px) scale(0.5581);
    -webkit-animation: fall-87 24s linear -8s infinite;
    animation: fall-87 24s linear -8s infinite;
  }

  @-webkit-keyframes fall-87 {
    70.87% {
      -webkit-transform: translate(40.6965vw, 212.61vh) scale(0.5581);
      transform: translate(40.6965vw, 212.61vh) scale(0.5581);
    }

    to {
      -webkit-transform: translate(38.2915vw, 300vh) scale(0.5581);
      transform: translate(38.2915vw, 300vh) scale(0.5581);
    }
  }

  @keyframes fall-87 {
    70.87% {
      -webkit-transform: translate(40.6965vw, 212.61vh) scale(0.5581);
      transform: translate(40.6965vw, 212.61vh) scale(0.5581);
    }

    to {
      -webkit-transform: translate(38.2915vw, 300vh) scale(0.5581);
      transform: translate(38.2915vw, 300vh) scale(0.5581);
    }
  }

  .snow:nth-child(88) {
    opacity: 0.8526;
    -webkit-transform: translate(44.6964vw, -10px) scale(0.0341);
    transform: translate(44.6964vw, -10px) scale(0.0341);
    -webkit-animation: fall-88 40s linear -27s infinite;
    animation: fall-88 40s linear -27s infinite;
  }

  @-webkit-keyframes fall-88 {
    41.526% {
      -webkit-transform: translate(44.4953vw, 124.578vh) scale(0.0341);
      transform: translate(44.4953vw, 124.578vh) scale(0.0341);
    }

    to {
      -webkit-transform: translate(44.59585vw, 300vh) scale(0.0341);
      transform: translate(44.59585vw, 300vh) scale(0.0341);
    }
  }

  @keyframes fall-88 {
    41.526% {
      -webkit-transform: translate(44.4953vw, 124.578vh) scale(0.0341);
      transform: translate(44.4953vw, 124.578vh) scale(0.0341);
    }

    to {
      -webkit-transform: translate(44.59585vw, 300vh) scale(0.0341);
      transform: translate(44.59585vw, 300vh) scale(0.0341);
    }
  }

  .snow:nth-child(89) {
    opacity: 0.9251;
    -webkit-transform: translate(28.4117vw, -10px) scale(0.1448);
    transform: translate(28.4117vw, -10px) scale(0.1448);
    -webkit-animation: fall-89 33s linear -17s infinite;
    animation: fall-89 33s linear -17s infinite;
  }

  @-webkit-keyframes fall-89 {
    37.969% {
      -webkit-transform: translate(19.3181vw, 113.907vh) scale(0.1448);
      transform: translate(19.3181vw, 113.907vh) scale(0.1448);
    }

    to {
      -webkit-transform: translate(23.8649vw, 300vh) scale(0.1448);
      transform: translate(23.8649vw, 300vh) scale(0.1448);
    }
  }

  @keyframes fall-89 {
    37.969% {
      -webkit-transform: translate(19.3181vw, 113.907vh) scale(0.1448);
      transform: translate(19.3181vw, 113.907vh) scale(0.1448);
    }

    to {
      -webkit-transform: translate(23.8649vw, 300vh) scale(0.1448);
      transform: translate(23.8649vw, 300vh) scale(0.1448);
    }
  }

  .snow:nth-child(90) {
    opacity: 0.8781;
    -webkit-transform: translate(57.581vw, -10px) scale(0.2554);
    transform: translate(57.581vw, -10px) scale(0.2554);
    -webkit-animation: fall-90 36s linear -23s infinite;
    animation: fall-90 36s linear -23s infinite;
  }

  @-webkit-keyframes fall-90 {
    56.292% {
      -webkit-transform: translate(63.7239vw, 168.876vh) scale(0.2554);
      transform: translate(63.7239vw, 168.876vh) scale(0.2554);
    }

    to {
      -webkit-transform: translate(60.65245vw, 300vh) scale(0.2554);
      transform: translate(60.65245vw, 300vh) scale(0.2554);
    }
  }

  @keyframes fall-90 {
    56.292% {
      -webkit-transform: translate(63.7239vw, 168.876vh) scale(0.2554);
      transform: translate(63.7239vw, 168.876vh) scale(0.2554);
    }

    to {
      -webkit-transform: translate(60.65245vw, 300vh) scale(0.2554);
      transform: translate(60.65245vw, 300vh) scale(0.2554);
    }
  }

  .snow:nth-child(91) {
    opacity: 0.5884;
    -webkit-transform: translate(81.68vw, -10px) scale(0.3424);
    transform: translate(81.68vw, -10px) scale(0.3424);
    -webkit-animation: fall-91 22s linear -12s infinite;
    animation: fall-91 22s linear -12s infinite;
  }

  @-webkit-keyframes fall-91 {
    30.686% {
      -webkit-transform: translate(87.1536vw, 92.058vh) scale(0.3424);
      transform: translate(87.1536vw, 92.058vh) scale(0.3424);
    }

    to {
      -webkit-transform: translate(84.4168vw, 300vh) scale(0.3424);
      transform: translate(84.4168vw, 300vh) scale(0.3424);
    }
  }

  @keyframes fall-91 {
    30.686% {
      -webkit-transform: translate(87.1536vw, 92.058vh) scale(0.3424);
      transform: translate(87.1536vw, 92.058vh) scale(0.3424);
    }

    to {
      -webkit-transform: translate(84.4168vw, 300vh) scale(0.3424);
      transform: translate(84.4168vw, 300vh) scale(0.3424);
    }
  }

  .snow:nth-child(92) {
    opacity: 0.8627;
    -webkit-transform: translate(34.1276vw, -10px) scale(0.7996);
    transform: translate(34.1276vw, -10px) scale(0.7996);
    -webkit-animation: fall-92 35s linear -9s infinite;
    animation: fall-92 35s linear -9s infinite;
  }

  @-webkit-keyframes fall-92 {
    38.067% {
      -webkit-transform: translate(39.7659vw, 114.201vh) scale(0.7996);
      transform: translate(39.7659vw, 114.201vh) scale(0.7996);
    }

    to {
      -webkit-transform: translate(36.94675vw, 300vh) scale(0.7996);
      transform: translate(36.94675vw, 300vh) scale(0.7996);
    }
  }

  @keyframes fall-92 {
    38.067% {
      -webkit-transform: translate(39.7659vw, 114.201vh) scale(0.7996);
      transform: translate(39.7659vw, 114.201vh) scale(0.7996);
    }

    to {
      -webkit-transform: translate(36.94675vw, 300vh) scale(0.7996);
      transform: translate(36.94675vw, 300vh) scale(0.7996);
    }
  }

  .snow:nth-child(93) {
    opacity: 0.9133;
    -webkit-transform: translate(37.6142vw, -10px) scale(0.4719);
    transform: translate(37.6142vw, -10px) scale(0.4719);
    -webkit-animation: fall-93 29s linear -21s infinite;
    animation: fall-93 29s linear -21s infinite;
  }

  @-webkit-keyframes fall-93 {
    38.56% {
      -webkit-transform: translate(40.1485vw, 115.68vh) scale(0.4719);
      transform: translate(40.1485vw, 115.68vh) scale(0.4719);
    }

    to {
      -webkit-transform: translate(38.88135vw, 300vh) scale(0.4719);
      transform: translate(38.88135vw, 300vh) scale(0.4719);
    }
  }

  @keyframes fall-93 {
    38.56% {
      -webkit-transform: translate(40.1485vw, 115.68vh) scale(0.4719);
      transform: translate(40.1485vw, 115.68vh) scale(0.4719);
    }

    to {
      -webkit-transform: translate(38.88135vw, 300vh) scale(0.4719);
      transform: translate(38.88135vw, 300vh) scale(0.4719);
    }
  }

  .snow:nth-child(94) {
    opacity: 0.2683;
    -webkit-transform: translate(89.3023vw, -10px) scale(0.2329);
    transform: translate(89.3023vw, -10px) scale(0.2329);
    -webkit-animation: fall-94 34s linear -4s infinite;
    animation: fall-94 34s linear -4s infinite;
  }

  @-webkit-keyframes fall-94 {
    48.245% {
      -webkit-transform: translate(84.4117vw, 144.735vh) scale(0.2329);
      transform: translate(84.4117vw, 144.735vh) scale(0.2329);
    }

    to {
      -webkit-transform: translate(86.857vw, 300vh) scale(0.2329);
      transform: translate(86.857vw, 300vh) scale(0.2329);
    }
  }

  @keyframes fall-94 {
    48.245% {
      -webkit-transform: translate(84.4117vw, 144.735vh) scale(0.2329);
      transform: translate(84.4117vw, 144.735vh) scale(0.2329);
    }

    to {
      -webkit-transform: translate(86.857vw, 300vh) scale(0.2329);
      transform: translate(86.857vw, 300vh) scale(0.2329);
    }
  }

  .snow:nth-child(95) {
    opacity: 0.2634;
    -webkit-transform: translate(74.7613vw, -10px) scale(0.1049);
    transform: translate(74.7613vw, -10px) scale(0.1049);
    -webkit-animation: fall-95 23s linear -19s infinite;
    animation: fall-95 23s linear -19s infinite;
  }

  @-webkit-keyframes fall-95 {
    42.577% {
      -webkit-transform: translate(81.2563vw, 127.731vh) scale(0.1049);
      transform: translate(81.2563vw, 127.731vh) scale(0.1049);
    }

    to {
      -webkit-transform: translate(78.0088vw, 300vh) scale(0.1049);
      transform: translate(78.0088vw, 300vh) scale(0.1049);
    }
  }

  @keyframes fall-95 {
    42.577% {
      -webkit-transform: translate(81.2563vw, 127.731vh) scale(0.1049);
      transform: translate(81.2563vw, 127.731vh) scale(0.1049);
    }

    to {
      -webkit-transform: translate(78.0088vw, 300vh) scale(0.1049);
      transform: translate(78.0088vw, 300vh) scale(0.1049);
    }
  }

  .snow:nth-child(96) {
    opacity: 0.3781;
    -webkit-transform: translate(32.7558vw, -10px) scale(0.9588);
    transform: translate(32.7558vw, -10px) scale(0.9588);
    -webkit-animation: fall-96 35s linear -2s infinite;
    animation: fall-96 35s linear -2s infinite;
  }

  @-webkit-keyframes fall-96 {
    56.316% {
      -webkit-transform: translate(30.569vw, 168.948vh) scale(0.9588);
      transform: translate(30.569vw, 168.948vh) scale(0.9588);
    }

    to {
      -webkit-transform: translate(31.6624vw, 300vh) scale(0.9588);
      transform: translate(31.6624vw, 300vh) scale(0.9588);
    }
  }

  @keyframes fall-96 {
    56.316% {
      -webkit-transform: translate(30.569vw, 168.948vh) scale(0.9588);
      transform: translate(30.569vw, 168.948vh) scale(0.9588);
    }

    to {
      -webkit-transform: translate(31.6624vw, 300vh) scale(0.9588);
      transform: translate(31.6624vw, 300vh) scale(0.9588);
    }
  }

  .snow:nth-child(97) {
    opacity: 0.938;
    -webkit-transform: translate(54.6877vw, -10px) scale(0.4864);
    transform: translate(54.6877vw, -10px) scale(0.4864);
    -webkit-animation: fall-97 24s linear -8s infinite;
    animation: fall-97 24s linear -8s infinite;
  }

  @-webkit-keyframes fall-97 {
    45.351% {
      -webkit-transform: translate(53.283vw, 136.053vh) scale(0.4864);
      transform: translate(53.283vw, 136.053vh) scale(0.4864);
    }

    to {
      -webkit-transform: translate(53.98535vw, 300vh) scale(0.4864);
      transform: translate(53.98535vw, 300vh) scale(0.4864);
    }
  }

  @keyframes fall-97 {
    45.351% {
      -webkit-transform: translate(53.283vw, 136.053vh) scale(0.4864);
      transform: translate(53.283vw, 136.053vh) scale(0.4864);
    }

    to {
      -webkit-transform: translate(53.98535vw, 300vh) scale(0.4864);
      transform: translate(53.98535vw, 300vh) scale(0.4864);
    }
  }

  .snow:nth-child(98) {
    opacity: 0.5033;
    -webkit-transform: translate(50.2056vw, -10px) scale(0.502);
    transform: translate(50.2056vw, -10px) scale(0.502);
    -webkit-animation: fall-98 27s linear -14s infinite;
    animation: fall-98 27s linear -14s infinite;
  }

  @-webkit-keyframes fall-98 {
    44.358% {
      -webkit-transform: translate(56.1343vw, 133.074vh) scale(0.502);
      transform: translate(56.1343vw, 133.074vh) scale(0.502);
    }

    to {
      -webkit-transform: translate(53.16995vw, 300vh) scale(0.502);
      transform: translate(53.16995vw, 300vh) scale(0.502);
    }
  }

  @keyframes fall-98 {
    44.358% {
      -webkit-transform: translate(56.1343vw, 133.074vh) scale(0.502);
      transform: translate(56.1343vw, 133.074vh) scale(0.502);
    }

    to {
      -webkit-transform: translate(53.16995vw, 300vh) scale(0.502);
      transform: translate(53.16995vw, 300vh) scale(0.502);
    }
  }

  .snow:nth-child(99) {
    opacity: 0.4791;
    -webkit-transform: translate(78.424vw, -10px) scale(0.8824);
    transform: translate(78.424vw, -10px) scale(0.8824);
    -webkit-animation: fall-99 37s linear -3s infinite;
    animation: fall-99 37s linear -3s infinite;
  }

  @-webkit-keyframes fall-99 {
    60.478% {
      -webkit-transform: translate(85.3482vw, 181.434vh) scale(0.8824);
      transform: translate(85.3482vw, 181.434vh) scale(0.8824);
    }

    to {
      -webkit-transform: translate(81.8861vw, 300vh) scale(0.8824);
      transform: translate(81.8861vw, 300vh) scale(0.8824);
    }
  }

  @keyframes fall-99 {
    60.478% {
      -webkit-transform: translate(85.3482vw, 181.434vh) scale(0.8824);
      transform: translate(85.3482vw, 181.434vh) scale(0.8824);
    }

    to {
      -webkit-transform: translate(81.8861vw, 300vh) scale(0.8824);
      transform: translate(81.8861vw, 300vh) scale(0.8824);
    }
  }

  .snow:nth-child(100) {
    opacity: 0.6685;
    -webkit-transform: translate(24.7711vw, -10px) scale(0.3181);
    transform: translate(24.7711vw, -10px) scale(0.3181);
    -webkit-animation: fall-100 22s linear -7s infinite;
    animation: fall-100 22s linear -7s infinite;
  }

  @-webkit-keyframes fall-100 {
    78.627% {
      -webkit-transform: translate(29.7181vw, 235.881vh) scale(0.3181);
      transform: translate(29.7181vw, 235.881vh) scale(0.3181);
    }

    to {
      -webkit-transform: translate(27.2446vw, 300vh) scale(0.3181);
      transform: translate(27.2446vw, 300vh) scale(0.3181);
    }
  }

  @keyframes fall-100 {
    78.627% {
      -webkit-transform: translate(29.7181vw, 235.881vh) scale(0.3181);
      transform: translate(29.7181vw, 235.881vh) scale(0.3181);
    }

    to {
      -webkit-transform: translate(27.2446vw, 300vh) scale(0.3181);
      transform: translate(27.2446vw, 300vh) scale(0.3181);
    }
  }

  .snow:nth-child(101) {
    opacity: 0.9999;
    -webkit-transform: translate(98.9617vw, -10px) scale(0.3088);
    transform: translate(98.9617vw, -10px) scale(0.3088);
    -webkit-animation: fall-101 22s linear -3s infinite;
    animation: fall-101 22s linear -3s infinite;
  }

  @-webkit-keyframes fall-101 {
    75.481% {
      -webkit-transform: translate(102.971vw, 226.443vh) scale(0.3088);
      transform: translate(102.971vw, 226.443vh) scale(0.3088);
    }

    to {
      -webkit-transform: translate(100.96635vw, 300vh) scale(0.3088);
      transform: translate(100.96635vw, 300vh) scale(0.3088);
    }
  }

  @keyframes fall-101 {
    75.481% {
      -webkit-transform: translate(102.971vw, 226.443vh) scale(0.3088);
      transform: translate(102.971vw, 226.443vh) scale(0.3088);
    }

    to {
      -webkit-transform: translate(100.96635vw, 300vh) scale(0.3088);
      transform: translate(100.96635vw, 300vh) scale(0.3088);
    }
  }

  .snow:nth-child(102) {
    opacity: 0.3219;
    -webkit-transform: translate(32.0538vw, -10px) scale(0.7253);
    transform: translate(32.0538vw, -10px) scale(0.7253);
    -webkit-animation: fall-102 39s linear -18s infinite;
    animation: fall-102 39s linear -18s infinite;
  }

  @-webkit-keyframes fall-102 {
    33.037% {
      -webkit-transform: translate(29.7827vw, 99.111vh) scale(0.7253);
      transform: translate(29.7827vw, 99.111vh) scale(0.7253);
    }

    to {
      -webkit-transform: translate(30.91825vw, 300vh) scale(0.7253);
      transform: translate(30.91825vw, 300vh) scale(0.7253);
    }
  }

  @keyframes fall-102 {
    33.037% {
      -webkit-transform: translate(29.7827vw, 99.111vh) scale(0.7253);
      transform: translate(29.7827vw, 99.111vh) scale(0.7253);
    }

    to {
      -webkit-transform: translate(30.91825vw, 300vh) scale(0.7253);
      transform: translate(30.91825vw, 300vh) scale(0.7253);
    }
  }

  .snow:nth-child(103) {
    opacity: 0.2196;
    -webkit-transform: translate(8.5024vw, -10px) scale(0.9592);
    transform: translate(8.5024vw, -10px) scale(0.9592);
    -webkit-animation: fall-103 22s linear -28s infinite;
    animation: fall-103 22s linear -28s infinite;
  }

  @-webkit-keyframes fall-103 {
    48.673% {
      -webkit-transform: translate(7.434vw, 146.019vh) scale(0.9592);
      transform: translate(7.434vw, 146.019vh) scale(0.9592);
    }

    to {
      -webkit-transform: translate(7.9682vw, 300vh) scale(0.9592);
      transform: translate(7.9682vw, 300vh) scale(0.9592);
    }
  }

  @keyframes fall-103 {
    48.673% {
      -webkit-transform: translate(7.434vw, 146.019vh) scale(0.9592);
      transform: translate(7.434vw, 146.019vh) scale(0.9592);
    }

    to {
      -webkit-transform: translate(7.9682vw, 300vh) scale(0.9592);
      transform: translate(7.9682vw, 300vh) scale(0.9592);
    }
  }

  .snow:nth-child(104) {
    opacity: 0.0227;
    -webkit-transform: translate(29.6668vw, -10px) scale(0.5049);
    transform: translate(29.6668vw, -10px) scale(0.5049);
    -webkit-animation: fall-104 38s linear -13s infinite;
    animation: fall-104 38s linear -13s infinite;
  }

  @-webkit-keyframes fall-104 {
    67.363% {
      -webkit-transform: translate(36.4474vw, 202.089vh) scale(0.5049);
      transform: translate(36.4474vw, 202.089vh) scale(0.5049);
    }

    to {
      -webkit-transform: translate(33.0571vw, 300vh) scale(0.5049);
      transform: translate(33.0571vw, 300vh) scale(0.5049);
    }
  }

  @keyframes fall-104 {
    67.363% {
      -webkit-transform: translate(36.4474vw, 202.089vh) scale(0.5049);
      transform: translate(36.4474vw, 202.089vh) scale(0.5049);
    }

    to {
      -webkit-transform: translate(33.0571vw, 300vh) scale(0.5049);
      transform: translate(33.0571vw, 300vh) scale(0.5049);
    }
  }

  .snow:nth-child(105) {
    opacity: 0.0178;
    -webkit-transform: translate(99.4199vw, -10px) scale(0.515);
    transform: translate(99.4199vw, -10px) scale(0.515);
    -webkit-animation: fall-105 27s linear -16s infinite;
    animation: fall-105 27s linear -16s infinite;
  }

  @-webkit-keyframes fall-105 {
    39.087% {
      -webkit-transform: translate(97.6436vw, 117.261vh) scale(0.515);
      transform: translate(97.6436vw, 117.261vh) scale(0.515);
    }

    to {
      -webkit-transform: translate(98.53175vw, 300vh) scale(0.515);
      transform: translate(98.53175vw, 300vh) scale(0.515);
    }
  }

  @keyframes fall-105 {
    39.087% {
      -webkit-transform: translate(97.6436vw, 117.261vh) scale(0.515);
      transform: translate(97.6436vw, 117.261vh) scale(0.515);
    }

    to {
      -webkit-transform: translate(98.53175vw, 300vh) scale(0.515);
      transform: translate(98.53175vw, 300vh) scale(0.515);
    }
  }

  .snow:nth-child(106) {
    opacity: 0.8159;
    -webkit-transform: translate(82.4544vw, -10px) scale(0.2071);
    transform: translate(82.4544vw, -10px) scale(0.2071);
    -webkit-animation: fall-106 37s linear -23s infinite;
    animation: fall-106 37s linear -23s infinite;
  }

  @-webkit-keyframes fall-106 {
    58.62% {
      -webkit-transform: translate(80.5738vw, 175.86vh) scale(0.2071);
      transform: translate(80.5738vw, 175.86vh) scale(0.2071);
    }

    to {
      -webkit-transform: translate(81.5141vw, 300vh) scale(0.2071);
      transform: translate(81.5141vw, 300vh) scale(0.2071);
    }
  }

  @keyframes fall-106 {
    58.62% {
      -webkit-transform: translate(80.5738vw, 175.86vh) scale(0.2071);
      transform: translate(80.5738vw, 175.86vh) scale(0.2071);
    }

    to {
      -webkit-transform: translate(81.5141vw, 300vh) scale(0.2071);
      transform: translate(81.5141vw, 300vh) scale(0.2071);
    }
  }

  .snow:nth-child(107) {
    opacity: 0.8042;
    -webkit-transform: translate(30.8186vw, -10px) scale(0.124);
    transform: translate(30.8186vw, -10px) scale(0.124);
    -webkit-animation: fall-107 30s linear -30s infinite;
    animation: fall-107 30s linear -30s infinite;
  }

  @-webkit-keyframes fall-107 {
    36.905% {
      -webkit-transform: translate(30.3962vw, 110.715vh) scale(0.124);
      transform: translate(30.3962vw, 110.715vh) scale(0.124);
    }

    to {
      -webkit-transform: translate(30.6074vw, 300vh) scale(0.124);
      transform: translate(30.6074vw, 300vh) scale(0.124);
    }
  }

  @keyframes fall-107 {
    36.905% {
      -webkit-transform: translate(30.3962vw, 110.715vh) scale(0.124);
      transform: translate(30.3962vw, 110.715vh) scale(0.124);
    }

    to {
      -webkit-transform: translate(30.6074vw, 300vh) scale(0.124);
      transform: translate(30.6074vw, 300vh) scale(0.124);
    }
  }

  .snow:nth-child(108) {
    opacity: 0.1451;
    -webkit-transform: translate(77.1909vw, -10px) scale(0.6624);
    transform: translate(77.1909vw, -10px) scale(0.6624);
    -webkit-animation: fall-108 26s linear -20s infinite;
    animation: fall-108 26s linear -20s infinite;
  }

  @-webkit-keyframes fall-108 {
    49.853% {
      -webkit-transform: translate(75.9719vw, 149.559vh) scale(0.6624);
      transform: translate(75.9719vw, 149.559vh) scale(0.6624);
    }

    to {
      -webkit-transform: translate(76.5814vw, 300vh) scale(0.6624);
      transform: translate(76.5814vw, 300vh) scale(0.6624);
    }
  }

  @keyframes fall-108 {
    49.853% {
      -webkit-transform: translate(75.9719vw, 149.559vh) scale(0.6624);
      transform: translate(75.9719vw, 149.559vh) scale(0.6624);
    }

    to {
      -webkit-transform: translate(76.5814vw, 300vh) scale(0.6624);
      transform: translate(76.5814vw, 300vh) scale(0.6624);
    }
  }

  .snow:nth-child(109) {
    opacity: 0.6389;
    -webkit-transform: translate(99.142vw, -10px) scale(0.4784);
    transform: translate(99.142vw, -10px) scale(0.4784);
    -webkit-animation: fall-109 29s linear -4s infinite;
    animation: fall-109 29s linear -4s infinite;
  }

  @-webkit-keyframes fall-109 {
    59.384% {
      -webkit-transform: translate(99.3693vw, 178.152vh) scale(0.4784);
      transform: translate(99.3693vw, 178.152vh) scale(0.4784);
    }

    to {
      -webkit-transform: translate(99.25565vw, 300vh) scale(0.4784);
      transform: translate(99.25565vw, 300vh) scale(0.4784);
    }
  }

  @keyframes fall-109 {
    59.384% {
      -webkit-transform: translate(99.3693vw, 178.152vh) scale(0.4784);
      transform: translate(99.3693vw, 178.152vh) scale(0.4784);
    }

    to {
      -webkit-transform: translate(99.25565vw, 300vh) scale(0.4784);
      transform: translate(99.25565vw, 300vh) scale(0.4784);
    }
  }

  .snow:nth-child(110) {
    opacity: 0.165;
    -webkit-transform: translate(95.2464vw, -10px) scale(0.3419);
    transform: translate(95.2464vw, -10px) scale(0.3419);
    -webkit-animation: fall-110 36s linear -13s infinite;
    animation: fall-110 36s linear -13s infinite;
  }

  @-webkit-keyframes fall-110 {
    56.471% {
      -webkit-transform: translate(103.8812vw, 169.413vh) scale(0.3419);
      transform: translate(103.8812vw, 169.413vh) scale(0.3419);
    }

    to {
      -webkit-transform: translate(99.5638vw, 300vh) scale(0.3419);
      transform: translate(99.5638vw, 300vh) scale(0.3419);
    }
  }

  @keyframes fall-110 {
    56.471% {
      -webkit-transform: translate(103.8812vw, 169.413vh) scale(0.3419);
      transform: translate(103.8812vw, 169.413vh) scale(0.3419);
    }

    to {
      -webkit-transform: translate(99.5638vw, 300vh) scale(0.3419);
      transform: translate(99.5638vw, 300vh) scale(0.3419);
    }
  }

  .snow:nth-child(111) {
    opacity: 0.4233;
    -webkit-transform: translate(36.3019vw, -10px) scale(0.749);
    transform: translate(36.3019vw, -10px) scale(0.749);
    -webkit-animation: fall-111 27s linear -11s infinite;
    animation: fall-111 27s linear -11s infinite;
  }

  @-webkit-keyframes fall-111 {
    76.127% {
      -webkit-transform: translate(39.8784vw, 228.381vh) scale(0.749);
      transform: translate(39.8784vw, 228.381vh) scale(0.749);
    }

    to {
      -webkit-transform: translate(38.09015vw, 300vh) scale(0.749);
      transform: translate(38.09015vw, 300vh) scale(0.749);
    }
  }

  @keyframes fall-111 {
    76.127% {
      -webkit-transform: translate(39.8784vw, 228.381vh) scale(0.749);
      transform: translate(39.8784vw, 228.381vh) scale(0.749);
    }

    to {
      -webkit-transform: translate(38.09015vw, 300vh) scale(0.749);
      transform: translate(38.09015vw, 300vh) scale(0.749);
    }
  }

  .snow:nth-child(112) {
    opacity: 0.7141;
    -webkit-transform: translate(36.3783vw, -10px) scale(0.4468);
    transform: translate(36.3783vw, -10px) scale(0.4468);
    -webkit-animation: fall-112 39s linear -12s infinite;
    animation: fall-112 39s linear -12s infinite;
  }

  @-webkit-keyframes fall-112 {
    48.222% {
      -webkit-transform: translate(29.6336vw, 144.666vh) scale(0.4468);
      transform: translate(29.6336vw, 144.666vh) scale(0.4468);
    }

    to {
      -webkit-transform: translate(33.00595vw, 300vh) scale(0.4468);
      transform: translate(33.00595vw, 300vh) scale(0.4468);
    }
  }

  @keyframes fall-112 {
    48.222% {
      -webkit-transform: translate(29.6336vw, 144.666vh) scale(0.4468);
      transform: translate(29.6336vw, 144.666vh) scale(0.4468);
    }

    to {
      -webkit-transform: translate(33.00595vw, 300vh) scale(0.4468);
      transform: translate(33.00595vw, 300vh) scale(0.4468);
    }
  }

  .snow:nth-child(113) {
    opacity: 0.7535;
    -webkit-transform: translate(71.8057vw, -10px) scale(0.8208);
    transform: translate(71.8057vw, -10px) scale(0.8208);
    -webkit-animation: fall-113 29s linear -5s infinite;
    animation: fall-113 29s linear -5s infinite;
  }

  @-webkit-keyframes fall-113 {
    35.194% {
      -webkit-transform: translate(73.7095vw, 105.582vh) scale(0.8208);
      transform: translate(73.7095vw, 105.582vh) scale(0.8208);
    }

    to {
      -webkit-transform: translate(72.7576vw, 300vh) scale(0.8208);
      transform: translate(72.7576vw, 300vh) scale(0.8208);
    }
  }

  @keyframes fall-113 {
    35.194% {
      -webkit-transform: translate(73.7095vw, 105.582vh) scale(0.8208);
      transform: translate(73.7095vw, 105.582vh) scale(0.8208);
    }

    to {
      -webkit-transform: translate(72.7576vw, 300vh) scale(0.8208);
      transform: translate(72.7576vw, 300vh) scale(0.8208);
    }
  }

  .snow:nth-child(114) {
    opacity: 0.2893;
    -webkit-transform: translate(57.8035vw, -10px) scale(0.4304);
    transform: translate(57.8035vw, -10px) scale(0.4304);
    -webkit-animation: fall-114 38s linear -28s infinite;
    animation: fall-114 38s linear -28s infinite;
  }

  @-webkit-keyframes fall-114 {
    50.863% {
      -webkit-transform: translate(65.3549vw, 152.589vh) scale(0.4304);
      transform: translate(65.3549vw, 152.589vh) scale(0.4304);
    }

    to {
      -webkit-transform: translate(61.5792vw, 300vh) scale(0.4304);
      transform: translate(61.5792vw, 300vh) scale(0.4304);
    }
  }

  @keyframes fall-114 {
    50.863% {
      -webkit-transform: translate(65.3549vw, 152.589vh) scale(0.4304);
      transform: translate(65.3549vw, 152.589vh) scale(0.4304);
    }

    to {
      -webkit-transform: translate(61.5792vw, 300vh) scale(0.4304);
      transform: translate(61.5792vw, 300vh) scale(0.4304);
    }
  }

  .snow:nth-child(115) {
    opacity: 0.0007;
    -webkit-transform: translate(4.4658vw, -10px) scale(0.9475);
    transform: translate(4.4658vw, -10px) scale(0.9475);
    -webkit-animation: fall-115 22s linear -7s infinite;
    animation: fall-115 22s linear -7s infinite;
  }

  @-webkit-keyframes fall-115 {
    77.866% {
      -webkit-transform: translate(5.2126vw, 233.598vh) scale(0.9475);
      transform: translate(5.2126vw, 233.598vh) scale(0.9475);
    }

    to {
      -webkit-transform: translate(4.8392vw, 300vh) scale(0.9475);
      transform: translate(4.8392vw, 300vh) scale(0.9475);
    }
  }

  @keyframes fall-115 {
    77.866% {
      -webkit-transform: translate(5.2126vw, 233.598vh) scale(0.9475);
      transform: translate(5.2126vw, 233.598vh) scale(0.9475);
    }

    to {
      -webkit-transform: translate(4.8392vw, 300vh) scale(0.9475);
      transform: translate(4.8392vw, 300vh) scale(0.9475);
    }
  }

  .snow:nth-child(116) {
    opacity: 0.2013;
    -webkit-transform: translate(61.9097vw, -10px) scale(0.877);
    transform: translate(61.9097vw, -10px) scale(0.877);
    -webkit-animation: fall-116 25s linear -12s infinite;
    animation: fall-116 25s linear -12s infinite;
  }

  @-webkit-keyframes fall-116 {
    59.683% {
      -webkit-transform: translate(62.6218vw, 179.049vh) scale(0.877);
      transform: translate(62.6218vw, 179.049vh) scale(0.877);
    }

    to {
      -webkit-transform: translate(62.26575vw, 300vh) scale(0.877);
      transform: translate(62.26575vw, 300vh) scale(0.877);
    }
  }

  @keyframes fall-116 {
    59.683% {
      -webkit-transform: translate(62.6218vw, 179.049vh) scale(0.877);
      transform: translate(62.6218vw, 179.049vh) scale(0.877);
    }

    to {
      -webkit-transform: translate(62.26575vw, 300vh) scale(0.877);
      transform: translate(62.26575vw, 300vh) scale(0.877);
    }
  }

  .snow:nth-child(117) {
    opacity: 0.5574;
    -webkit-transform: translate(43.0032vw, -10px) scale(0.5776);
    transform: translate(43.0032vw, -10px) scale(0.5776);
    -webkit-animation: fall-117 39s linear -22s infinite;
    animation: fall-117 39s linear -22s infinite;
  }

  @-webkit-keyframes fall-117 {
    75.461% {
      -webkit-transform: translate(41.5888vw, 226.383vh) scale(0.5776);
      transform: translate(41.5888vw, 226.383vh) scale(0.5776);
    }

    to {
      -webkit-transform: translate(42.296vw, 300vh) scale(0.5776);
      transform: translate(42.296vw, 300vh) scale(0.5776);
    }
  }

  @keyframes fall-117 {
    75.461% {
      -webkit-transform: translate(41.5888vw, 226.383vh) scale(0.5776);
      transform: translate(41.5888vw, 226.383vh) scale(0.5776);
    }

    to {
      -webkit-transform: translate(42.296vw, 300vh) scale(0.5776);
      transform: translate(42.296vw, 300vh) scale(0.5776);
    }
  }

  .snow:nth-child(118) {
    opacity: 0.7965;
    -webkit-transform: translate(43.5768vw, -10px) scale(0.7285);
    transform: translate(43.5768vw, -10px) scale(0.7285);
    -webkit-animation: fall-118 25s linear -17s infinite;
    animation: fall-118 25s linear -17s infinite;
  }

  @-webkit-keyframes fall-118 {
    61.726% {
      -webkit-transform: translate(47.0989vw, 185.178vh) scale(0.7285);
      transform: translate(47.0989vw, 185.178vh) scale(0.7285);
    }

    to {
      -webkit-transform: translate(45.33785vw, 300vh) scale(0.7285);
      transform: translate(45.33785vw, 300vh) scale(0.7285);
    }
  }

  @keyframes fall-118 {
    61.726% {
      -webkit-transform: translate(47.0989vw, 185.178vh) scale(0.7285);
      transform: translate(47.0989vw, 185.178vh) scale(0.7285);
    }

    to {
      -webkit-transform: translate(45.33785vw, 300vh) scale(0.7285);
      transform: translate(45.33785vw, 300vh) scale(0.7285);
    }
  }

  .snow:nth-child(119) {
    opacity: 0.4304;
    -webkit-transform: translate(15.4866vw, -10px) scale(0.949);
    transform: translate(15.4866vw, -10px) scale(0.949);
    -webkit-animation: fall-119 39s linear -4s infinite;
    animation: fall-119 39s linear -4s infinite;
  }

  @-webkit-keyframes fall-119 {
    47.229% {
      -webkit-transform: translate(7.8051vw, 141.687vh) scale(0.949);
      transform: translate(7.8051vw, 141.687vh) scale(0.949);
    }

    to {
      -webkit-transform: translate(11.64585vw, 300vh) scale(0.949);
      transform: translate(11.64585vw, 300vh) scale(0.949);
    }
  }

  @keyframes fall-119 {
    47.229% {
      -webkit-transform: translate(7.8051vw, 141.687vh) scale(0.949);
      transform: translate(7.8051vw, 141.687vh) scale(0.949);
    }

    to {
      -webkit-transform: translate(11.64585vw, 300vh) scale(0.949);
      transform: translate(11.64585vw, 300vh) scale(0.949);
    }
  }

  .snow:nth-child(120) {
    opacity: 0.1173;
    -webkit-transform: translate(79.2901vw, -10px) scale(0.5429);
    transform: translate(79.2901vw, -10px) scale(0.5429);
    -webkit-animation: fall-120 31s linear -20s infinite;
    animation: fall-120 31s linear -20s infinite;
  }

  @-webkit-keyframes fall-120 {
    58.866% {
      -webkit-transform: translate(69.5771vw, 176.598vh) scale(0.5429);
      transform: translate(69.5771vw, 176.598vh) scale(0.5429);
    }

    to {
      -webkit-transform: translate(74.4336vw, 300vh) scale(0.5429);
      transform: translate(74.4336vw, 300vh) scale(0.5429);
    }
  }

  @keyframes fall-120 {
    58.866% {
      -webkit-transform: translate(69.5771vw, 176.598vh) scale(0.5429);
      transform: translate(69.5771vw, 176.598vh) scale(0.5429);
    }

    to {
      -webkit-transform: translate(74.4336vw, 300vh) scale(0.5429);
      transform: translate(74.4336vw, 300vh) scale(0.5429);
    }
  }

  .snow:nth-child(121) {
    opacity: 0.84;
    -webkit-transform: translate(81.1563vw, -10px) scale(0.1152);
    transform: translate(81.1563vw, -10px) scale(0.1152);
    -webkit-animation: fall-121 30s linear -11s infinite;
    animation: fall-121 30s linear -11s infinite;
  }

  @-webkit-keyframes fall-121 {
    43.977% {
      -webkit-transform: translate(80.5896vw, 131.931vh) scale(0.1152);
      transform: translate(80.5896vw, 131.931vh) scale(0.1152);
    }

    to {
      -webkit-transform: translate(80.87295vw, 300vh) scale(0.1152);
      transform: translate(80.87295vw, 300vh) scale(0.1152);
    }
  }

  @keyframes fall-121 {
    43.977% {
      -webkit-transform: translate(80.5896vw, 131.931vh) scale(0.1152);
      transform: translate(80.5896vw, 131.931vh) scale(0.1152);
    }

    to {
      -webkit-transform: translate(80.87295vw, 300vh) scale(0.1152);
      transform: translate(80.87295vw, 300vh) scale(0.1152);
    }
  }

  .snow:nth-child(122) {
    opacity: 0.4843;
    -webkit-transform: translate(26.0815vw, -10px) scale(0.9997);
    transform: translate(26.0815vw, -10px) scale(0.9997);
    -webkit-animation: fall-122 37s linear -18s infinite;
    animation: fall-122 37s linear -18s infinite;
  }

  @-webkit-keyframes fall-122 {
    30.08% {
      -webkit-transform: translate(19.9841vw, 90.24vh) scale(0.9997);
      transform: translate(19.9841vw, 90.24vh) scale(0.9997);
    }

    to {
      -webkit-transform: translate(23.0328vw, 300vh) scale(0.9997);
      transform: translate(23.0328vw, 300vh) scale(0.9997);
    }
  }

  @keyframes fall-122 {
    30.08% {
      -webkit-transform: translate(19.9841vw, 90.24vh) scale(0.9997);
      transform: translate(19.9841vw, 90.24vh) scale(0.9997);
    }

    to {
      -webkit-transform: translate(23.0328vw, 300vh) scale(0.9997);
      transform: translate(23.0328vw, 300vh) scale(0.9997);
    }
  }

  .snow:nth-child(123) {
    opacity: 0.5095;
    -webkit-transform: translate(8.3854vw, -10px) scale(0.3145);
    transform: translate(8.3854vw, -10px) scale(0.3145);
    -webkit-animation: fall-123 24s linear -21s infinite;
    animation: fall-123 24s linear -21s infinite;
  }

  @-webkit-keyframes fall-123 {
    36.097% {
      -webkit-transform: translate(3.5647vw, 108.291vh) scale(0.3145);
      transform: translate(3.5647vw, 108.291vh) scale(0.3145);
    }

    to {
      -webkit-transform: translate(5.97505vw, 300vh) scale(0.3145);
      transform: translate(5.97505vw, 300vh) scale(0.3145);
    }
  }

  @keyframes fall-123 {
    36.097% {
      -webkit-transform: translate(3.5647vw, 108.291vh) scale(0.3145);
      transform: translate(3.5647vw, 108.291vh) scale(0.3145);
    }

    to {
      -webkit-transform: translate(5.97505vw, 300vh) scale(0.3145);
      transform: translate(5.97505vw, 300vh) scale(0.3145);
    }
  }

  .snow:nth-child(124) {
    opacity: 0.3544;
    -webkit-transform: translate(8.5908vw, -10px) scale(0.6749);
    transform: translate(8.5908vw, -10px) scale(0.6749);
    -webkit-animation: fall-124 28s linear -22s infinite;
    animation: fall-124 28s linear -22s infinite;
  }

  @-webkit-keyframes fall-124 {
    51.276% {
      -webkit-transform: translate(0.8614vw, 153.828vh) scale(0.6749);
      transform: translate(0.8614vw, 153.828vh) scale(0.6749);
    }

    to {
      -webkit-transform: translate(4.7261vw, 300vh) scale(0.6749);
      transform: translate(4.7261vw, 300vh) scale(0.6749);
    }
  }

  @keyframes fall-124 {
    51.276% {
      -webkit-transform: translate(0.8614vw, 153.828vh) scale(0.6749);
      transform: translate(0.8614vw, 153.828vh) scale(0.6749);
    }

    to {
      -webkit-transform: translate(4.7261vw, 300vh) scale(0.6749);
      transform: translate(4.7261vw, 300vh) scale(0.6749);
    }
  }

  .snow:nth-child(125) {
    opacity: 0.6505;
    -webkit-transform: translate(62.635vw, -10px) scale(0.0761);
    transform: translate(62.635vw, -10px) scale(0.0761);
    -webkit-animation: fall-125 38s linear -2s infinite;
    animation: fall-125 38s linear -2s infinite;
  }

  @-webkit-keyframes fall-125 {
    38.174% {
      -webkit-transform: translate(59.9039vw, 114.522vh) scale(0.0761);
      transform: translate(59.9039vw, 114.522vh) scale(0.0761);
    }

    to {
      -webkit-transform: translate(61.26945vw, 300vh) scale(0.0761);
      transform: translate(61.26945vw, 300vh) scale(0.0761);
    }
  }

  @keyframes fall-125 {
    38.174% {
      -webkit-transform: translate(59.9039vw, 114.522vh) scale(0.0761);
      transform: translate(59.9039vw, 114.522vh) scale(0.0761);
    }

    to {
      -webkit-transform: translate(61.26945vw, 300vh) scale(0.0761);
      transform: translate(61.26945vw, 300vh) scale(0.0761);
    }
  }

  .snow:nth-child(126) {
    opacity: 0.4204;
    -webkit-transform: translate(90.8037vw, -10px) scale(0.2384);
    transform: translate(90.8037vw, -10px) scale(0.2384);
    -webkit-animation: fall-126 32s linear -28s infinite;
    animation: fall-126 32s linear -28s infinite;
  }

  @-webkit-keyframes fall-126 {
    65.88% {
      -webkit-transform: translate(87.9146vw, 197.64vh) scale(0.2384);
      transform: translate(87.9146vw, 197.64vh) scale(0.2384);
    }

    to {
      -webkit-transform: translate(89.35915vw, 300vh) scale(0.2384);
      transform: translate(89.35915vw, 300vh) scale(0.2384);
    }
  }

  @keyframes fall-126 {
    65.88% {
      -webkit-transform: translate(87.9146vw, 197.64vh) scale(0.2384);
      transform: translate(87.9146vw, 197.64vh) scale(0.2384);
    }

    to {
      -webkit-transform: translate(89.35915vw, 300vh) scale(0.2384);
      transform: translate(89.35915vw, 300vh) scale(0.2384);
    }
  }

  .snow:nth-child(127) {
    opacity: 0.0252;
    -webkit-transform: translate(57.6025vw, -10px) scale(0.846);
    transform: translate(57.6025vw, -10px) scale(0.846);
    -webkit-animation: fall-127 38s linear -26s infinite;
    animation: fall-127 38s linear -26s infinite;
  }

  @-webkit-keyframes fall-127 {
    33.729% {
      -webkit-transform: translate(49.6923vw, 101.187vh) scale(0.846);
      transform: translate(49.6923vw, 101.187vh) scale(0.846);
    }

    to {
      -webkit-transform: translate(53.6474vw, 300vh) scale(0.846);
      transform: translate(53.6474vw, 300vh) scale(0.846);
    }
  }

  @keyframes fall-127 {
    33.729% {
      -webkit-transform: translate(49.6923vw, 101.187vh) scale(0.846);
      transform: translate(49.6923vw, 101.187vh) scale(0.846);
    }

    to {
      -webkit-transform: translate(53.6474vw, 300vh) scale(0.846);
      transform: translate(53.6474vw, 300vh) scale(0.846);
    }
  }

  .snow:nth-child(128) {
    opacity: 0.3085;
    -webkit-transform: translate(79.193vw, -10px) scale(0.544);
    transform: translate(79.193vw, -10px) scale(0.544);
    -webkit-animation: fall-128 20s linear -1s infinite;
    animation: fall-128 20s linear -1s infinite;
  }

  @-webkit-keyframes fall-128 {
    33.682% {
      -webkit-transform: translate(77.4397vw, 101.046vh) scale(0.544);
      transform: translate(77.4397vw, 101.046vh) scale(0.544);
    }

    to {
      -webkit-transform: translate(78.31635vw, 300vh) scale(0.544);
      transform: translate(78.31635vw, 300vh) scale(0.544);
    }
  }

  @keyframes fall-128 {
    33.682% {
      -webkit-transform: translate(77.4397vw, 101.046vh) scale(0.544);
      transform: translate(77.4397vw, 101.046vh) scale(0.544);
    }

    to {
      -webkit-transform: translate(78.31635vw, 300vh) scale(0.544);
      transform: translate(78.31635vw, 300vh) scale(0.544);
    }
  }

  .snow:nth-child(129) {
    opacity: 0.3923;
    -webkit-transform: translate(4.3371vw, -10px) scale(0.6792);
    transform: translate(4.3371vw, -10px) scale(0.6792);
    -webkit-animation: fall-129 30s linear -22s infinite;
    animation: fall-129 30s linear -22s infinite;
  }

  @-webkit-keyframes fall-129 {
    66.905% {
      -webkit-transform: translate(12.9432vw, 200.715vh) scale(0.6792);
      transform: translate(12.9432vw, 200.715vh) scale(0.6792);
    }

    to {
      -webkit-transform: translate(8.64015vw, 300vh) scale(0.6792);
      transform: translate(8.64015vw, 300vh) scale(0.6792);
    }
  }

  @keyframes fall-129 {
    66.905% {
      -webkit-transform: translate(12.9432vw, 200.715vh) scale(0.6792);
      transform: translate(12.9432vw, 200.715vh) scale(0.6792);
    }

    to {
      -webkit-transform: translate(8.64015vw, 300vh) scale(0.6792);
      transform: translate(8.64015vw, 300vh) scale(0.6792);
    }
  }

  .snow:nth-child(130) {
    opacity: 0.0527;
    -webkit-transform: translate(60.8497vw, -10px) scale(0.9939);
    transform: translate(60.8497vw, -10px) scale(0.9939);
    -webkit-animation: fall-130 38s linear -7s infinite;
    animation: fall-130 38s linear -7s infinite;
  }

  @-webkit-keyframes fall-130 {
    66.151% {
      -webkit-transform: translate(51.5363vw, 198.453vh) scale(0.9939);
      transform: translate(51.5363vw, 198.453vh) scale(0.9939);
    }

    to {
      -webkit-transform: translate(56.193vw, 300vh) scale(0.9939);
      transform: translate(56.193vw, 300vh) scale(0.9939);
    }
  }

  @keyframes fall-130 {
    66.151% {
      -webkit-transform: translate(51.5363vw, 198.453vh) scale(0.9939);
      transform: translate(51.5363vw, 198.453vh) scale(0.9939);
    }

    to {
      -webkit-transform: translate(56.193vw, 300vh) scale(0.9939);
      transform: translate(56.193vw, 300vh) scale(0.9939);
    }
  }

  .snow:nth-child(131) {
    opacity: 0.398;
    -webkit-transform: translate(64.9842vw, -10px) scale(0.2528);
    transform: translate(64.9842vw, -10px) scale(0.2528);
    -webkit-animation: fall-131 35s linear -3s infinite;
    animation: fall-131 35s linear -3s infinite;
  }

  @-webkit-keyframes fall-131 {
    75.608% {
      -webkit-transform: translate(63.4512vw, 226.824vh) scale(0.2528);
      transform: translate(63.4512vw, 226.824vh) scale(0.2528);
    }

    to {
      -webkit-transform: translate(64.2177vw, 300vh) scale(0.2528);
      transform: translate(64.2177vw, 300vh) scale(0.2528);
    }
  }

  @keyframes fall-131 {
    75.608% {
      -webkit-transform: translate(63.4512vw, 226.824vh) scale(0.2528);
      transform: translate(63.4512vw, 226.824vh) scale(0.2528);
    }

    to {
      -webkit-transform: translate(64.2177vw, 300vh) scale(0.2528);
      transform: translate(64.2177vw, 300vh) scale(0.2528);
    }
  }

  .snow:nth-child(132) {
    opacity: 0.0644;
    -webkit-transform: translate(96.4882vw, -10px) scale(0.1944);
    transform: translate(96.4882vw, -10px) scale(0.1944);
    -webkit-animation: fall-132 39s linear -2s infinite;
    animation: fall-132 39s linear -2s infinite;
  }

  @-webkit-keyframes fall-132 {
    67.155% {
      -webkit-transform: translate(94.2529vw, 201.465vh) scale(0.1944);
      transform: translate(94.2529vw, 201.465vh) scale(0.1944);
    }

    to {
      -webkit-transform: translate(95.37055vw, 300vh) scale(0.1944);
      transform: translate(95.37055vw, 300vh) scale(0.1944);
    }
  }

  @keyframes fall-132 {
    67.155% {
      -webkit-transform: translate(94.2529vw, 201.465vh) scale(0.1944);
      transform: translate(94.2529vw, 201.465vh) scale(0.1944);
    }

    to {
      -webkit-transform: translate(95.37055vw, 300vh) scale(0.1944);
      transform: translate(95.37055vw, 300vh) scale(0.1944);
    }
  }

  .snow:nth-child(133) {
    opacity: 0.4875;
    -webkit-transform: translate(9.2392vw, -10px) scale(0.2223);
    transform: translate(9.2392vw, -10px) scale(0.2223);
    -webkit-animation: fall-133 40s linear -15s infinite;
    animation: fall-133 40s linear -15s infinite;
  }

  @-webkit-keyframes fall-133 {
    68.957% {
      -webkit-transform: translate(15.7786vw, 206.871vh) scale(0.2223);
      transform: translate(15.7786vw, 206.871vh) scale(0.2223);
    }

    to {
      -webkit-transform: translate(12.5089vw, 300vh) scale(0.2223);
      transform: translate(12.5089vw, 300vh) scale(0.2223);
    }
  }

  @keyframes fall-133 {
    68.957% {
      -webkit-transform: translate(15.7786vw, 206.871vh) scale(0.2223);
      transform: translate(15.7786vw, 206.871vh) scale(0.2223);
    }

    to {
      -webkit-transform: translate(12.5089vw, 300vh) scale(0.2223);
      transform: translate(12.5089vw, 300vh) scale(0.2223);
    }
  }

  .snow:nth-child(134) {
    opacity: 0.497;
    -webkit-transform: translate(26.0026vw, -10px) scale(0.1285);
    transform: translate(26.0026vw, -10px) scale(0.1285);
    -webkit-animation: fall-134 25s linear -12s infinite;
    animation: fall-134 25s linear -12s infinite;
  }

  @-webkit-keyframes fall-134 {
    54.898% {
      -webkit-transform: translate(33.7253vw, 164.694vh) scale(0.1285);
      transform: translate(33.7253vw, 164.694vh) scale(0.1285);
    }

    to {
      -webkit-transform: translate(29.86395vw, 300vh) scale(0.1285);
      transform: translate(29.86395vw, 300vh) scale(0.1285);
    }
  }

  @keyframes fall-134 {
    54.898% {
      -webkit-transform: translate(33.7253vw, 164.694vh) scale(0.1285);
      transform: translate(33.7253vw, 164.694vh) scale(0.1285);
    }

    to {
      -webkit-transform: translate(29.86395vw, 300vh) scale(0.1285);
      transform: translate(29.86395vw, 300vh) scale(0.1285);
    }
  }

  .snow:nth-child(135) {
    opacity: 0.5472;
    -webkit-transform: translate(84.8881vw, -10px) scale(0.3155);
    transform: translate(84.8881vw, -10px) scale(0.3155);
    -webkit-animation: fall-135 27s linear -15s infinite;
    animation: fall-135 27s linear -15s infinite;
  }

  @-webkit-keyframes fall-135 {
    77.038% {
      -webkit-transform: translate(84.4333vw, 231.114vh) scale(0.3155);
      transform: translate(84.4333vw, 231.114vh) scale(0.3155);
    }

    to {
      -webkit-transform: translate(84.6607vw, 300vh) scale(0.3155);
      transform: translate(84.6607vw, 300vh) scale(0.3155);
    }
  }

  @keyframes fall-135 {
    77.038% {
      -webkit-transform: translate(84.4333vw, 231.114vh) scale(0.3155);
      transform: translate(84.4333vw, 231.114vh) scale(0.3155);
    }

    to {
      -webkit-transform: translate(84.6607vw, 300vh) scale(0.3155);
      transform: translate(84.6607vw, 300vh) scale(0.3155);
    }
  }

  .snow:nth-child(136) {
    opacity: 0.0148;
    -webkit-transform: translate(48.4374vw, -10px) scale(0.7208);
    transform: translate(48.4374vw, -10px) scale(0.7208);
    -webkit-animation: fall-136 26s linear -5s infinite;
    animation: fall-136 26s linear -5s infinite;
  }

  @-webkit-keyframes fall-136 {
    66.095% {
      -webkit-transform: translate(52.37vw, 198.285vh) scale(0.7208);
      transform: translate(52.37vw, 198.285vh) scale(0.7208);
    }

    to {
      -webkit-transform: translate(50.4037vw, 300vh) scale(0.7208);
      transform: translate(50.4037vw, 300vh) scale(0.7208);
    }
  }

  @keyframes fall-136 {
    66.095% {
      -webkit-transform: translate(52.37vw, 198.285vh) scale(0.7208);
      transform: translate(52.37vw, 198.285vh) scale(0.7208);
    }

    to {
      -webkit-transform: translate(50.4037vw, 300vh) scale(0.7208);
      transform: translate(50.4037vw, 300vh) scale(0.7208);
    }
  }

  .snow:nth-child(137) {
    opacity: 0.4021;
    -webkit-transform: translate(52.292vw, -10px) scale(0.4854);
    transform: translate(52.292vw, -10px) scale(0.4854);
    -webkit-animation: fall-137 26s linear -19s infinite;
    animation: fall-137 26s linear -19s infinite;
  }

  @-webkit-keyframes fall-137 {
    61.664% {
      -webkit-transform: translate(48.2493vw, 184.992vh) scale(0.4854);
      transform: translate(48.2493vw, 184.992vh) scale(0.4854);
    }

    to {
      -webkit-transform: translate(50.27065vw, 300vh) scale(0.4854);
      transform: translate(50.27065vw, 300vh) scale(0.4854);
    }
  }

  @keyframes fall-137 {
    61.664% {
      -webkit-transform: translate(48.2493vw, 184.992vh) scale(0.4854);
      transform: translate(48.2493vw, 184.992vh) scale(0.4854);
    }

    to {
      -webkit-transform: translate(50.27065vw, 300vh) scale(0.4854);
      transform: translate(50.27065vw, 300vh) scale(0.4854);
    }
  }

  .snow:nth-child(138) {
    opacity: 0.1099;
    -webkit-transform: translate(37.9789vw, -10px) scale(0.9753);
    transform: translate(37.9789vw, -10px) scale(0.9753);
    -webkit-animation: fall-138 32s linear -7s infinite;
    animation: fall-138 32s linear -7s infinite;
  }

  @-webkit-keyframes fall-138 {
    75.276% {
      -webkit-transform: translate(30.5909vw, 225.828vh) scale(0.9753);
      transform: translate(30.5909vw, 225.828vh) scale(0.9753);
    }

    to {
      -webkit-transform: translate(34.2849vw, 300vh) scale(0.9753);
      transform: translate(34.2849vw, 300vh) scale(0.9753);
    }
  }

  @keyframes fall-138 {
    75.276% {
      -webkit-transform: translate(30.5909vw, 225.828vh) scale(0.9753);
      transform: translate(30.5909vw, 225.828vh) scale(0.9753);
    }

    to {
      -webkit-transform: translate(34.2849vw, 300vh) scale(0.9753);
      transform: translate(34.2849vw, 300vh) scale(0.9753);
    }
  }

  .snow:nth-child(139) {
    opacity: 0.1722;
    -webkit-transform: translate(75.2426vw, -10px) scale(0.6795);
    transform: translate(75.2426vw, -10px) scale(0.6795);
    -webkit-animation: fall-139 33s linear -17s infinite;
    animation: fall-139 33s linear -17s infinite;
  }

  @-webkit-keyframes fall-139 {
    75.98% {
      -webkit-transform: translate(81.2793vw, 227.94vh) scale(0.6795);
      transform: translate(81.2793vw, 227.94vh) scale(0.6795);
    }

    to {
      -webkit-transform: translate(78.26095vw, 300vh) scale(0.6795);
      transform: translate(78.26095vw, 300vh) scale(0.6795);
    }
  }

  @keyframes fall-139 {
    75.98% {
      -webkit-transform: translate(81.2793vw, 227.94vh) scale(0.6795);
      transform: translate(81.2793vw, 227.94vh) scale(0.6795);
    }

    to {
      -webkit-transform: translate(78.26095vw, 300vh) scale(0.6795);
      transform: translate(78.26095vw, 300vh) scale(0.6795);
    }
  }

  .snow:nth-child(140) {
    opacity: 0.7362;
    -webkit-transform: translate(13.9895vw, -10px) scale(0.1013);
    transform: translate(13.9895vw, -10px) scale(0.1013);
    -webkit-animation: fall-140 26s linear -14s infinite;
    animation: fall-140 26s linear -14s infinite;
  }

  @-webkit-keyframes fall-140 {
    32.637% {
      -webkit-transform: translate(15.9864vw, 97.911vh) scale(0.1013);
      transform: translate(15.9864vw, 97.911vh) scale(0.1013);
    }

    to {
      -webkit-transform: translate(14.98795vw, 300vh) scale(0.1013);
      transform: translate(14.98795vw, 300vh) scale(0.1013);
    }
  }

  @keyframes fall-140 {
    32.637% {
      -webkit-transform: translate(15.9864vw, 97.911vh) scale(0.1013);
      transform: translate(15.9864vw, 97.911vh) scale(0.1013);
    }

    to {
      -webkit-transform: translate(14.98795vw, 300vh) scale(0.1013);
      transform: translate(14.98795vw, 300vh) scale(0.1013);
    }
  }

  .snow:nth-child(141) {
    opacity: 0.0119;
    -webkit-transform: translate(45.1394vw, -10px) scale(0.1613);
    transform: translate(45.1394vw, -10px) scale(0.1613);
    -webkit-animation: fall-141 34s linear -16s infinite;
    animation: fall-141 34s linear -16s infinite;
  }

  @-webkit-keyframes fall-141 {
    53.152% {
      -webkit-transform: translate(43.9797vw, 159.456vh) scale(0.1613);
      transform: translate(43.9797vw, 159.456vh) scale(0.1613);
    }

    to {
      -webkit-transform: translate(44.55955vw, 300vh) scale(0.1613);
      transform: translate(44.55955vw, 300vh) scale(0.1613);
    }
  }

  @keyframes fall-141 {
    53.152% {
      -webkit-transform: translate(43.9797vw, 159.456vh) scale(0.1613);
      transform: translate(43.9797vw, 159.456vh) scale(0.1613);
    }

    to {
      -webkit-transform: translate(44.55955vw, 300vh) scale(0.1613);
      transform: translate(44.55955vw, 300vh) scale(0.1613);
    }
  }

  .snow:nth-child(142) {
    opacity: 0.0649;
    -webkit-transform: translate(84.847vw, -10px) scale(0.7564);
    transform: translate(84.847vw, -10px) scale(0.7564);
    -webkit-animation: fall-142 38s linear -11s infinite;
    animation: fall-142 38s linear -11s infinite;
  }

  @-webkit-keyframes fall-142 {
    34.779% {
      -webkit-transform: translate(80.0341vw, 104.337vh) scale(0.7564);
      transform: translate(80.0341vw, 104.337vh) scale(0.7564);
    }

    to {
      -webkit-transform: translate(82.44055vw, 300vh) scale(0.7564);
      transform: translate(82.44055vw, 300vh) scale(0.7564);
    }
  }

  @keyframes fall-142 {
    34.779% {
      -webkit-transform: translate(80.0341vw, 104.337vh) scale(0.7564);
      transform: translate(80.0341vw, 104.337vh) scale(0.7564);
    }

    to {
      -webkit-transform: translate(82.44055vw, 300vh) scale(0.7564);
      transform: translate(82.44055vw, 300vh) scale(0.7564);
    }
  }

  .snow:nth-child(143) {
    opacity: 0.1458;
    -webkit-transform: translate(47.9907vw, -10px) scale(0.4431);
    transform: translate(47.9907vw, -10px) scale(0.4431);
    -webkit-animation: fall-143 20s linear -20s infinite;
    animation: fall-143 20s linear -20s infinite;
  }

  @-webkit-keyframes fall-143 {
    33.196% {
      -webkit-transform: translate(55.5797vw, 99.588vh) scale(0.4431);
      transform: translate(55.5797vw, 99.588vh) scale(0.4431);
    }

    to {
      -webkit-transform: translate(51.7852vw, 300vh) scale(0.4431);
      transform: translate(51.7852vw, 300vh) scale(0.4431);
    }
  }

  @keyframes fall-143 {
    33.196% {
      -webkit-transform: translate(55.5797vw, 99.588vh) scale(0.4431);
      transform: translate(55.5797vw, 99.588vh) scale(0.4431);
    }

    to {
      -webkit-transform: translate(51.7852vw, 300vh) scale(0.4431);
      transform: translate(51.7852vw, 300vh) scale(0.4431);
    }
  }

  .snow:nth-child(144) {
    opacity: 0.0139;
    -webkit-transform: translate(56.1819vw, -10px) scale(0.4726);
    transform: translate(56.1819vw, -10px) scale(0.4726);
    -webkit-animation: fall-144 38s linear -9s infinite;
    animation: fall-144 38s linear -9s infinite;
  }

  @-webkit-keyframes fall-144 {
    34.995% {
      -webkit-transform: translate(48.8878vw, 104.985vh) scale(0.4726);
      transform: translate(48.8878vw, 104.985vh) scale(0.4726);
    }

    to {
      -webkit-transform: translate(52.53485vw, 300vh) scale(0.4726);
      transform: translate(52.53485vw, 300vh) scale(0.4726);
    }
  }

  @keyframes fall-144 {
    34.995% {
      -webkit-transform: translate(48.8878vw, 104.985vh) scale(0.4726);
      transform: translate(48.8878vw, 104.985vh) scale(0.4726);
    }

    to {
      -webkit-transform: translate(52.53485vw, 300vh) scale(0.4726);
      transform: translate(52.53485vw, 300vh) scale(0.4726);
    }
  }

  .snow:nth-child(145) {
    opacity: 0.5627;
    -webkit-transform: translate(56.4693vw, -10px) scale(0.7854);
    transform: translate(56.4693vw, -10px) scale(0.7854);
    -webkit-animation: fall-145 32s linear -6s infinite;
    animation: fall-145 32s linear -6s infinite;
  }

  @-webkit-keyframes fall-145 {
    53.676% {
      -webkit-transform: translate(65.7309vw, 161.028vh) scale(0.7854);
      transform: translate(65.7309vw, 161.028vh) scale(0.7854);
    }

    to {
      -webkit-transform: translate(61.1001vw, 300vh) scale(0.7854);
      transform: translate(61.1001vw, 300vh) scale(0.7854);
    }
  }

  @keyframes fall-145 {
    53.676% {
      -webkit-transform: translate(65.7309vw, 161.028vh) scale(0.7854);
      transform: translate(65.7309vw, 161.028vh) scale(0.7854);
    }

    to {
      -webkit-transform: translate(61.1001vw, 300vh) scale(0.7854);
      transform: translate(61.1001vw, 300vh) scale(0.7854);
    }
  }

  .snow:nth-child(146) {
    opacity: 0.2866;
    -webkit-transform: translate(13.4388vw, -10px) scale(0.7461);
    transform: translate(13.4388vw, -10px) scale(0.7461);
    -webkit-animation: fall-146 39s linear -16s infinite;
    animation: fall-146 39s linear -16s infinite;
  }

  @-webkit-keyframes fall-146 {
    72.018% {
      -webkit-transform: translate(16.3466vw, 216.054vh) scale(0.7461);
      transform: translate(16.3466vw, 216.054vh) scale(0.7461);
    }

    to {
      -webkit-transform: translate(14.8927vw, 300vh) scale(0.7461);
      transform: translate(14.8927vw, 300vh) scale(0.7461);
    }
  }

  @keyframes fall-146 {
    72.018% {
      -webkit-transform: translate(16.3466vw, 216.054vh) scale(0.7461);
      transform: translate(16.3466vw, 216.054vh) scale(0.7461);
    }

    to {
      -webkit-transform: translate(14.8927vw, 300vh) scale(0.7461);
      transform: translate(14.8927vw, 300vh) scale(0.7461);
    }
  }

  .snow:nth-child(147) {
    opacity: 0.2529;
    -webkit-transform: translate(83.6644vw, -10px) scale(0.2321);
    transform: translate(83.6644vw, -10px) scale(0.2321);
    -webkit-animation: fall-147 27s linear -27s infinite;
    animation: fall-147 27s linear -27s infinite;
  }

  @-webkit-keyframes fall-147 {
    38.14% {
      -webkit-transform: translate(74.6965vw, 114.42vh) scale(0.2321);
      transform: translate(74.6965vw, 114.42vh) scale(0.2321);
    }

    to {
      -webkit-transform: translate(79.18045vw, 300vh) scale(0.2321);
      transform: translate(79.18045vw, 300vh) scale(0.2321);
    }
  }

  @keyframes fall-147 {
    38.14% {
      -webkit-transform: translate(74.6965vw, 114.42vh) scale(0.2321);
      transform: translate(74.6965vw, 114.42vh) scale(0.2321);
    }

    to {
      -webkit-transform: translate(79.18045vw, 300vh) scale(0.2321);
      transform: translate(79.18045vw, 300vh) scale(0.2321);
    }
  }

  .snow:nth-child(148) {
    opacity: 0.6031;
    -webkit-transform: translate(10.3226vw, -10px) scale(0.6576);
    transform: translate(10.3226vw, -10px) scale(0.6576);
    -webkit-animation: fall-148 39s linear -27s infinite;
    animation: fall-148 39s linear -27s infinite;
  }

  @-webkit-keyframes fall-148 {
    58.471% {
      -webkit-transform: translate(4.7326vw, 175.413vh) scale(0.6576);
      transform: translate(4.7326vw, 175.413vh) scale(0.6576);
    }

    to {
      -webkit-transform: translate(7.5276vw, 300vh) scale(0.6576);
      transform: translate(7.5276vw, 300vh) scale(0.6576);
    }
  }

  @keyframes fall-148 {
    58.471% {
      -webkit-transform: translate(4.7326vw, 175.413vh) scale(0.6576);
      transform: translate(4.7326vw, 175.413vh) scale(0.6576);
    }

    to {
      -webkit-transform: translate(7.5276vw, 300vh) scale(0.6576);
      transform: translate(7.5276vw, 300vh) scale(0.6576);
    }
  }

  .snow:nth-child(149) {
    opacity: 0.5055;
    -webkit-transform: translate(19.2987vw, -10px) scale(0.2797);
    transform: translate(19.2987vw, -10px) scale(0.2797);
    -webkit-animation: fall-149 38s linear -25s infinite;
    animation: fall-149 38s linear -25s infinite;
  }

  @-webkit-keyframes fall-149 {
    48.604% {
      -webkit-transform: translate(24.8059vw, 145.812vh) scale(0.2797);
      transform: translate(24.8059vw, 145.812vh) scale(0.2797);
    }

    to {
      -webkit-transform: translate(22.0523vw, 300vh) scale(0.2797);
      transform: translate(22.0523vw, 300vh) scale(0.2797);
    }
  }

  @keyframes fall-149 {
    48.604% {
      -webkit-transform: translate(24.8059vw, 145.812vh) scale(0.2797);
      transform: translate(24.8059vw, 145.812vh) scale(0.2797);
    }

    to {
      -webkit-transform: translate(22.0523vw, 300vh) scale(0.2797);
      transform: translate(22.0523vw, 300vh) scale(0.2797);
    }
  }

  .snow:nth-child(150) {
    opacity: 0.4619;
    -webkit-transform: translate(79.2194vw, -10px) scale(0.8404);
    transform: translate(79.2194vw, -10px) scale(0.8404);
    -webkit-animation: fall-150 25s linear -22s infinite;
    animation: fall-150 25s linear -22s infinite;
  }

  @-webkit-keyframes fall-150 {
    52.694% {
      -webkit-transform: translate(84.1402vw, 158.082vh) scale(0.8404);
      transform: translate(84.1402vw, 158.082vh) scale(0.8404);
    }

    to {
      -webkit-transform: translate(81.6798vw, 300vh) scale(0.8404);
      transform: translate(81.6798vw, 300vh) scale(0.8404);
    }
  }

  @keyframes fall-150 {
    52.694% {
      -webkit-transform: translate(84.1402vw, 158.082vh) scale(0.8404);
      transform: translate(84.1402vw, 158.082vh) scale(0.8404);
    }

    to {
      -webkit-transform: translate(81.6798vw, 300vh) scale(0.8404);
      transform: translate(81.6798vw, 300vh) scale(0.8404);
    }
  }

  .snow:nth-child(151) {
    opacity: 0.6742;
    -webkit-transform: translate(70.3012vw, -10px) scale(0.3297);
    transform: translate(70.3012vw, -10px) scale(0.3297);
    -webkit-animation: fall-151 37s linear -1s infinite;
    animation: fall-151 37s linear -1s infinite;
  }

  @-webkit-keyframes fall-151 {
    62.118% {
      -webkit-transform: translate(63.7426vw, 186.354vh) scale(0.3297);
      transform: translate(63.7426vw, 186.354vh) scale(0.3297);
    }

    to {
      -webkit-transform: translate(67.0219vw, 300vh) scale(0.3297);
      transform: translate(67.0219vw, 300vh) scale(0.3297);
    }
  }

  @keyframes fall-151 {
    62.118% {
      -webkit-transform: translate(63.7426vw, 186.354vh) scale(0.3297);
      transform: translate(63.7426vw, 186.354vh) scale(0.3297);
    }

    to {
      -webkit-transform: translate(67.0219vw, 300vh) scale(0.3297);
      transform: translate(67.0219vw, 300vh) scale(0.3297);
    }
  }

  .snow:nth-child(152) {
    opacity: 0.001;
    -webkit-transform: translate(2.0329vw, -10px) scale(0.9641);
    transform: translate(2.0329vw, -10px) scale(0.9641);
    -webkit-animation: fall-152 29s linear -26s infinite;
    animation: fall-152 29s linear -26s infinite;
  }

  @-webkit-keyframes fall-152 {
    51.377% {
      -webkit-transform: translate(11.2554vw, 154.131vh) scale(0.9641);
      transform: translate(11.2554vw, 154.131vh) scale(0.9641);
    }

    to {
      -webkit-transform: translate(6.64415vw, 300vh) scale(0.9641);
      transform: translate(6.64415vw, 300vh) scale(0.9641);
    }
  }

  @keyframes fall-152 {
    51.377% {
      -webkit-transform: translate(11.2554vw, 154.131vh) scale(0.9641);
      transform: translate(11.2554vw, 154.131vh) scale(0.9641);
    }

    to {
      -webkit-transform: translate(6.64415vw, 300vh) scale(0.9641);
      transform: translate(6.64415vw, 300vh) scale(0.9641);
    }
  }

  .snow:nth-child(153) {
    opacity: 0.9188;
    -webkit-transform: translate(79.907vw, -10px) scale(0.1901);
    transform: translate(79.907vw, -10px) scale(0.1901);
    -webkit-animation: fall-153 38s linear -5s infinite;
    animation: fall-153 38s linear -5s infinite;
  }

  @-webkit-keyframes fall-153 {
    36.434% {
      -webkit-transform: translate(83.3334vw, 109.302vh) scale(0.1901);
      transform: translate(83.3334vw, 109.302vh) scale(0.1901);
    }

    to {
      -webkit-transform: translate(81.6202vw, 300vh) scale(0.1901);
      transform: translate(81.6202vw, 300vh) scale(0.1901);
    }
  }

  @keyframes fall-153 {
    36.434% {
      -webkit-transform: translate(83.3334vw, 109.302vh) scale(0.1901);
      transform: translate(83.3334vw, 109.302vh) scale(0.1901);
    }

    to {
      -webkit-transform: translate(81.6202vw, 300vh) scale(0.1901);
      transform: translate(81.6202vw, 300vh) scale(0.1901);
    }
  }

  .snow:nth-child(154) {
    opacity: 0.7346;
    -webkit-transform: translate(87.9679vw, -10px) scale(0.0525);
    transform: translate(87.9679vw, -10px) scale(0.0525);
    -webkit-animation: fall-154 20s linear -24s infinite;
    animation: fall-154 20s linear -24s infinite;
  }

  @-webkit-keyframes fall-154 {
    70.093% {
      -webkit-transform: translate(86.0434vw, 210.279vh) scale(0.0525);
      transform: translate(86.0434vw, 210.279vh) scale(0.0525);
    }

    to {
      -webkit-transform: translate(87.00565vw, 300vh) scale(0.0525);
      transform: translate(87.00565vw, 300vh) scale(0.0525);
    }
  }

  @keyframes fall-154 {
    70.093% {
      -webkit-transform: translate(86.0434vw, 210.279vh) scale(0.0525);
      transform: translate(86.0434vw, 210.279vh) scale(0.0525);
    }

    to {
      -webkit-transform: translate(87.00565vw, 300vh) scale(0.0525);
      transform: translate(87.00565vw, 300vh) scale(0.0525);
    }
  }

  .snow:nth-child(155) {
    opacity: 0.6681;
    -webkit-transform: translate(54.123vw, -10px) scale(0.1492);
    transform: translate(54.123vw, -10px) scale(0.1492);
    -webkit-animation: fall-155 22s linear -16s infinite;
    animation: fall-155 22s linear -16s infinite;
  }

  @-webkit-keyframes fall-155 {
    35.958% {
      -webkit-transform: translate(44.2742vw, 107.874vh) scale(0.1492);
      transform: translate(44.2742vw, 107.874vh) scale(0.1492);
    }

    to {
      -webkit-transform: translate(49.1986vw, 300vh) scale(0.1492);
      transform: translate(49.1986vw, 300vh) scale(0.1492);
    }
  }

  @keyframes fall-155 {
    35.958% {
      -webkit-transform: translate(44.2742vw, 107.874vh) scale(0.1492);
      transform: translate(44.2742vw, 107.874vh) scale(0.1492);
    }

    to {
      -webkit-transform: translate(49.1986vw, 300vh) scale(0.1492);
      transform: translate(49.1986vw, 300vh) scale(0.1492);
    }
  }

  .snow:nth-child(156) {
    opacity: 0.7284;
    -webkit-transform: translate(6.4909vw, -10px) scale(0.3183);
    transform: translate(6.4909vw, -10px) scale(0.3183);
    -webkit-animation: fall-156 36s linear -19s infinite;
    animation: fall-156 36s linear -19s infinite;
  }

  @-webkit-keyframes fall-156 {
    46.85% {
      -webkit-transform: translate(11.531vw, 140.55vh) scale(0.3183);
      transform: translate(11.531vw, 140.55vh) scale(0.3183);
    }

    to {
      -webkit-transform: translate(9.01095vw, 300vh) scale(0.3183);
      transform: translate(9.01095vw, 300vh) scale(0.3183);
    }
  }

  @keyframes fall-156 {
    46.85% {
      -webkit-transform: translate(11.531vw, 140.55vh) scale(0.3183);
      transform: translate(11.531vw, 140.55vh) scale(0.3183);
    }

    to {
      -webkit-transform: translate(9.01095vw, 300vh) scale(0.3183);
      transform: translate(9.01095vw, 300vh) scale(0.3183);
    }
  }

  .snow:nth-child(157) {
    opacity: 0.7507;
    -webkit-transform: translate(5.2074vw, -10px) scale(0.7517);
    transform: translate(5.2074vw, -10px) scale(0.7517);
    -webkit-animation: fall-157 22s linear -4s infinite;
    animation: fall-157 22s linear -4s infinite;
  }

  @-webkit-keyframes fall-157 {
    55.503% {
      -webkit-transform: translate(-0.7337vw, 166.509vh) scale(0.7517);
      transform: translate(-0.7337vw, 166.509vh) scale(0.7517);
    }

    to {
      -webkit-transform: translate(2.23685vw, 300vh) scale(0.7517);
      transform: translate(2.23685vw, 300vh) scale(0.7517);
    }
  }

  @keyframes fall-157 {
    55.503% {
      -webkit-transform: translate(-0.7337vw, 166.509vh) scale(0.7517);
      transform: translate(-0.7337vw, 166.509vh) scale(0.7517);
    }

    to {
      -webkit-transform: translate(2.23685vw, 300vh) scale(0.7517);
      transform: translate(2.23685vw, 300vh) scale(0.7517);
    }
  }

  .snow:nth-child(158) {
    opacity: 0.2625;
    -webkit-transform: translate(84.4039vw, -10px) scale(0.8762);
    transform: translate(84.4039vw, -10px) scale(0.8762);
    -webkit-animation: fall-158 24s linear -26s infinite;
    animation: fall-158 24s linear -26s infinite;
  }

  @-webkit-keyframes fall-158 {
    66.031% {
      -webkit-transform: translate(82.8002vw, 198.093vh) scale(0.8762);
      transform: translate(82.8002vw, 198.093vh) scale(0.8762);
    }

    to {
      -webkit-transform: translate(83.60205vw, 300vh) scale(0.8762);
      transform: translate(83.60205vw, 300vh) scale(0.8762);
    }
  }

  @keyframes fall-158 {
    66.031% {
      -webkit-transform: translate(82.8002vw, 198.093vh) scale(0.8762);
      transform: translate(82.8002vw, 198.093vh) scale(0.8762);
    }

    to {
      -webkit-transform: translate(83.60205vw, 300vh) scale(0.8762);
      transform: translate(83.60205vw, 300vh) scale(0.8762);
    }
  }

  .snow:nth-child(159) {
    opacity: 0.0966;
    -webkit-transform: translate(71.312vw, -10px) scale(0.3087);
    transform: translate(71.312vw, -10px) scale(0.3087);
    -webkit-animation: fall-159 31s linear -15s infinite;
    animation: fall-159 31s linear -15s infinite;
  }

  @-webkit-keyframes fall-159 {
    48.694% {
      -webkit-transform: translate(65.0505vw, 146.082vh) scale(0.3087);
      transform: translate(65.0505vw, 146.082vh) scale(0.3087);
    }

    to {
      -webkit-transform: translate(68.18125vw, 300vh) scale(0.3087);
      transform: translate(68.18125vw, 300vh) scale(0.3087);
    }
  }

  @keyframes fall-159 {
    48.694% {
      -webkit-transform: translate(65.0505vw, 146.082vh) scale(0.3087);
      transform: translate(65.0505vw, 146.082vh) scale(0.3087);
    }

    to {
      -webkit-transform: translate(68.18125vw, 300vh) scale(0.3087);
      transform: translate(68.18125vw, 300vh) scale(0.3087);
    }
  }

  .snow:nth-child(160) {
    opacity: 0.9103;
    -webkit-transform: translate(47.5042vw, -10px) scale(0.9896);
    transform: translate(47.5042vw, -10px) scale(0.9896);
    -webkit-animation: fall-160 33s linear -25s infinite;
    animation: fall-160 33s linear -25s infinite;
  }

  @-webkit-keyframes fall-160 {
    46.48% {
      -webkit-transform: translate(41.4202vw, 139.44vh) scale(0.9896);
      transform: translate(41.4202vw, 139.44vh) scale(0.9896);
    }

    to {
      -webkit-transform: translate(44.4622vw, 300vh) scale(0.9896);
      transform: translate(44.4622vw, 300vh) scale(0.9896);
    }
  }

  @keyframes fall-160 {
    46.48% {
      -webkit-transform: translate(41.4202vw, 139.44vh) scale(0.9896);
      transform: translate(41.4202vw, 139.44vh) scale(0.9896);
    }

    to {
      -webkit-transform: translate(44.4622vw, 300vh) scale(0.9896);
      transform: translate(44.4622vw, 300vh) scale(0.9896);
    }
  }

  .snow:nth-child(161) {
    opacity: 0.2534;
    -webkit-transform: translate(5.5351vw, -10px) scale(0.2611);
    transform: translate(5.5351vw, -10px) scale(0.2611);
    -webkit-animation: fall-161 26s linear -3s infinite;
    animation: fall-161 26s linear -3s infinite;
  }

  @-webkit-keyframes fall-161 {
    38.47% {
      -webkit-transform: translate(12.2339vw, 115.41vh) scale(0.2611);
      transform: translate(12.2339vw, 115.41vh) scale(0.2611);
    }

    to {
      -webkit-transform: translate(8.8845vw, 300vh) scale(0.2611);
      transform: translate(8.8845vw, 300vh) scale(0.2611);
    }
  }

  @keyframes fall-161 {
    38.47% {
      -webkit-transform: translate(12.2339vw, 115.41vh) scale(0.2611);
      transform: translate(12.2339vw, 115.41vh) scale(0.2611);
    }

    to {
      -webkit-transform: translate(8.8845vw, 300vh) scale(0.2611);
      transform: translate(8.8845vw, 300vh) scale(0.2611);
    }
  }

  .snow:nth-child(162) {
    opacity: 0.0066;
    -webkit-transform: translate(5.8215vw, -10px) scale(0.5893);
    transform: translate(5.8215vw, -10px) scale(0.5893);
    -webkit-animation: fall-162 26s linear -11s infinite;
    animation: fall-162 26s linear -11s infinite;
  }

  @-webkit-keyframes fall-162 {
    40.925% {
      -webkit-transform: translate(9.6697vw, 122.775vh) scale(0.5893);
      transform: translate(9.6697vw, 122.775vh) scale(0.5893);
    }

    to {
      -webkit-transform: translate(7.7456vw, 300vh) scale(0.5893);
      transform: translate(7.7456vw, 300vh) scale(0.5893);
    }
  }

  @keyframes fall-162 {
    40.925% {
      -webkit-transform: translate(9.6697vw, 122.775vh) scale(0.5893);
      transform: translate(9.6697vw, 122.775vh) scale(0.5893);
    }

    to {
      -webkit-transform: translate(7.7456vw, 300vh) scale(0.5893);
      transform: translate(7.7456vw, 300vh) scale(0.5893);
    }
  }

  .snow:nth-child(163) {
    opacity: 0.8682;
    -webkit-transform: translate(73.3311vw, -10px) scale(0.8075);
    transform: translate(73.3311vw, -10px) scale(0.8075);
    -webkit-animation: fall-163 30s linear -14s infinite;
    animation: fall-163 30s linear -14s infinite;
  }

  @-webkit-keyframes fall-163 {
    70.605% {
      -webkit-transform: translate(71.1793vw, 211.815vh) scale(0.8075);
      transform: translate(71.1793vw, 211.815vh) scale(0.8075);
    }

    to {
      -webkit-transform: translate(72.2552vw, 300vh) scale(0.8075);
      transform: translate(72.2552vw, 300vh) scale(0.8075);
    }
  }

  @keyframes fall-163 {
    70.605% {
      -webkit-transform: translate(71.1793vw, 211.815vh) scale(0.8075);
      transform: translate(71.1793vw, 211.815vh) scale(0.8075);
    }

    to {
      -webkit-transform: translate(72.2552vw, 300vh) scale(0.8075);
      transform: translate(72.2552vw, 300vh) scale(0.8075);
    }
  }

  .snow:nth-child(164) {
    opacity: 0.0246;
    -webkit-transform: translate(86.5902vw, -10px) scale(0.6599);
    transform: translate(86.5902vw, -10px) scale(0.6599);
    -webkit-animation: fall-164 34s linear -19s infinite;
    animation: fall-164 34s linear -19s infinite;
  }

  @-webkit-keyframes fall-164 {
    69.913% {
      -webkit-transform: translate(77.255vw, 209.739vh) scale(0.6599);
      transform: translate(77.255vw, 209.739vh) scale(0.6599);
    }

    to {
      -webkit-transform: translate(81.9226vw, 300vh) scale(0.6599);
      transform: translate(81.9226vw, 300vh) scale(0.6599);
    }
  }

  @keyframes fall-164 {
    69.913% {
      -webkit-transform: translate(77.255vw, 209.739vh) scale(0.6599);
      transform: translate(77.255vw, 209.739vh) scale(0.6599);
    }

    to {
      -webkit-transform: translate(81.9226vw, 300vh) scale(0.6599);
      transform: translate(81.9226vw, 300vh) scale(0.6599);
    }
  }

  .snow:nth-child(165) {
    opacity: 0.9962;
    -webkit-transform: translate(31.3957vw, -10px) scale(0.8432);
    transform: translate(31.3957vw, -10px) scale(0.8432);
    -webkit-animation: fall-165 40s linear -20s infinite;
    animation: fall-165 40s linear -20s infinite;
  }

  @-webkit-keyframes fall-165 {
    41.857% {
      -webkit-transform: translate(33.7687vw, 125.571vh) scale(0.8432);
      transform: translate(33.7687vw, 125.571vh) scale(0.8432);
    }

    to {
      -webkit-transform: translate(32.5822vw, 300vh) scale(0.8432);
      transform: translate(32.5822vw, 300vh) scale(0.8432);
    }
  }

  @keyframes fall-165 {
    41.857% {
      -webkit-transform: translate(33.7687vw, 125.571vh) scale(0.8432);
      transform: translate(33.7687vw, 125.571vh) scale(0.8432);
    }

    to {
      -webkit-transform: translate(32.5822vw, 300vh) scale(0.8432);
      transform: translate(32.5822vw, 300vh) scale(0.8432);
    }
  }

  .snow:nth-child(166) {
    opacity: 0.9879;
    -webkit-transform: translate(6.5644vw, -10px) scale(0.8711);
    transform: translate(6.5644vw, -10px) scale(0.8711);
    -webkit-animation: fall-166 29s linear -14s infinite;
    animation: fall-166 29s linear -14s infinite;
  }

  @-webkit-keyframes fall-166 {
    51.151% {
      -webkit-transform: translate(12.6817vw, 153.453vh) scale(0.8711);
      transform: translate(12.6817vw, 153.453vh) scale(0.8711);
    }

    to {
      -webkit-transform: translate(9.62305vw, 300vh) scale(0.8711);
      transform: translate(9.62305vw, 300vh) scale(0.8711);
    }
  }

  @keyframes fall-166 {
    51.151% {
      -webkit-transform: translate(12.6817vw, 153.453vh) scale(0.8711);
      transform: translate(12.6817vw, 153.453vh) scale(0.8711);
    }

    to {
      -webkit-transform: translate(9.62305vw, 300vh) scale(0.8711);
      transform: translate(9.62305vw, 300vh) scale(0.8711);
    }
  }

  .snow:nth-child(167) {
    opacity: 0.9777;
    -webkit-transform: translate(16.8406vw, -10px) scale(0.5744);
    transform: translate(16.8406vw, -10px) scale(0.5744);
    -webkit-animation: fall-167 28s linear -16s infinite;
    animation: fall-167 28s linear -16s infinite;
  }

  @-webkit-keyframes fall-167 {
    36.895% {
      -webkit-transform: translate(24.8933vw, 110.685vh) scale(0.5744);
      transform: translate(24.8933vw, 110.685vh) scale(0.5744);
    }

    to {
      -webkit-transform: translate(20.86695vw, 300vh) scale(0.5744);
      transform: translate(20.86695vw, 300vh) scale(0.5744);
    }
  }

  @keyframes fall-167 {
    36.895% {
      -webkit-transform: translate(24.8933vw, 110.685vh) scale(0.5744);
      transform: translate(24.8933vw, 110.685vh) scale(0.5744);
    }

    to {
      -webkit-transform: translate(20.86695vw, 300vh) scale(0.5744);
      transform: translate(20.86695vw, 300vh) scale(0.5744);
    }
  }

  .snow:nth-child(168) {
    opacity: 0.4579;
    -webkit-transform: translate(31.4962vw, -10px) scale(0.0637);
    transform: translate(31.4962vw, -10px) scale(0.0637);
    -webkit-animation: fall-168 37s linear -8s infinite;
    animation: fall-168 37s linear -8s infinite;
  }

  @-webkit-keyframes fall-168 {
    34.047% {
      -webkit-transform: translate(36.0892vw, 102.141vh) scale(0.0637);
      transform: translate(36.0892vw, 102.141vh) scale(0.0637);
    }

    to {
      -webkit-transform: translate(33.7927vw, 300vh) scale(0.0637);
      transform: translate(33.7927vw, 300vh) scale(0.0637);
    }
  }

  @keyframes fall-168 {
    34.047% {
      -webkit-transform: translate(36.0892vw, 102.141vh) scale(0.0637);
      transform: translate(36.0892vw, 102.141vh) scale(0.0637);
    }

    to {
      -webkit-transform: translate(33.7927vw, 300vh) scale(0.0637);
      transform: translate(33.7927vw, 300vh) scale(0.0637);
    }
  }

  .snow:nth-child(169) {
    opacity: 0.8722;
    -webkit-transform: translate(91.3729vw, -10px) scale(0.0419);
    transform: translate(91.3729vw, -10px) scale(0.0419);
    -webkit-animation: fall-169 35s linear -14s infinite;
    animation: fall-169 35s linear -14s infinite;
  }

  @-webkit-keyframes fall-169 {
    67.992% {
      -webkit-transform: translate(86.9732vw, 203.976vh) scale(0.0419);
      transform: translate(86.9732vw, 203.976vh) scale(0.0419);
    }

    to {
      -webkit-transform: translate(89.17305vw, 300vh) scale(0.0419);
      transform: translate(89.17305vw, 300vh) scale(0.0419);
    }
  }

  @keyframes fall-169 {
    67.992% {
      -webkit-transform: translate(86.9732vw, 203.976vh) scale(0.0419);
      transform: translate(86.9732vw, 203.976vh) scale(0.0419);
    }

    to {
      -webkit-transform: translate(89.17305vw, 300vh) scale(0.0419);
      transform: translate(89.17305vw, 300vh) scale(0.0419);
    }
  }

  .snow:nth-child(170) {
    opacity: 0.1259;
    -webkit-transform: translate(63.2045vw, -10px) scale(0.4992);
    transform: translate(63.2045vw, -10px) scale(0.4992);
    -webkit-animation: fall-170 30s linear -13s infinite;
    animation: fall-170 30s linear -13s infinite;
  }

  @-webkit-keyframes fall-170 {
    79.639% {
      -webkit-transform: translate(58.289vw, 238.917vh) scale(0.4992);
      transform: translate(58.289vw, 238.917vh) scale(0.4992);
    }

    to {
      -webkit-transform: translate(60.74675vw, 300vh) scale(0.4992);
      transform: translate(60.74675vw, 300vh) scale(0.4992);
    }
  }

  @keyframes fall-170 {
    79.639% {
      -webkit-transform: translate(58.289vw, 238.917vh) scale(0.4992);
      transform: translate(58.289vw, 238.917vh) scale(0.4992);
    }

    to {
      -webkit-transform: translate(60.74675vw, 300vh) scale(0.4992);
      transform: translate(60.74675vw, 300vh) scale(0.4992);
    }
  }

  .snow:nth-child(171) {
    opacity: 0.9175;
    -webkit-transform: translate(97.4563vw, -10px) scale(0.8923);
    transform: translate(97.4563vw, -10px) scale(0.8923);
    -webkit-animation: fall-171 29s linear -2s infinite;
    animation: fall-171 29s linear -2s infinite;
  }

  @-webkit-keyframes fall-171 {
    56.459% {
      -webkit-transform: translate(95.8808vw, 169.377vh) scale(0.8923);
      transform: translate(95.8808vw, 169.377vh) scale(0.8923);
    }

    to {
      -webkit-transform: translate(96.66855vw, 300vh) scale(0.8923);
      transform: translate(96.66855vw, 300vh) scale(0.8923);
    }
  }

  @keyframes fall-171 {
    56.459% {
      -webkit-transform: translate(95.8808vw, 169.377vh) scale(0.8923);
      transform: translate(95.8808vw, 169.377vh) scale(0.8923);
    }

    to {
      -webkit-transform: translate(96.66855vw, 300vh) scale(0.8923);
      transform: translate(96.66855vw, 300vh) scale(0.8923);
    }
  }

  .snow:nth-child(172) {
    opacity: 0.7537;
    -webkit-transform: translate(47.9486vw, -10px) scale(0.5779);
    transform: translate(47.9486vw, -10px) scale(0.5779);
    -webkit-animation: fall-172 24s linear -8s infinite;
    animation: fall-172 24s linear -8s infinite;
  }

  @-webkit-keyframes fall-172 {
    69.347% {
      -webkit-transform: translate(47.0266vw, 208.041vh) scale(0.5779);
      transform: translate(47.0266vw, 208.041vh) scale(0.5779);
    }

    to {
      -webkit-transform: translate(47.4876vw, 300vh) scale(0.5779);
      transform: translate(47.4876vw, 300vh) scale(0.5779);
    }
  }

  @keyframes fall-172 {
    69.347% {
      -webkit-transform: translate(47.0266vw, 208.041vh) scale(0.5779);
      transform: translate(47.0266vw, 208.041vh) scale(0.5779);
    }

    to {
      -webkit-transform: translate(47.4876vw, 300vh) scale(0.5779);
      transform: translate(47.4876vw, 300vh) scale(0.5779);
    }
  }

  .snow:nth-child(173) {
    opacity: 0.7824;
    -webkit-transform: translate(39.5483vw, -10px) scale(0.7258);
    transform: translate(39.5483vw, -10px) scale(0.7258);
    -webkit-animation: fall-173 35s linear -21s infinite;
    animation: fall-173 35s linear -21s infinite;
  }

  @-webkit-keyframes fall-173 {
    43.465% {
      -webkit-transform: translate(38.3749vw, 130.395vh) scale(0.7258);
      transform: translate(38.3749vw, 130.395vh) scale(0.7258);
    }

    to {
      -webkit-transform: translate(38.9616vw, 300vh) scale(0.7258);
      transform: translate(38.9616vw, 300vh) scale(0.7258);
    }
  }

  @keyframes fall-173 {
    43.465% {
      -webkit-transform: translate(38.3749vw, 130.395vh) scale(0.7258);
      transform: translate(38.3749vw, 130.395vh) scale(0.7258);
    }

    to {
      -webkit-transform: translate(38.9616vw, 300vh) scale(0.7258);
      transform: translate(38.9616vw, 300vh) scale(0.7258);
    }
  }

  .snow:nth-child(174) {
    opacity: 0.1144;
    -webkit-transform: translate(19.1505vw, -10px) scale(0.5506);
    transform: translate(19.1505vw, -10px) scale(0.5506);
    -webkit-animation: fall-174 38s linear -26s infinite;
    animation: fall-174 38s linear -26s infinite;
  }

  @-webkit-keyframes fall-174 {
    47.796% {
      -webkit-transform: translate(25.1386vw, 143.388vh) scale(0.5506);
      transform: translate(25.1386vw, 143.388vh) scale(0.5506);
    }

    to {
      -webkit-transform: translate(22.14455vw, 300vh) scale(0.5506);
      transform: translate(22.14455vw, 300vh) scale(0.5506);
    }
  }

  @keyframes fall-174 {
    47.796% {
      -webkit-transform: translate(25.1386vw, 143.388vh) scale(0.5506);
      transform: translate(25.1386vw, 143.388vh) scale(0.5506);
    }

    to {
      -webkit-transform: translate(22.14455vw, 300vh) scale(0.5506);
      transform: translate(22.14455vw, 300vh) scale(0.5506);
    }
  }

  .snow:nth-child(175) {
    opacity: 0.2569;
    -webkit-transform: translate(88.0524vw, -10px) scale(0.121);
    transform: translate(88.0524vw, -10px) scale(0.121);
    -webkit-animation: fall-175 31s linear -1s infinite;
    animation: fall-175 31s linear -1s infinite;
  }

  @-webkit-keyframes fall-175 {
    50.077% {
      -webkit-transform: translate(79.8468vw, 150.231vh) scale(0.121);
      transform: translate(79.8468vw, 150.231vh) scale(0.121);
    }

    to {
      -webkit-transform: translate(83.9496vw, 300vh) scale(0.121);
      transform: translate(83.9496vw, 300vh) scale(0.121);
    }
  }

  @keyframes fall-175 {
    50.077% {
      -webkit-transform: translate(79.8468vw, 150.231vh) scale(0.121);
      transform: translate(79.8468vw, 150.231vh) scale(0.121);
    }

    to {
      -webkit-transform: translate(83.9496vw, 300vh) scale(0.121);
      transform: translate(83.9496vw, 300vh) scale(0.121);
    }
  }

  .snow:nth-child(176) {
    opacity: 0.893;
    -webkit-transform: translate(46.2177vw, -10px) scale(0.2735);
    transform: translate(46.2177vw, -10px) scale(0.2735);
    -webkit-animation: fall-176 38s linear -18s infinite;
    animation: fall-176 38s linear -18s infinite;
  }

  @-webkit-keyframes fall-176 {
    55.892% {
      -webkit-transform: translate(51.4015vw, 167.676vh) scale(0.2735);
      transform: translate(51.4015vw, 167.676vh) scale(0.2735);
    }

    to {
      -webkit-transform: translate(48.8096vw, 300vh) scale(0.2735);
      transform: translate(48.8096vw, 300vh) scale(0.2735);
    }
  }

  @keyframes fall-176 {
    55.892% {
      -webkit-transform: translate(51.4015vw, 167.676vh) scale(0.2735);
      transform: translate(51.4015vw, 167.676vh) scale(0.2735);
    }

    to {
      -webkit-transform: translate(48.8096vw, 300vh) scale(0.2735);
      transform: translate(48.8096vw, 300vh) scale(0.2735);
    }
  }

  .snow:nth-child(177) {
    opacity: 0.3926;
    -webkit-transform: translate(45.7811vw, -10px) scale(0.5207);
    transform: translate(45.7811vw, -10px) scale(0.5207);
    -webkit-animation: fall-177 26s linear -23s infinite;
    animation: fall-177 26s linear -23s infinite;
  }

  @-webkit-keyframes fall-177 {
    32.403% {
      -webkit-transform: translate(55.2041vw, 97.209vh) scale(0.5207);
      transform: translate(55.2041vw, 97.209vh) scale(0.5207);
    }

    to {
      -webkit-transform: translate(50.4926vw, 300vh) scale(0.5207);
      transform: translate(50.4926vw, 300vh) scale(0.5207);
    }
  }

  @keyframes fall-177 {
    32.403% {
      -webkit-transform: translate(55.2041vw, 97.209vh) scale(0.5207);
      transform: translate(55.2041vw, 97.209vh) scale(0.5207);
    }

    to {
      -webkit-transform: translate(50.4926vw, 300vh) scale(0.5207);
      transform: translate(50.4926vw, 300vh) scale(0.5207);
    }
  }

  .snow:nth-child(178) {
    opacity: 0.6651;
    -webkit-transform: translate(46.6051vw, -10px) scale(0.0639);
    transform: translate(46.6051vw, -10px) scale(0.0639);
    -webkit-animation: fall-178 20s linear -14s infinite;
    animation: fall-178 20s linear -14s infinite;
  }

  @-webkit-keyframes fall-178 {
    44.307% {
      -webkit-transform: translate(52.9722vw, 132.921vh) scale(0.0639);
      transform: translate(52.9722vw, 132.921vh) scale(0.0639);
    }

    to {
      -webkit-transform: translate(49.78865vw, 300vh) scale(0.0639);
      transform: translate(49.78865vw, 300vh) scale(0.0639);
    }
  }

  @keyframes fall-178 {
    44.307% {
      -webkit-transform: translate(52.9722vw, 132.921vh) scale(0.0639);
      transform: translate(52.9722vw, 132.921vh) scale(0.0639);
    }

    to {
      -webkit-transform: translate(49.78865vw, 300vh) scale(0.0639);
      transform: translate(49.78865vw, 300vh) scale(0.0639);
    }
  }

  .snow:nth-child(179) {
    opacity: 0.2575;
    -webkit-transform: translate(16.9682vw, -10px) scale(0.7701);
    transform: translate(16.9682vw, -10px) scale(0.7701);
    -webkit-animation: fall-179 37s linear -28s infinite;
    animation: fall-179 37s linear -28s infinite;
  }

  @-webkit-keyframes fall-179 {
    39.553% {
      -webkit-transform: translate(15.4549vw, 118.659vh) scale(0.7701);
      transform: translate(15.4549vw, 118.659vh) scale(0.7701);
    }

    to {
      -webkit-transform: translate(16.21155vw, 300vh) scale(0.7701);
      transform: translate(16.21155vw, 300vh) scale(0.7701);
    }
  }

  @keyframes fall-179 {
    39.553% {
      -webkit-transform: translate(15.4549vw, 118.659vh) scale(0.7701);
      transform: translate(15.4549vw, 118.659vh) scale(0.7701);
    }

    to {
      -webkit-transform: translate(16.21155vw, 300vh) scale(0.7701);
      transform: translate(16.21155vw, 300vh) scale(0.7701);
    }
  }

  .snow:nth-child(180) {
    opacity: 0.3734;
    -webkit-transform: translate(71.4887vw, -10px) scale(0.5314);
    transform: translate(71.4887vw, -10px) scale(0.5314);
    -webkit-animation: fall-180 30s linear -7s infinite;
    animation: fall-180 30s linear -7s infinite;
  }

  @-webkit-keyframes fall-180 {
    70.892% {
      -webkit-transform: translate(62.4968vw, 212.676vh) scale(0.5314);
      transform: translate(62.4968vw, 212.676vh) scale(0.5314);
    }

    to {
      -webkit-transform: translate(66.99275vw, 300vh) scale(0.5314);
      transform: translate(66.99275vw, 300vh) scale(0.5314);
    }
  }

  @keyframes fall-180 {
    70.892% {
      -webkit-transform: translate(62.4968vw, 212.676vh) scale(0.5314);
      transform: translate(62.4968vw, 212.676vh) scale(0.5314);
    }

    to {
      -webkit-transform: translate(66.99275vw, 300vh) scale(0.5314);
      transform: translate(66.99275vw, 300vh) scale(0.5314);
    }
  }

  .snow:nth-child(181) {
    opacity: 0.919;
    -webkit-transform: translate(94.0893vw, -10px) scale(0.6806);
    transform: translate(94.0893vw, -10px) scale(0.6806);
    -webkit-animation: fall-181 21s linear -18s infinite;
    animation: fall-181 21s linear -18s infinite;
  }

  @-webkit-keyframes fall-181 {
    38.516% {
      -webkit-transform: translate(94.0755vw, 115.548vh) scale(0.6806);
      transform: translate(94.0755vw, 115.548vh) scale(0.6806);
    }

    to {
      -webkit-transform: translate(94.0824vw, 300vh) scale(0.6806);
      transform: translate(94.0824vw, 300vh) scale(0.6806);
    }
  }

  @keyframes fall-181 {
    38.516% {
      -webkit-transform: translate(94.0755vw, 115.548vh) scale(0.6806);
      transform: translate(94.0755vw, 115.548vh) scale(0.6806);
    }

    to {
      -webkit-transform: translate(94.0824vw, 300vh) scale(0.6806);
      transform: translate(94.0824vw, 300vh) scale(0.6806);
    }
  }

  .snow:nth-child(182) {
    opacity: 0.0824;
    -webkit-transform: translate(91.8579vw, -10px) scale(0.8156);
    transform: translate(91.8579vw, -10px) scale(0.8156);
    -webkit-animation: fall-182 38s linear -28s infinite;
    animation: fall-182 38s linear -28s infinite;
  }

  @-webkit-keyframes fall-182 {
    76.419% {
      -webkit-transform: translate(92.8415vw, 229.257vh) scale(0.8156);
      transform: translate(92.8415vw, 229.257vh) scale(0.8156);
    }

    to {
      -webkit-transform: translate(92.3497vw, 300vh) scale(0.8156);
      transform: translate(92.3497vw, 300vh) scale(0.8156);
    }
  }

  @keyframes fall-182 {
    76.419% {
      -webkit-transform: translate(92.8415vw, 229.257vh) scale(0.8156);
      transform: translate(92.8415vw, 229.257vh) scale(0.8156);
    }

    to {
      -webkit-transform: translate(92.3497vw, 300vh) scale(0.8156);
      transform: translate(92.3497vw, 300vh) scale(0.8156);
    }
  }

  .snow:nth-child(183) {
    opacity: 0.1211;
    -webkit-transform: translate(28.6983vw, -10px) scale(0.8501);
    transform: translate(28.6983vw, -10px) scale(0.8501);
    -webkit-animation: fall-183 31s linear -25s infinite;
    animation: fall-183 31s linear -25s infinite;
  }

  @-webkit-keyframes fall-183 {
    78.016% {
      -webkit-transform: translate(26.7716vw, 234.048vh) scale(0.8501);
      transform: translate(26.7716vw, 234.048vh) scale(0.8501);
    }

    to {
      -webkit-transform: translate(27.73495vw, 300vh) scale(0.8501);
      transform: translate(27.73495vw, 300vh) scale(0.8501);
    }
  }

  @keyframes fall-183 {
    78.016% {
      -webkit-transform: translate(26.7716vw, 234.048vh) scale(0.8501);
      transform: translate(26.7716vw, 234.048vh) scale(0.8501);
    }

    to {
      -webkit-transform: translate(27.73495vw, 300vh) scale(0.8501);
      transform: translate(27.73495vw, 300vh) scale(0.8501);
    }
  }

  .snow:nth-child(184) {
    opacity: 0.0419;
    -webkit-transform: translate(91.6291vw, -10px) scale(0.3575);
    transform: translate(91.6291vw, -10px) scale(0.3575);
    -webkit-animation: fall-184 26s linear -5s infinite;
    animation: fall-184 26s linear -5s infinite;
  }

  @-webkit-keyframes fall-184 {
    59.549% {
      -webkit-transform: translate(87.0419vw, 178.647vh) scale(0.3575);
      transform: translate(87.0419vw, 178.647vh) scale(0.3575);
    }

    to {
      -webkit-transform: translate(89.3355vw, 300vh) scale(0.3575);
      transform: translate(89.3355vw, 300vh) scale(0.3575);
    }
  }

  @keyframes fall-184 {
    59.549% {
      -webkit-transform: translate(87.0419vw, 178.647vh) scale(0.3575);
      transform: translate(87.0419vw, 178.647vh) scale(0.3575);
    }

    to {
      -webkit-transform: translate(89.3355vw, 300vh) scale(0.3575);
      transform: translate(89.3355vw, 300vh) scale(0.3575);
    }
  }

  .snow:nth-child(185) {
    opacity: 0.4851;
    -webkit-transform: translate(45.9879vw, -10px) scale(0.7101);
    transform: translate(45.9879vw, -10px) scale(0.7101);
    -webkit-animation: fall-185 28s linear -2s infinite;
    animation: fall-185 28s linear -2s infinite;
  }

  @-webkit-keyframes fall-185 {
    66.41% {
      -webkit-transform: translate(36.4997vw, 199.23vh) scale(0.7101);
      transform: translate(36.4997vw, 199.23vh) scale(0.7101);
    }

    to {
      -webkit-transform: translate(41.2438vw, 300vh) scale(0.7101);
      transform: translate(41.2438vw, 300vh) scale(0.7101);
    }
  }

  @keyframes fall-185 {
    66.41% {
      -webkit-transform: translate(36.4997vw, 199.23vh) scale(0.7101);
      transform: translate(36.4997vw, 199.23vh) scale(0.7101);
    }

    to {
      -webkit-transform: translate(41.2438vw, 300vh) scale(0.7101);
      transform: translate(41.2438vw, 300vh) scale(0.7101);
    }
  }

  .snow:nth-child(186) {
    opacity: 0.9702;
    -webkit-transform: translate(41.3441vw, -10px) scale(0.0409);
    transform: translate(41.3441vw, -10px) scale(0.0409);
    -webkit-animation: fall-186 32s linear -19s infinite;
    animation: fall-186 32s linear -19s infinite;
  }

  @-webkit-keyframes fall-186 {
    64.898% {
      -webkit-transform: translate(38.1265vw, 194.694vh) scale(0.0409);
      transform: translate(38.1265vw, 194.694vh) scale(0.0409);
    }

    to {
      -webkit-transform: translate(39.7353vw, 300vh) scale(0.0409);
      transform: translate(39.7353vw, 300vh) scale(0.0409);
    }
  }

  @keyframes fall-186 {
    64.898% {
      -webkit-transform: translate(38.1265vw, 194.694vh) scale(0.0409);
      transform: translate(38.1265vw, 194.694vh) scale(0.0409);
    }

    to {
      -webkit-transform: translate(39.7353vw, 300vh) scale(0.0409);
      transform: translate(39.7353vw, 300vh) scale(0.0409);
    }
  }

  .snow:nth-child(187) {
    opacity: 0.0217;
    -webkit-transform: translate(25.5118vw, -10px) scale(0.143);
    transform: translate(25.5118vw, -10px) scale(0.143);
    -webkit-animation: fall-187 23s linear -23s infinite;
    animation: fall-187 23s linear -23s infinite;
  }

  @-webkit-keyframes fall-187 {
    60.33% {
      -webkit-transform: translate(17.6169vw, 180.99vh) scale(0.143);
      transform: translate(17.6169vw, 180.99vh) scale(0.143);
    }

    to {
      -webkit-transform: translate(21.56435vw, 300vh) scale(0.143);
      transform: translate(21.56435vw, 300vh) scale(0.143);
    }
  }

  @keyframes fall-187 {
    60.33% {
      -webkit-transform: translate(17.6169vw, 180.99vh) scale(0.143);
      transform: translate(17.6169vw, 180.99vh) scale(0.143);
    }

    to {
      -webkit-transform: translate(21.56435vw, 300vh) scale(0.143);
      transform: translate(21.56435vw, 300vh) scale(0.143);
    }
  }

  .snow:nth-child(188) {
    opacity: 0.3052;
    -webkit-transform: translate(45.0426vw, -10px) scale(0.2745);
    transform: translate(45.0426vw, -10px) scale(0.2745);
    -webkit-animation: fall-188 21s linear -11s infinite;
    animation: fall-188 21s linear -11s infinite;
  }

  @-webkit-keyframes fall-188 {
    66.973% {
      -webkit-transform: translate(38.6386vw, 200.919vh) scale(0.2745);
      transform: translate(38.6386vw, 200.919vh) scale(0.2745);
    }

    to {
      -webkit-transform: translate(41.8406vw, 300vh) scale(0.2745);
      transform: translate(41.8406vw, 300vh) scale(0.2745);
    }
  }

  @keyframes fall-188 {
    66.973% {
      -webkit-transform: translate(38.6386vw, 200.919vh) scale(0.2745);
      transform: translate(38.6386vw, 200.919vh) scale(0.2745);
    }

    to {
      -webkit-transform: translate(41.8406vw, 300vh) scale(0.2745);
      transform: translate(41.8406vw, 300vh) scale(0.2745);
    }
  }

  .snow:nth-child(189) {
    opacity: 0.5353;
    -webkit-transform: translate(26.0294vw, -10px) scale(0.3124);
    transform: translate(26.0294vw, -10px) scale(0.3124);
    -webkit-animation: fall-189 26s linear -29s infinite;
    animation: fall-189 26s linear -29s infinite;
  }

  @-webkit-keyframes fall-189 {
    54.418% {
      -webkit-transform: translate(25.0912vw, 163.254vh) scale(0.3124);
      transform: translate(25.0912vw, 163.254vh) scale(0.3124);
    }

    to {
      -webkit-transform: translate(25.5603vw, 300vh) scale(0.3124);
      transform: translate(25.5603vw, 300vh) scale(0.3124);
    }
  }

  @keyframes fall-189 {
    54.418% {
      -webkit-transform: translate(25.0912vw, 163.254vh) scale(0.3124);
      transform: translate(25.0912vw, 163.254vh) scale(0.3124);
    }

    to {
      -webkit-transform: translate(25.5603vw, 300vh) scale(0.3124);
      transform: translate(25.5603vw, 300vh) scale(0.3124);
    }
  }

  .snow:nth-child(190) {
    opacity: 0.176;
    -webkit-transform: translate(85.2271vw, -10px) scale(0.3863);
    transform: translate(85.2271vw, -10px) scale(0.3863);
    -webkit-animation: fall-190 24s linear -4s infinite;
    animation: fall-190 24s linear -4s infinite;
  }

  @-webkit-keyframes fall-190 {
    36.42% {
      -webkit-transform: translate(89.555vw, 109.26vh) scale(0.3863);
      transform: translate(89.555vw, 109.26vh) scale(0.3863);
    }

    to {
      -webkit-transform: translate(87.39105vw, 300vh) scale(0.3863);
      transform: translate(87.39105vw, 300vh) scale(0.3863);
    }
  }

  @keyframes fall-190 {
    36.42% {
      -webkit-transform: translate(89.555vw, 109.26vh) scale(0.3863);
      transform: translate(89.555vw, 109.26vh) scale(0.3863);
    }

    to {
      -webkit-transform: translate(87.39105vw, 300vh) scale(0.3863);
      transform: translate(87.39105vw, 300vh) scale(0.3863);
    }
  }

  .snow:nth-child(191) {
    opacity: 0.1439;
    -webkit-transform: translate(79.0247vw, -10px) scale(0.7853);
    transform: translate(79.0247vw, -10px) scale(0.7853);
    -webkit-animation: fall-191 34s linear -25s infinite;
    animation: fall-191 34s linear -25s infinite;
  }

  @-webkit-keyframes fall-191 {
    62.401% {
      -webkit-transform: translate(84.6821vw, 187.203vh) scale(0.7853);
      transform: translate(84.6821vw, 187.203vh) scale(0.7853);
    }

    to {
      -webkit-transform: translate(81.8534vw, 300vh) scale(0.7853);
      transform: translate(81.8534vw, 300vh) scale(0.7853);
    }
  }

  @keyframes fall-191 {
    62.401% {
      -webkit-transform: translate(84.6821vw, 187.203vh) scale(0.7853);
      transform: translate(84.6821vw, 187.203vh) scale(0.7853);
    }

    to {
      -webkit-transform: translate(81.8534vw, 300vh) scale(0.7853);
      transform: translate(81.8534vw, 300vh) scale(0.7853);
    }
  }

  .snow:nth-child(192) {
    opacity: 0.8488;
    -webkit-transform: translate(23.4512vw, -10px) scale(0.294);
    transform: translate(23.4512vw, -10px) scale(0.294);
    -webkit-animation: fall-192 32s linear -8s infinite;
    animation: fall-192 32s linear -8s infinite;
  }

  @-webkit-keyframes fall-192 {
    36.571% {
      -webkit-transform: translate(18.3766vw, 109.713vh) scale(0.294);
      transform: translate(18.3766vw, 109.713vh) scale(0.294);
    }

    to {
      -webkit-transform: translate(20.9139vw, 300vh) scale(0.294);
      transform: translate(20.9139vw, 300vh) scale(0.294);
    }
  }

  @keyframes fall-192 {
    36.571% {
      -webkit-transform: translate(18.3766vw, 109.713vh) scale(0.294);
      transform: translate(18.3766vw, 109.713vh) scale(0.294);
    }

    to {
      -webkit-transform: translate(20.9139vw, 300vh) scale(0.294);
      transform: translate(20.9139vw, 300vh) scale(0.294);
    }
  }

  .snow:nth-child(193) {
    opacity: 0.5578;
    -webkit-transform: translate(64.774vw, -10px) scale(0.8577);
    transform: translate(64.774vw, -10px) scale(0.8577);
    -webkit-animation: fall-193 28s linear -23s infinite;
    animation: fall-193 28s linear -23s infinite;
  }

  @-webkit-keyframes fall-193 {
    59.701% {
      -webkit-transform: translate(63.0291vw, 179.103vh) scale(0.8577);
      transform: translate(63.0291vw, 179.103vh) scale(0.8577);
    }

    to {
      -webkit-transform: translate(63.90155vw, 300vh) scale(0.8577);
      transform: translate(63.90155vw, 300vh) scale(0.8577);
    }
  }

  @keyframes fall-193 {
    59.701% {
      -webkit-transform: translate(63.0291vw, 179.103vh) scale(0.8577);
      transform: translate(63.0291vw, 179.103vh) scale(0.8577);
    }

    to {
      -webkit-transform: translate(63.90155vw, 300vh) scale(0.8577);
      transform: translate(63.90155vw, 300vh) scale(0.8577);
    }
  }

  .snow:nth-child(194) {
    opacity: 0.5549;
    -webkit-transform: translate(91.9616vw, -10px) scale(0.5761);
    transform: translate(91.9616vw, -10px) scale(0.5761);
    -webkit-animation: fall-194 34s linear -22s infinite;
    animation: fall-194 34s linear -22s infinite;
  }

  @-webkit-keyframes fall-194 {
    79.094% {
      -webkit-transform: translate(97.5981vw, 237.282vh) scale(0.5761);
      transform: translate(97.5981vw, 237.282vh) scale(0.5761);
    }

    to {
      -webkit-transform: translate(94.77985vw, 300vh) scale(0.5761);
      transform: translate(94.77985vw, 300vh) scale(0.5761);
    }
  }

  @keyframes fall-194 {
    79.094% {
      -webkit-transform: translate(97.5981vw, 237.282vh) scale(0.5761);
      transform: translate(97.5981vw, 237.282vh) scale(0.5761);
    }

    to {
      -webkit-transform: translate(94.77985vw, 300vh) scale(0.5761);
      transform: translate(94.77985vw, 300vh) scale(0.5761);
    }
  }

  .snow:nth-child(195) {
    opacity: 0.8874;
    -webkit-transform: translate(42.1425vw, -10px) scale(0.0897);
    transform: translate(42.1425vw, -10px) scale(0.0897);
    -webkit-animation: fall-195 22s linear -19s infinite;
    animation: fall-195 22s linear -19s infinite;
  }

  @-webkit-keyframes fall-195 {
    63.07% {
      -webkit-transform: translate(44.9905vw, 189.21vh) scale(0.0897);
      transform: translate(44.9905vw, 189.21vh) scale(0.0897);
    }

    to {
      -webkit-transform: translate(43.5665vw, 300vh) scale(0.0897);
      transform: translate(43.5665vw, 300vh) scale(0.0897);
    }
  }

  @keyframes fall-195 {
    63.07% {
      -webkit-transform: translate(44.9905vw, 189.21vh) scale(0.0897);
      transform: translate(44.9905vw, 189.21vh) scale(0.0897);
    }

    to {
      -webkit-transform: translate(43.5665vw, 300vh) scale(0.0897);
      transform: translate(43.5665vw, 300vh) scale(0.0897);
    }
  }

  .snow:nth-child(196) {
    opacity: 0.9079;
    -webkit-transform: translate(87.6966vw, -10px) scale(0.3783);
    transform: translate(87.6966vw, -10px) scale(0.3783);
    -webkit-animation: fall-196 31s linear -12s infinite;
    animation: fall-196 31s linear -12s infinite;
  }

  @-webkit-keyframes fall-196 {
    57.711% {
      -webkit-transform: translate(81.0076vw, 173.133vh) scale(0.3783);
      transform: translate(81.0076vw, 173.133vh) scale(0.3783);
    }

    to {
      -webkit-transform: translate(84.3521vw, 300vh) scale(0.3783);
      transform: translate(84.3521vw, 300vh) scale(0.3783);
    }
  }

  @keyframes fall-196 {
    57.711% {
      -webkit-transform: translate(81.0076vw, 173.133vh) scale(0.3783);
      transform: translate(81.0076vw, 173.133vh) scale(0.3783);
    }

    to {
      -webkit-transform: translate(84.3521vw, 300vh) scale(0.3783);
      transform: translate(84.3521vw, 300vh) scale(0.3783);
    }
  }

  .snow:nth-child(197) {
    opacity: 0.8554;
    -webkit-transform: translate(70.4738vw, -10px) scale(0.2377);
    transform: translate(70.4738vw, -10px) scale(0.2377);
    -webkit-animation: fall-197 25s linear -28s infinite;
    animation: fall-197 25s linear -28s infinite;
  }

  @-webkit-keyframes fall-197 {
    55.119% {
      -webkit-transform: translate(76.9714vw, 165.357vh) scale(0.2377);
      transform: translate(76.9714vw, 165.357vh) scale(0.2377);
    }

    to {
      -webkit-transform: translate(73.7226vw, 300vh) scale(0.2377);
      transform: translate(73.7226vw, 300vh) scale(0.2377);
    }
  }

  @keyframes fall-197 {
    55.119% {
      -webkit-transform: translate(76.9714vw, 165.357vh) scale(0.2377);
      transform: translate(76.9714vw, 165.357vh) scale(0.2377);
    }

    to {
      -webkit-transform: translate(73.7226vw, 300vh) scale(0.2377);
      transform: translate(73.7226vw, 300vh) scale(0.2377);
    }
  }

  .snow:nth-child(198) {
    opacity: 0.5411;
    -webkit-transform: translate(56.552vw, -10px) scale(0.5106);
    transform: translate(56.552vw, -10px) scale(0.5106);
    -webkit-animation: fall-198 22s linear -27s infinite;
    animation: fall-198 22s linear -27s infinite;
  }

  @-webkit-keyframes fall-198 {
    65.993% {
      -webkit-transform: translate(56.1577vw, 197.979vh) scale(0.5106);
      transform: translate(56.1577vw, 197.979vh) scale(0.5106);
    }

    to {
      -webkit-transform: translate(56.35485vw, 300vh) scale(0.5106);
      transform: translate(56.35485vw, 300vh) scale(0.5106);
    }
  }

  @keyframes fall-198 {
    65.993% {
      -webkit-transform: translate(56.1577vw, 197.979vh) scale(0.5106);
      transform: translate(56.1577vw, 197.979vh) scale(0.5106);
    }

    to {
      -webkit-transform: translate(56.35485vw, 300vh) scale(0.5106);
      transform: translate(56.35485vw, 300vh) scale(0.5106);
    }
  }

  .snow:nth-child(199) {
    opacity: 0.0839;
    -webkit-transform: translate(82.5294vw, -10px) scale(0.7489);
    transform: translate(82.5294vw, -10px) scale(0.7489);
    -webkit-animation: fall-199 35s linear -21s infinite;
    animation: fall-199 35s linear -21s infinite;
  }

  @-webkit-keyframes fall-199 {
    31.696% {
      -webkit-transform: translate(90.9279vw, 95.088vh) scale(0.7489);
      transform: translate(90.9279vw, 95.088vh) scale(0.7489);
    }

    to {
      -webkit-transform: translate(86.72865vw, 300vh) scale(0.7489);
      transform: translate(86.72865vw, 300vh) scale(0.7489);
    }
  }

  @keyframes fall-199 {
    31.696% {
      -webkit-transform: translate(90.9279vw, 95.088vh) scale(0.7489);
      transform: translate(90.9279vw, 95.088vh) scale(0.7489);
    }

    to {
      -webkit-transform: translate(86.72865vw, 300vh) scale(0.7489);
      transform: translate(86.72865vw, 300vh) scale(0.7489);
    }
  }

  .snow:nth-child(200) {
    opacity: 0.5761;
    -webkit-transform: translate(1.1204vw, -10px) scale(0.0987);
    transform: translate(1.1204vw, -10px) scale(0.0987);
    -webkit-animation: fall-200 25s linear -3s infinite;
    animation: fall-200 25s linear -3s infinite;
  }

  @-webkit-keyframes fall-200 {
    50.383% {
      -webkit-transform: translate(-0.6491vw, 151.149vh) scale(0.0987);
      transform: translate(-0.6491vw, 151.149vh) scale(0.0987);
    }

    to {
      -webkit-transform: translate(0.23565vw, 300vh) scale(0.0987);
      transform: translate(0.23565vw, 300vh) scale(0.0987);
    }
  }

  @keyframes fall-200 {
    50.383% {
      -webkit-transform: translate(-0.6491vw, 151.149vh) scale(0.0987);
      transform: translate(-0.6491vw, 151.149vh) scale(0.0987);
    }

    to {
      -webkit-transform: translate(0.23565vw, 300vh) scale(0.0987);
      transform: translate(0.23565vw, 300vh) scale(0.0987);
    }
  }
`

export const StyledHome = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  width: 100%;
  position: relative;

  @media (min-width: 900px) {
    height: 110vh;
  }

  @media (max-width: 1025px) {
    padding-left: 4px;
    padding-right: 4px;
    height: 90vh;
  }
  .header-title {
    width: 330px;
    margin-bottom: 10px;
  }

  .download-appstore {
    width: 94px;
  }

  .download-chplay {
    width: 94px;
  }

  .download-game {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    font-feature-settings: 'salt', 'liga' 0;
    color: rgb(255, 255, 255);
    width: 184px;
    min-height: 42px;
    margin: auto;
    max-width: 100%;
    background: linear-gradient(90deg, rgb(56, 76, 255) 0%, rgb(36, 134, 249) 0.01%, rgb(61, 220, 236) 100%);
    box-shadow: rgb(71, 69, 248) 2px 2px 6px;
    border-radius: 40px;
    cursor: pointer;
    border: none;
    img {
      margin-right: 10px;
    }
  }

  @media (min-width: 967px) {
    .download-chplay {
      width: 10vw;
    }
  }
  @media (min-width: 967px) {
    .download-appstore {
      width: 10vw;
    }
  }

  @media (min-width: 1440px) {
    .header-title {
      width: 600px;
    }
  }

  .zPuL {
    position: unset;
  }
  @media (max-width: 899px) {
    .zPuL {
      padding: 15vh 0px 10px;
      text-align: center;
    }
  }
  @media (min-width: 900px) {
    .zPuL {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }

  .nft-01 {
    width: 120px;
    position: absolute;
    right: 200px;
    bottom: 150px;
    z-index: 1;
    transform: scaleX(-1);
  }

  @media (min-width: 899px) {
    .nft-01 {
      width: 16vw;
      position: absolute;
      right: 23vw;
      bottom: 34vw;
    }
  }

  @media screen and (min-width: 1080px) {
    .nft-01 {
      width: 13vw;
      position: absolute;
      right: 21vw;
      bottom: 32vw;
    }
  }

  @media (min-width: 1600px) {
    .nft-01 {
      width: 230px;
      position: absolute;
      right: 300px;
      bottom: 600px;
    }
  }

  @media (min-width: 1920px) {
    .nft-01 {
      width: 261px;
      position: absolute;
      right: 212px;
      bottom: 676px;
    }
  }

  @media (min-width: 2560px) {
    .nft-01 {
      width: 261px;
      position: absolute;
      right: 162px;
      bottom: 869px;
    }
  }

  .nft-02 {
    width: 120px;
    position: absolute;
    right: -34px;
    bottom: 93px;
    z-index: 3;
  }

  @media screen and (min-width: 968px) {
    .nft-02 {
      width: 18vw;
      position: absolute;
      right: 5vw;
      bottom: 14vw;
    }
  }

  @media screen and (min-width: 1080px) {
    .nft-02 {
      width: 18vw;
      position: absolute;
      right: 5vw;
      bottom: 14vw;
    }
  }

  @media (min-width: 1600px) {
    .nft-02 {
      width: 300px;
      position: absolute;
      right: 0px;
      bottom: 280px;
    }
  }

  @media (min-width: 1920px) {
    .nft-02 {
      width: 376px;
      position: absolute;
      right: -52px;
      bottom: 346px;
    }
  }

  @media (min-width: 2560px) {
    .nft-02 {
      width: 438px;
      position: absolute;
      right: -137px;
      bottom: 541px;
    }
  }

  .nft-03 {
    width: 198px;
    position: absolute;
    right: 60px;
    bottom: 85px;
  }

  @media screen and (min-width: 968px) {
    .nft-03 {
      width: 26vw;
      position: absolute;
      right: 22vw;
      bottom: 11vw;
    }
  }
  @media screen and (min-width: 1080px) {
    .nft-03 {
      width: 26vw;
      position: absolute;
      right: 22vw;
      bottom: 11vw;
    }
  }
  @media (min-width: 1600px) {
    .nft-03 {
      width: 24vw;
      position: absolute;
      right: 266px;
      bottom: 200px;
      z-index: 2;
    }
  }
  @media (min-width: 1920px) {
    .nft-03 {
      width: 24vw;
      position: absolute;
      right: 227px;
      bottom: 267px;
      z-index: 2;
    }
  }
  @media (min-width: 2560px) {
    .nft-03 {
      width: 434px;
      position: absolute;
      right: 200px;
      bottom: 436px;
    }
  }
  @media (max-width: 899px) {
    .nft-container {
      width: 300px;
      height: 300px;
      margin: auto;
      position: relative;
    }
  }
`
export const CardBridgeTransfer = styled.div`
  width: 100%;
  max-width: 630px;
  margin: 0 auto 48px;
  background: rgb(255, 255, 255, 0.6);

  border: 1px solid rgb(218 220 223);
  border-radius: 4px;
  .head {
    padding: 24px 16px;
    border-bottom: 1px solid #383e48;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 24px;
    }

    h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
      margin-bottom: 8px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #f98c36;
    }
  }

  .content {
    padding: 24px 16px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 24px;
    }

    .btn-max {
      color: #f98c36;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      width: 63px;
      height: 27px;
      margin-left: 10px;
      background: #111b1e;
      border: unset;
      box-shadow: -2px -2px 2px #1e3238, inset 0px -2px 1px #001015;
      border-radius: 8px;
      cursor: pointer;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 16px;
      }
    }

    .wrap-input-item {
      > div > div:last-child {
        width: 100%;
      }
      /* margin-bottom: 12px;
      ${({ theme }) => theme.mediaQueries.sm} {
        margin-bottom: 32px;
      } */
    }
  }
  .form-action {
    width: 100%;
    margin-top: 32px;
    button {
      width: 100%;
      height: 44px;
      font-size: 14px;
      background: #f0b90b;
      // box-shadow: -2px -2px 2px #1e3238, inset 0px -2px 1px #001015;
      border-radius: 8px;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 16px;
      }
    }

    button:disabled {
      background: #e0e0eb;
      color: rgb(143, 155, 179);
    }
  }
`
export const RightInputButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;

  min-width: 50px;
  height: 36px;

  background: transparent;
  box-shadow: none;
  border-radius: 8px;
  border: unset;
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.sm} {
    height: 44px;
    padding: 4px 16px;
  }

  > div {
    white-space: nowrap;

    > svg {
      width: 1px;
      path {
        stroke: #000;
      }
      ${({ theme }) => theme.mediaQueries.sm} {
        width: auto;
      }
    }
  }

  .wIcon {
    margin-right: 6px;
    width: 24px;
    height: 24px;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 20px;
      height: 20px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    > svg {
      width: 100%;
    }
  }
  .jPoDQE {
    width: 15px;
    height: 15px;
    opacity: 0.5;
    ${({ theme }) => theme.mediaQueries.sm} {
      width: 15px;
      height: 15px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    > svg {
      width: 100%;
    }
  }
`
export const NetworkSelectContentStyle = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .network-item {
    align-items: center;
    border: 0;
    /* background: transparent; */
    color: ${({ theme }) => theme.colors.textSubtle};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 48px;
    justify-content: space-between;
    outline: 0;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    background: #000000;
    border-radius: 12px;

    &:hover:not(:disabled) {
      background-color: ${({ theme }) => theme.colors.tertiary};
    }

    &:active:not(:disabled) {
      opacity: 0.85;
      transform: translateY(1px);
    }
  }
`
export const CurrenciesSelectContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .curr-item {
    align-items: center;
    border: 0;
    /* background: transparent; */
    color: ${({ theme }) => theme.colors.textSubtle};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 48px;
    justify-content: space-between;
    outline: 0;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    background: #edf1f7;
    border-radius: 12px;

    &:hover:not(:disabled) {
      // background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    }

    &:active:not(:disabled) {
      opacity: 0.85;
      transform: translateY(1px);
      // background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    }

    img {
      width: 28px;
      height: 28px;
    }
  }
`
