/* eslint-disable lodash/import-scope */
import { Box, EmptyIcon, Flex, Text } from '@pancakeswap/uikit'
// import { useAllBlockchain } from 'state/home/fetchAllBlockChain'

// import {useWeb3React} from '../../../packages/wagmi/src/useWeb3React'
// import TransactionBridge from './components/TransactionBridge'
// eslint-disable-next-line lodash/import-scope
import styled from 'styled-components'
import { Button } from 'antd'
import * as Styles from './styles'

// const NetworkSelect = ({ switchNetwork, chainId, blockchainList }) => {
//   return (
//     <Styles.NetworkSelectContentStyle>
//       {blockchainList?.map((chain) => {
//         return (
//           <Box
//             className="network-item"
//             key={chain.chainid}
//             style={{
//               justifyContent: 'flex-start',
//               opacity: chain.chainid !== chainId ? 1 : 0.5,
//               cursor: chain.chainid !== chainId ? 'pointer' : 'not-allowed',
//             }}
//             onClick={() => chain.chainid !== chainId && switchNetwork(chain)}
//           >
//             <ChainLogo chainId={chain.chainid} />
//             <Text
//               color={chain.chainid === chainId ? 'secondary' : 'text'}
//               bold={chain.chainid === chainId}
//               pl="12px"
//               fontSize={[12, , 14]}
//             >
//               {chain.title}
//             </Text>
//           </Box>
//         )
//       })}
//     </Styles.NetworkSelectContentStyle>
//   )
// }

const FooterContainer = styled.div`
  position: absolute;
  bottom: 40px;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`

const CurrencySelect = ({ fromNetwork, switchCurrency, currencySelect, currencyListByChain }) => {
  // let currency
  // // console.log('currencyListByChain', currencyListByChain)
  // if (currencyListByChain?.length > 0 && typeof fromNetwork !== 'undefined') {
  //   currency = currencyListByChain[0]
  // }

  return (
    // <Styles.CurrenciesSelectContentStyle>
    //   {typeof currency !== 'undefined' ? (
    //     <Box
    //       className="curr-item"
    //       key={currency._id}
    //       style={{
    //         justifyContent: 'flex-start',
    //         opacity: currency._id !== currencySelect?._id ? 1 : 0.5,
    //         cursor: currency._id !== currencySelect?._id ? 'pointer' : 'not-allowed',
    //       }}
    //       onClick={() => currency._id !== currencySelect?._id && switchCurrency(currency)}
    //     >
    //       <img src={`/images/currencies/${currency?.code}.png`} alt="logo" />
    //       <Text
    //         color={currency._id === currencySelect?._id ? 'secondary' : 'text'}
    //         bold={currency._id === currencySelect?._id}
    //         pl="12px"
    //       >
    //         {currency.title} ({currency.code})
    //       </Text>
    //     </Box>
    //   ) : (
    //     <Flex alignItems="center">
    //       <EmptyIcon width={24} />
    //       <Text as="p" ml="16px" fontSize={[12, 14]} fontWeight={500}>
    //         There Are No Data
    //       </Text>
    //     </Flex>
    //   )}
    // </Styles.CurrenciesSelectContentStyle>
    <Styles.CurrenciesSelectContentStyle>
      {currencyListByChain?.length > 0 ? (
        currencyListByChain?.map((item) => {
          const currency = item
          // const currency = allCurrency.find((cur) => {
          //   if (cur._id === item._id) {
          //     return {
          //       ...item,
          //       ...cur,
          //     }
          //   }
          //   return {}
          // })

          return (
            <Box
              className="curr-item"
              key={currency._id}
              style={{
                justifyContent: 'flex-start',
                opacity: currency._id !== currencySelect?._id ? 1 : 0.5,
                cursor: currency._id !== currencySelect?._id ? 'pointer' : 'not-allowed',
              }}
              onClick={() => currency._id !== currencySelect?._id && switchCurrency(currency)}
            >
              <img src={`/images/currencies/${currency?.code}.png`} alt="logo" />
              <Text
                color={currency._id === currencySelect?._id ? 'secondary' : 'text'}
                bold={currency._id === currencySelect?._id}
                pl="12px"
              >
                {currency.title} ({currency.code})
              </Text>
            </Box>
          )
        })
      ) : (
        <Flex alignItems="center">
          <EmptyIcon width={24} />
          <Text as="p" ml="16px" fontSize={[12, 14]} fontWeight={500}>
            There Are No Data
          </Text>
        </Flex>
      )}
    </Styles.CurrenciesSelectContentStyle>
  )
}

const Styled04 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  margin-left: 300px;
  @media (min-width: 768px) {
    padding-top: 300px;
  }
  @media (max-width: 500px) {
    width: 170px;
    padding-top: 275px;
    margin-left: 250px;
  }
`

const Styled03 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 95px;
  min-width: 375px;
  min-height: 586px;
`

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  return (
    <Styles.SnowWrapper>
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div
        style={{
          width: '100%',
          maxWidth: '1366px',
          margin: '0px auto',
          padding: '0px 16px',
        }}
      >
        <Styles.StyledHome data-aos="zoom-in">
          <div className="zPuL">
            <img className="header-title" src="/images/zuki/title-welcome.png" alt="" />
            <div style={{ display: 'grid', gridAutoRows: 'auto', rowGap: 30 }}>
              {/* <div style={{ display: 'flex', gap: 30, justifyContent: 'center' }}>
                <img className="download-appstore" src="/images/zuki/download-appstore.png" alt="" />
                <img className="download-chplay" src="/images/zuki/download-chplay.png" alt="" />
              </div>
              <a href="https://beurl.app/DownloadGame" target="_blank" rel="noreferrer">
                <Button className="download-game">
                  <img src="/images/zuki/game-controll.svg" alt="" /> Download Game
                </Button>
              </a> */}
            </div>
          </div>
          <div className="nft-container">
            <img
              style={{ height: 'auto', maxWidth: '100%', imageRendering: '-webkit-optimize-contrast' }}
              className="nft-01"
              src="/images/zuki/nft-02.gif"
              alt=""
            />
            <img
              style={{ height: 'auto', maxWidth: '100%', imageRendering: '-webkit-optimize-contrast' }}
              className="nft-02"
              src="/images/zuki/nft-01.gif"
              alt=""
            />
            <img
              style={{ height: 'auto', maxWidth: '100%', imageRendering: '-webkit-optimize-contrast' }}
              className="nft-03"
              src="/images/zuki/nft-03.gif"
              alt=""
            />
          </div>
        </Styles.StyledHome>
      </div>
    </Styles.SnowWrapper>
  )
}

export default Home
